import Dispatcher from "../helper/Dispatcher";

export default {
    fetchData(payload){
        Dispatcher().dispatch(FETCH_DATA, payload);
    },
    addStoreEventListener(fName, fn){
        Dispatcher().getStore('AdPerformanceStore').addStoreEventListener(fName, fn);
    },
    hasStoreEventListener(fName){
        return Dispatcher().getStore('AdPerformanceStore').hasStoreEventListener(fName);
    }
}
export const FETCH_DATA = 'ad-fetch-data';