import React, {Component} from 'react';
import MenuItem from '../components/menu/MenuItem';
import mainActions, {CS_JORA_SEARCH, LOCAL_STORAGE_KEY_RECENT_SEARCHES, LOCAL_STORAGE_KEY_STORED_SEARCHES, ON_PERSIST_SAVED_SEARCH, ON_PERSIST_CURRENT_FILTERS, ON_REMOVE_PERSISTED_SEARCH, ON_REMOVE_CURRENT_FILTERS, CS_SEEK_SEARCH, RECOMMENDED_QUESTIONS, ON_PLAY_VERSION_CHECKER, SET_APP_TITLE} from '../actions/MainViewAction';
import CsJoraSearch from './cs/jora/CsJoraSearch';
import $ from 'jquery'
import ViewUtils from '../helper/ViewUtils';

import VerticalToolBar from '../components/vertical-tool-bar/VerticalToolBar';
import VerticalToolBarItem from '../components/vertical-tool-bar/VerticalToolBarItem';
import VerticalToolBarIcon from '../components/vertical-tool-bar/VerticalToolBarIcon';
import { withRouter } from 'react-router-dom';
import AuthAction, { LOG_OUT } from '../actions/AuthAction';
import {Sidebar} from 'primereact/components/sidebar/Sidebar';
import {Button} from 'primereact/components/button/Button';
import UiUpdateInfo from '../components/update-info/UiUpdateInfo';
import BasicDataAction, { FETCH_APP_MENU } from '../actions/BasicDataAction';
import { For } from '../helper/react-inline-logic';
import { XCOMPONENTS_PATH, DOCUMENTATION_PATH } from '../App';
import MainViewAction from '../actions/MainViewAction';
import {getMenuItemPath} from '../App';


class MainView extends Component{
    constructor(props){
        super(props);
        let self = this;
        self.state = {
            data:{},
            rightSideBarVisible:false,
            hasUpdate:false,
            latestVersion:null,
            appTitle:'',
            menuItems:[]
        };
        self.state.currentView=window.location.pathname;

        let onMainListener = function(type, payload){
            self.mainStoreEventListener(type, payload, self);
        };

        mainActions.addStoreEventListener('onMainListener', onMainListener);
        BasicDataAction.addStoreEventListener('onMainBasicDataListener',(type, payload)=>{
            if(type===FETCH_APP_MENU && !payload.error){
              self.setState({menuItems:payload.data}, self.refs.verticalMainBar.forceUpdate());
            }
          });
        
    }
    
    componentDidMount(){
        mainActions.populate();
        //mainActions.playVersionChecker();
        BasicDataAction.fetchAppMenu();
    }

    isCurrentPath(path){
        if(path===this.state.currentView){
            return true;
        }
        let pattern = path+'/';
        if(this.state.currentView.indexOf(pattern)===0){
            return true;
        }
        return false;
    }

    getCurrentView(){
        if(this.state.currentView!=null){
            if(this.isCurrentPath(CS_JORA_SEARCH)){
                return (<CsJoraSearch></CsJoraSearch>);
            }
        }
        return null;
    }

    mainStoreEventListener(type, payload, self){
        switch(type){
            case ON_PERSIST_CURRENT_FILTERS:{
                let data = payload;
                let items = self.getRecentSearchItems(data);
                self.setState({recentSearches:items});
                break;
            }
            case ON_REMOVE_CURRENT_FILTERS:{
                let data = payload.dataList;
                let items = self.getRecentSearchItems(data);
                self.setState({recentSearches:items});
                let removedItem = payload.removedItem;
                mainActions.persistAsSavedSearch(removedItem);
                break;
            }
            case ON_PERSIST_SAVED_SEARCH:{
                let data = payload;
                let items = self.getSavedSearchItems(data);
                self.setState({savedSearches:items});
                window.Messages.addInfoMessage('Operation performed successfully!');
                break;
            }
            case ON_REMOVE_PERSISTED_SEARCH:{
                let data = payload;
                let items = self.getSavedSearchItems(data);
                self.setState({savedSearches:items});
                window.Messages.addInfoMessage('Operation performed successfully!');
                break;
            }
            case LOCAL_STORAGE_KEY_STORED_SEARCHES:{
                let savedSearches = self.getSavedSearchItems(payload);
                self.setState({savedSearches});
                break;
            }
            case LOCAL_STORAGE_KEY_RECENT_SEARCHES:{
                let recentSearches = self.getRecentSearchItems(payload);
                self.setState({recentSearches});
                break;
            }
            case LOG_OUT:{
                this.props.history.push('/');
                break;
            }
            case ON_PLAY_VERSION_CHECKER:{
                self.setState({hasUpdate:payload.hasUpdate, latestVersion:payload.latestVersion});
                break;
            }
            case SET_APP_TITLE:{
                self.setState({appTitle:payload.title});break;
            }
            default:{}
        }
    }
    
    onSaveRecentSearch(menuEvent){
        $(menuEvent.component).fadeOut(function(){
            mainActions.removeRecentSearch(menuEvent.reactInstance.props.data.id);
        })
    }

    onDeleteSavedSearch(menuEvent){
        mainActions.removePersistedSearch(menuEvent.reactInstance.props.data.id, LOCAL_STORAGE_KEY_STORED_SEARCHES);
    }

    getMenuItemText(data){
        return `${data.jobtitle!==null && data.jobtitle!==''?data.jobtitle:data.keyWords} ${data.country}`;
    }

    getMenuItemIcon(data){
        try{
            return `${ViewUtils.isDefined(data.jobtitle) && data.jobtitle!==''?data.jobtitle.charAt(0):data.keyWords.charAt(0)}${data.country.charAt(0)}`;
        }catch(e){
            return data.country;
        }
    }

    getRecentSearchItems(data){
        let self = this;
        if(typeof data === 'undefined' || data === null){
            return null;
        }
        let recentSearches = [];
        for(let i=0; i<data.length; i++){
            let current = data[i];
            if(current.context===LOCAL_STORAGE_KEY_RECENT_SEARCHES){
                let currentPath = this.getParsedPathByObject(current);
                
                recentSearches.push(
                <MenuItem data={current} iconClassLeft="char-icon" iconRightRendered={true} iconRightClassName="material-icons" iconRightTitle="Store this item on Stored Searches"
                    iconRightText="alarm_add" materialIconName={this.getMenuItemIcon(current)} onIconRightClick={(me)=>this.onSaveRecentSearch(me)} iconLeftTitle={this.getMenuItemText(current)}
                    key={current.id} path={currentPath} text={this.getMenuItemText(current)} onClick={(path)=>self.onMenuClick(currentPath, current)}>
                </MenuItem>);
            }
        }
        return recentSearches;
    }

    getParsedPathByObject(payload){
        let props = Object.getOwnPropertyNames(payload);
        let returnValue = [];
        if(ViewUtils.isDefined(payload.path)){
            returnValue.push(payload.path);
        }else{
            return;
        }

        let exceptionList = 'path page pageSize hasErrors context';

        for(let i=0; i<props.length; i++){
            let prop = props[i];
            let value = payload[prop];
            if(exceptionList.indexOf(prop)===-1 && ViewUtils.isDefined(value) && !ViewUtils.isBlank(value) && value!=='-'){
                returnValue.push(prop);
                if(ViewUtils.isFunction(value.replace)){
                    value = value.replace(/ /g, '+');
                }
                returnValue.push(value);
            }
        }
        if(returnValue.length>0){
            return returnValue.join('/');
        }else{
            return '';
        }

    }

    getSavedSearchItems(data){
        let self = this;
        if(typeof data === 'undefined' || data === null){
            return null;
        }
        let recentSearches = [];
        for(let i=0; i<data.length; i++){
            let current = data[i];
            if(current.context===LOCAL_STORAGE_KEY_STORED_SEARCHES){
                let currentPath = this.getParsedPathByObject(current);
                recentSearches.push(
                <MenuItem data={current} key={current.id} iconClassLeft="char-icon" materialIconName={this.getMenuItemIcon(current)} iconRightTitle="Remove this item"
                    text={this.getMenuItemText(current)} onClick={(path)=>self.onMenuClick(currentPath, current)} path={currentPath} iconRightRendered={true} 
                    iconRightClassName="material-icons" iconRightText="delete" onIconRightClick={(me)=>this.onDeleteSavedSearch(me)} iconLeftTitle={this.getMenuItemText(current)}>
                </MenuItem>);
            }
        }
        return recentSearches;
    }

    onMenuClick(path, persistedFilter, windowTitle){
        this.props.history.push(path);
        if(typeof persistedFilter !== 'undefined'){
            mainActions.selectCurrentFiltersByClick(persistedFilter); 
        }else{
            mainActions.doOpenView(path);
            windowTitle && MainViewAction.setAppTitle(windowTitle);
        }
    }

    updateToolBar(){
        let self  = this;
        let recentSearchesTitle = ViewUtils.isDefined(self.state.recentSearches) && self.state.recentSearches.length>0?<div className="menu-item-title-static">Recent searches - {self.state.recentSearches.length}</div>:<div className="menu-item-title-static">You have no recent searches.</div>
        let savedSearchesTitle = ViewUtils.isDefined(self.state.savedSearches) && self.state.savedSearches.length>0?<div className="menu-item-title-static">Stored searches - {self.state.savedSearches.length}</div>:<div className="menu-item-title-static">You have no stored searches.</div>
        let recentSearchBody = <div>
            {recentSearchesTitle}
            {this.state.recentSearches}
        </div>

        let storedSearchBody = <div>
            {savedSearchesTitle}
            {this.state.savedSearches}
        </div>

        this.refs.recentSearchesItem.setState({children:recentSearchBody});
        this.refs.storedSearchesItem.setState({children:storedSearchBody});

        if(ViewUtils.isDefined(self.state.recentSearches)){
            this.refs.recentSearchesIcon.setState({iconValue:self.state.recentSearches.length});
        }

        if(ViewUtils.isDefined(self.state.savedSearches)){
            this.refs.storedSearchesIcon.setState({iconValue:self.state.savedSearches.length});
        }

        if(ViewUtils.isDefined(this.refs.locationsIcon)){
            let rendered = this.isInRole("LOCATION");
            this.refs.locationsIcon.setState({rendered});
        }

        if(ViewUtils.isDefined(this.refs.questionsIcon)){
            let rendered = this.isInRole("JOB_QUESTIONS");
            this.refs.questionsIcon.setState({rendered});
        } 
    }

    componentDidUpdate(){
        //this.updateToolBar();
        this.setToggleApp();

    }

    setToggleApp(){
        if(typeof window.localStorage.csappmenustatusbig === 'undefined'){
            window.localStorage.csappmenustatusbig='true';
        }
        if(window.localStorage.csappmenustatusbig==='false'){
            $('div.main-view-tool-bar').hide();
            return;     
        }else{
            $('div.main-view-tool-bar').show();
        }
        window.setTimeout(function(){
            let index = $('.menu-item-root.selected').closest('div.vertical-tool-bar-item-wrap').attr('data-index');
            $(`div.vertical-tool-bar-icon-wrap[data-index=${index}] > i`).addClass('selected');
        },200);
    }

    isExpandedView(){
        return window.localStorage.csappmenustatusbig==='true';
    }

    onToggleApp(){
        if(typeof window.localStorage.csappmenustatusbig === 'undefined'){
            window.localStorage.csappmenustatusbig='true';
        }
        if(window.localStorage.csappmenustatusbig==='true'){
            $('div.main-view-tool-bar').fadeOut();
            window.localStorage.csappmenustatusbig = 'false';
            return;
        }else{
            $('div.main-view-tool-bar').fadeIn();
            window.localStorage.csappmenustatusbig = 'true';
        }
    }

    logout(){
        AuthAction.logOut({userLogin:this.state.userLogin});
    }

    isInRole(role){
        let isIn = this.props.user && this.props.user.roles && this.props.user.roles.indexOf(role)!==-1;
        return true;
    }

    makeDynamicIcons(items, ready=[]){
        items.forEach((item, index)=>{
            ready.push(<VerticalToolBarIcon key={`d-item-${item.name}`} iconClass={`fa ${item.icon}`} rendered={this.isInRole(item.role)}
            iconText={item.iconText} toggleIndex={`d-item-${item.name}`}
            onBodyExpanded={(e)=>$('div.system-title').text('AIPS - Partner Portal')} 
            onBodyContracted={(e)=>$('div.system-title').text('APP')}/>);
        });
        return ready;
    }

    makeDynamicItems(items, ready=[]){
        items.forEach((item, index)=>{
            ready.push(<VerticalToolBarItem key={index} index={`d-item-${item.name}`}>
                 <For list={item.items} onLoop={(subItem, subIndex)=>(
                        <MenuItem key={subIndex} text={subItem.label} type={subItem.type}
                            onClick={(path)=>this.onMenuClick((getMenuItemPath(subItem)),undefined,subItem.label)} 
                            asLink={true} 
                            path={getMenuItemPath(subItem)} 
                            iconClassLeft={subItem.icon} iconLeftTitle={subItem.label} 
                            materialIconName={subItem.iconText}/>
                 )}>
                 </For>   
            </VerticalToolBarItem>);
        });
        return ready;
    }

    render(){
        return (<div className="main-view-root">
            <Sidebar ref="sidebar" visible={this.state.rightSideBarVisible} position="right" onHide={ () => this.setState({rightSideBarVisible:false}) }>
                <Button label="Logout (from OKTA)" icon="fa fa-sign-out" onClick={(e)=>this.logout()} iconPos="left"/>
            </Sidebar>
            
            <div className="main-view-tool-bar">
                <div className="system-title">APP</div>
                <div className="system-version">{this.props.version}</div>
                <VerticalToolBar ref="verticalMainBar">
                        
                    {this.makeDynamicIcons(this.state.menuItems)}

                    {this.makeDynamicItems(this.state.menuItems)}                    

                </VerticalToolBar>
            
            </div>

            <div className="main-view-root-div-menu hidden">
            </div>
            <div className="main-view-root-div-content">
                <div className="main-view-root-div-top-bar">
                    <i className="material-icons menu-toggle" onClick={(e)=>this.onToggleApp(e)}>menu</i>
                    <UiUpdateInfo show={this.state.hasUpdate} latestVersion={this.state.latestVersion}/>
                    <div className="current-view" id="div-current-view-title">{this.state.appTitle}</div>
                    <div className="user-details">
                        <img className="user-picture" src={this.props.user.picture} alt="User"/>
                        <span onClick={(e)=>this.setState({rightSideBarVisible:true})}>{this.props.user.name}</span>
                    </div>
                </div>
                {this.props.children}
            </div>
        </div>);
    }
}

export default withRouter(MainView);