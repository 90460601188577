import React,{Component} from 'react';
import './Box.css'
import PropTypes from 'prop-types';
import ViewUtils from '../../helper/ViewUtils';
import $ from 'jquery'

class Box extends Component{

    onToggleBody(){
        $(this.refs.currentBody).slideToggle(()=>{
            this.refs.iconToggle.innerHTML = this.refs.iconToggle.innerHTML === 'expand_more' ? 'expand_less':'expand_more';
            if(this.refs.iconToggle.innerHTML==='expand_less'){
                let f = this.props.onBodyExpanded || function(){};
                f(this);
            }
        });
    }

    onHeaderClick(){
        if(this.props.toggleBody){
            this.onToggleBody();
        }
    }


    render(){
        let toggleBodyBtn = this.props.toggleBody===true?<i className="material-icons icon-toggle" ref="iconToggle">{`${this.props.bodyHidden?'expand_more':'expand_less'}`}</i>:'';

        return (<div className={`div-box-wrap ${ViewUtils.isDefined(this.props.className)?this.props.className:''}`} style={this.props.style}>
            <div className={`div-box-header ${this.props.toggleBody===true? ' cursor-pointer hover-light':''}`} onClick={()=>this.onHeaderClick()}>
                {this.props.title}
                {toggleBodyBtn}
            </div>
            <div className={`div-box-body ${this.props.bodyHidden===true?'hidden':''}`} ref="currentBody">
                {this.props.children}
            </div>
            
        </div>);
    }



    static propTypes = {
        title:PropTypes.string.isRequired,
        style:PropTypes.object,
        className:PropTypes.string,
        toggleBody:PropTypes.bool,
        bodyHidden:PropTypes.bool,
        onBodyExpanded:PropTypes.func,
        data:PropTypes.object
   }   
}


export default Box;