import React,{Component} from 'react';
import { withRouter } from 'react-router-dom';
import $ from 'jquery'
import Box from '../components/box/Box';
import Input from '../components/input/Input';
import text from '../helper/text_en_au';
import {SelectButton} from 'primereact/components/selectbutton/SelectButton';
import ViewUtils from '../helper/ViewUtils';
import SearchFilterPanel from '../components/search/SearchFilterPanel';
import RecommendedQuestionsAction, { SEARCH_QUESTIONS, REC_QUESTIONS_FETCH_CLASSIFICATIONS, REC_QUESTIONS_FETCH_SUB_CLASSIFICATIONS, REC_QUESTIONS_FETCH_ALG } from '../actions/RecommendedQuestionsAction';
import {OverlayPanel} from 'primereact/components/overlaypanel/OverlayPanel';
import MainViewAction from '../actions/MainViewAction';


class RecommendedQuestions extends Component{

    constructor(props){
        super(props);
        this.state = this.getInitialState();
        
           //TODO include these records in one store when we can query it from one database
        this.countries = [
            {label:'AU',value:'au'},
            {label:'NZ',value:'nz'},
            {label:'MY',value:'150'},
            {label:'PH',value:'ph'},
            {label:'SG',value:'sg'},
            {label:'ID',value:'id'}
        ];
        RecommendedQuestionsAction.addStoreEventListener('recommendedQuestionsListener', (type,payload)=>{
            switch(type){
                case SEARCH_QUESTIONS:{
                    if(payload.hasError===true){
                        ViewUtils.addErrorMessage(payload.error);
                    }else{
                        let result = payload.recommendedQuestions || [];
                        this.setState({result});
                        // eslint-disable-next-line
                        for(let ri of result)
                            RecommendedQuestionsAction.fetchAlg({ref:ri, func:(e)=> e.country === this.state.filter.country && e.id === ri.algorithmId });
            
                        if(result.length<=0){
                            ViewUtils.addWarnMessage("No records found.");
                        }
                    }
                    ViewUtils.hideWait();
                    break;
                }
                case REC_QUESTIONS_FETCH_CLASSIFICATIONS:{
                    this.setState({classifications:payload});
                    break;
                }
                case REC_QUESTIONS_FETCH_SUB_CLASSIFICATIONS:{
                    this.setState({subClassifications:payload});
                    break;
                }
                case REC_QUESTIONS_FETCH_ALG:{
                    if(payload && payload.val && payload.val.length===1){
                        let result = this.state.result;
                        // eslint-disable-next-line
                        for(let item of result){
                            if(item.id === payload.ref.id){
                                item.algorithmLabel = payload.val[0].description;
                                item.algorithm = payload.val[0];
                                break;
                            }
                        }
                        this.setState({result});
                    }
                    break;
                }
                default:break;
            }
        });
    }

    getInitialState(){
        let country = '150';
        RecommendedQuestionsAction.fetchClassificationsByCountry({country});
        return {
            filter:{
                country,
                workType:'1',
                language:'3'
            },
            classifications:[],
            subClassifications:[],
            result:[]
        };
    }

    componentDidMount(){
        MainViewAction.setAppTitle(text.LBL_RECOMMENDED_QUESTIONS);
        this.refs.firstInput.focus();
    }

    reset(e){
        let ini = this.getInitialState();
        ini.filter.jobText = '';
        ini.filter.jobTitle = '';
        ini.filter.classificationId = '';
        ini.filter.subClassificationId = '';
        this.setState({filter:ini.filter, result:ini.result});
        this.refs.firstInput.focus();
    }

    doFilter(){
        let input = $(this.refs.jobTitle.comp).find('input');
        if(ViewUtils.isBlank(input.val())){
            ViewUtils.addErrorMessage("Job Title must have value.");
            input.focus();
        }else{
            RecommendedQuestionsAction.doSearch(this.state.filter);
            ViewUtils.showWait();
        }
    }

    getClassificationsOptions(classifications){
        let returnOptions = [];
        // eslint-disable-next-line
        for(let item of classifications){
            returnOptions.push(<option key={item.id} value={item.id}>{item.name}</option>);
        }
        return returnOptions;
    }

    getQuestionItems(questions){
        let returnItems = [];
        if(questions && Array.isArray(questions)){
            // eslint-disable-next-line
            for(let item of questions){
                returnItems.push(
                <Box data={item} title={`Question ${item.order} - ${item.text}`} toggleBody={true} 
                    className="box-recommended-question" bodyHidden={true}>
                    <div className="display-table full-size" >
                        <div className="display-cell">
                            <Box title="Answers">
                                {this.renderAnswersInCard(item)}
                            </Box>
                        </div>
                        <div className="display-cell">
                            <Box title="Details">
                                {this.renderDetailsInCard(item)}
                            </Box>
                        </div>
                    </div>
                    
                </Box>
                );
            }
        }
        return returnItems;
    }

    render(){
        let data = this.state.filter;
        return (<div>
            <Box title="Filters">
            <SearchFilterPanel title={text.LBL_RECOMMENDED_QUESTIONS} className="recommended-questions" onSearch={()=>this.doFilter()} onReset={(e)=>this.reset(e)}>
                
                <div className="div-filters">
                        <div className="div-filters-row">
                            <Input type="template" label="Country" className="select-button-country-rc fixed-width-140px">
                                <SelectButton value={data.country} 
                                    options={this.countries} onChange={(e)=>{data.country = e.value?e.value:'au';this.setState({data});RecommendedQuestionsAction.fetchClassificationsByCountry({country:this.state.filter.country});}}>
                                </SelectButton>
                            </Input>    
                            <Input type="select" label="Classification" ref="firstInput" value={data.classificationId} className="fixed-width-140px"
                                onChange={(e)=>{data.classificationId = e.value; this.setState({data}); RecommendedQuestionsAction.fetchSubClassificationsByClassification({classificationId:data.classificationId, country:this.state.filter.country});}}>
                                <option value="">Select one...</option>
                                {this.getClassificationsOptions(this.state.classifications)}
                            </Input>
                            <Input type="select" label="Sub-Classification" className="fixed-width-140px" value={data.subClassificationId} onChange={(e)=>{data.subClassificationId = e.value; this.setState({data});}}>
                                <option value="">Select one...</option>
                                {this.getClassificationsOptions(this.state.subClassifications)}
                            </Input>
                            <Input type="select" label="Work Type" value={data.workType} className="fixed-width-140px" onChange={(e)=>{data.workType = e.value; this.setState({data});}}>
                                <option value="4">Contract</option>
                                <option value="1">Full-Time</option>
                                <option value="16">Intership</option>
                                <option value="2">Part-Time</option>
                                <option value="8">Temporary</option>
                            </Input>
                            
                        </div>

                        <div className="div-filters-row">
                            <Input type="text" label="Job Title" ref="jobTitle" value={data.jobTitle} onChange={(e)=>{data.jobTitle = e.value; this.setState({data});}}></Input>
                            <Input type="text" label="Job Text" value={data.jobText} onChange={(e)=>{data.jobText = e.value; this.setState({data});}}></Input>
                            <Input type="text" label="Advertiser Id" value={data.advertiserId} onChange={(e)=>{data.advertiserId = e.value; this.setState({data});}}></Input>
                            <Input type="select" label="Language" value={data.language} onChange={(e)=>{data.language = e.value; this.setState({data});}}>
                                <option value="3">English</option>
                            </Input>
                        </div>

                    </div>
                </SearchFilterPanel>
                </Box>
                {this.getQuestionItems(this.state.result)}
        </div>)
    }

    renderAnswersInCard(question){
        let answers = [];
        let ordered = question.answers.sort((a,b)=>a.order-b.order);
        // eslint-disable-next-line
        for(let item of ordered){
            answers.push(
                <div className="row icon-label">
                    <div className="cell lbl">
                        <i className="char-icon">{item.order}</i>  
                    </div>
                    <div className="cell left">
                        {item.text}
                    </div>
                </div>
            );
        }

        return (<div className="card-table"> 
                <div className="div-panel-job-item-details">
                    {answers}
                </div>
            </div>);
    }

    renderDetailsInCard(question){
        const infoItem = question.algorithm ? <OverlayPanel ref={(el) => this[`op-${question.id}`] = el}>
                <div className="panel-title"><i class="fa fa-cogs"></i> Algorithm details:</div>
                <div className="p-grid p-align-start" style={{width:'400px'}}>
                    <div className="p-col-4 right">Name:</div>
                    <div className="p-col-8">{question.algorithm.name}</div>
                    <div className="p-col-4 right">Description:</div>
                    <div className="p-col-8">{question.algorithm.description}</div>
                    <div className="p-col-4 right">Matching Rules:</div>
                    <div className="p-col-8">{question.algorithm.matchingRules}</div>
                    <div className="p-col-4 right">Id:</div>
                    <div className="p-col-8">{question.algorithm.id}</div>
                </div>
            
        </OverlayPanel> :'';
        return (<div className="card-table"> 
                
                <div className="div-panel-job-item-details">
                    <div className="row icon-label">
                           
                            <div className="cell lbl">
                                <i className="fa fa-cogs"/>  
                                <span className="small-icon-sub-title">Algorithm</span>
                            </div>
                            <div className="cell left cursor-pointer" onClick={(e) => this[`op-${question.id}`].toggle(e)}>
                                {question.algorithmLabel || question.algorithmId}...
                            </div>
                            {infoItem}
                    </div>

                    <div className="row icon-label">
                            <div className="cell lbl">
                                <i className="material-icons">{`${question.answerType==='Single selection'?'done':'done_all'}`}</i>
                                <span className="small-icon-sub-title">Answer Type</span>
                            </div>
                            <div className="cell left">
                            {question.answerType}
                            </div>
                    </div>
                    <div className="row icon-label">
                            <div className="cell lbl">
                                <i className="fa fa-eye"/>
                                <span className="small-icon-sub-title">Hirer Control Type</span>  
                            </div>
                            <div className="cell left">
                                {question.hirerControlType}
                            </div>
                    </div>
                    <div className="row icon-label">
                           
                        <div className="cell lbl">
                                <i className="char-icon">id</i>
                                <span className="small-icon-sub-title">Question Id</span>
                            </div>
                            <div className="cell left">
                                {question.id}
                            </div>
                    </div>

                    <div className="row icon-label">
                            <div className="cell lbl">
                                <i className="material-icons">flag</i>
                                <span className="small-icon-sub-title">Version</span>
                            </div>
                            <div className="cell left upper">
                                {question.version}
                            </div>
                   </div>

                   <div className="row icon-label">
                           
                         <div className="cell lbl">
                                <i className="material-icons">format_list_numbered</i>  
                                <span className="small-icon-sub-title">Answers</span>
                            </div>
                            <div className="cell left">
                                {question.answers.length}
                            </div>
                    </div>

            </div></div>);
    
    
}

   
}

export default withRouter(RecommendedQuestions);