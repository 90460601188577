import React,{Component} from 'react';
import $ from 'jquery'
import ViewUtils from '../../helper/ViewUtils';

class VerticalToolBarIcon extends Component{
    constructor(props){
       super(props);
       this.state = {
           expanded :false,
           iconValue: props.iconValue,
           rendered:props.rendered
       }     
    }


    onToggleIndex(e){
        if(ViewUtils.isDefined(this.props.toggleIndex)){
            let divContent = $(e.target).closest('div.vertical-tool-bar-wrap').find('div.vertical-tool-bar-body'); 

            let shouldOpen = true;
            $(e.target).closest('div.vertical-tool-bar-wrap').find('div.vertical-tool-bar-icons i').removeClass('selected'); 
            if(divContent.find('div.vertical-tool-bar-item-wrap:visible').attr('data-index')===this.props.toggleIndex){
                shouldOpen = false;
            }

            divContent.find('div.vertical-tool-bar-item-wrap').hide();
            if(shouldOpen){
                divContent.find(`div[data-index=${this.props.toggleIndex}]`).show();
                $(e.target).addClass('selected');
                divContent.addClass('showed');
                $(e.target).closest('div.vertical-tool-bar-wrap').css('width','350px');
                this.bodyExpanded();
            }else{
                $(e.target).closest('div.vertical-tool-bar-wrap').css('width','50px');
                divContent.removeClass('showed');
                this.bodyContracted();
            }
            this.setState({expanded:shouldOpen});
        }
    }

    bodyExpanded(){
        if(ViewUtils.isFunction(this.props.onBodyExpanded)){
            this.props.onBodyExpanded();
        }
    }

    bodyContracted(){
        if(ViewUtils.isFunction(this.props.onBodyContracted)){
            this.props.onBodyContracted();
        }
    }


    render(){
        if(this.state.rendered===false){
            return ("");
        }


        let smallIconHoverIt = ViewUtils.isDefined(this.state.iconValue) && this.state.iconValue>0?<span className="menu-item-small-child-number">{this.state.iconValue}</span>:'';
        return (<div className="vertical-tool-bar-icon-wrap" data-tip={this.props.title} data-index={this.props.toggleIndex}>
            {smallIconHoverIt}
            <i className={this.props.iconClass} onClick={(e)=>this.onToggleIndex(e)}>{this.props.iconText}</i>
        </div>);
    }
}
export default VerticalToolBarIcon;



