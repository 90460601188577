let text = {
    ERROR_SELECT_ONE_ELEMENT:'You sould select one element before perform this action.',
    LBL_DISPLAY_OPTIONS:'Display Options',
    MSG_NO_JOBS_FOUND:'No jobs found! Try to change the filters and research!',
    MSG_JOBS_FOUND:(resultCount,recordsPerPage)=>{return `Found ${resultCount} jobs.${resultCount>recordsPerPage?` (Showing ${recordsPerPage} jobs per page)`:''}`},
    LBL_SUMMARY: 'Search Summary',
    LBL_TOTAL_JOBS_FOUND:(resultCount)=>{return `Total of jobs found: ${resultCount}`},
    MSG_JOB_FOUND:'Your job has been found!',
    LBL_SITE_ID:'Site Id',
    LBL_CATEGORY: 'Category',
    LBL_CLASSIFICATION: 'Classification',
    LBL_SUB: 'Sub',
    LBL_LOCATIONS: 'Locations',
    LBL_LOCATIONS_AU_PLACEHOLDER : 'Suburb, Area, Location or State',
    LBL_LOCATIONS_HK_PLACEHOLDER : 'Granular Location, District or Region',
    LBL_LOCATIONS_MX_PLACEHOLDER : 'Granular Location, Municipality or State',
    LBL_RECOMMENDED_QUESTIONS : 'Recommended Questions'
}

export default Object.freeze(text)