import React, {Component} from 'react'
import hljs from 'highlight.js';
import MainViewAction from '../../actions/MainViewAction';
import MarkdownRenderer from 'react-markdown-renderer';
import Box from '../box/Box';
import './MdOutput.css'
import httph from '../../helper/HttpHelper';

class MdOutput extends Component{

    constructor(props){
        super(props);
        this.state = {
            documentation:''
        }
    }
    
    componentDidMount=()=>{
        this.loadDocumentation(this.props.path);
        MainViewAction.setAppTitle(this.props.title || 'Documentation');
    };

    loadDocumentation=(path)=>httph.get(path,
        (resp)=>this.setState({documentation:applyAnchors(resp.data)},
        ()=>window.location.hash && triggerAnchor(window.location.hash)),(err)=>console.log(err));

    render=()=>
        (<Box title={this.props.title || 'Documentation'} className="md-output-wrap">
            <MarkdownRenderer markdown={this.state.documentation} options={{highlight, html:true}} />
        </Box>);
}

const highlight=(str, lang)=>{
    if (lang && hljs.getLanguage(lang)) {
        try {
            return hljs.highlight(lang, str).value;
        } catch (__) {}
    }

    try {
      return hljs.highlightAuto(str).value;
    } catch (__) {}

    return ''; // use external default escaping
}
export default MdOutput;

//fix to make the anchors works fine.
/**
 * These funcions implements one solution to make the anchors work fine inside the rendered md text.
 * As we are getting the content previus hosted on gitlab, there is one normalization to compose the 
 * ids on the anchored elements. The problem was that the react md render doesn't render the element
 * to be anchored. On the other hand, the anchor element is rendered correctly. Taken this, these funcions
 * are creating one new element above the elements that possible could be an anchor.
 * The logic is take all the elements on the md text starting with `#`, getting their inner text and
 * create the normilized Id for each one. Then, if some anchor are refering the element, it will work
 * fine. For instance, #### Some title here. will receive one element with the id="some-title-here"
 * with no point, no space, totaly lowercase.
 */
const normalizeAnchorId = (row) => (row.replace(/#/g,'').trim().toLowerCase().replace(/ /g,'-').replace(/\./g,''));
const applyAnchors = (md, done=[], rows = md.split('\n')) => {
    // eslint-disable-next-line
    for(let row of rows){
        row.startsWith('#') && done.push(`<i id="${normalizeAnchorId(row)}" ></i>`);
        done.push(row);
    }
    return done.join('\n');
};

const triggerAnchor = (hash) => (document.getElementById(hash.replace('#','')).scrollIntoView());
//end the fix to make the anchors works fine.

