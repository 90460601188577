import { Dialog } from "primereact/components/dialog/Dialog";
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import React,{Component} from 'react';
import SearchFilterPanel from "../search/SearchFilterPanel";
import {Button} from 'primereact/components/button/Button';
import Input from "../input/Input";
import ViewUtils from "../../helper/ViewUtils";
import $ from 'jquery'
import text from '../../helper/text_en_au'

class PopupSearch extends Component{
    constructor(props){
        super(props);
        this._type = PopupSearch;
        this.state = {
            visible:false,
            rows:[],
            rowsSize:0,
            itemsPerPage:100,
            data:{},
            selectedItem:null
        }
    }

    getTableMetadata(){}

    renderFields(){
        return 'include here on renderFields method your fields';
    }


    onSearchComplete(payload){
        this.fullList = payload;
        this.paginatedResultMap = new Map();
        let rows = null;
        let rowsSize = ViewUtils.isDefined(this.fullList)?this.fullList.length:0;
        if(ViewUtils.isDefined(this.fullList) && this.fullList.length>0){
            let maxSize = 0;
            let pageIndex = 0;
            let self = this;
            this.fullList.forEach(item => {
                let currentList = self.paginatedResultMap.get(pageIndex);
                if(!ViewUtils.isDefined(currentList)){
                    self.paginatedResultMap.set(pageIndex, []);
                    currentList = self.paginatedResultMap.get(pageIndex);
                }
                currentList.push(item);
                maxSize++;
                if(maxSize===self.state.itemsPerPage){
                    maxSize = 0;
                    pageIndex++;   
                }
            });
            rows = this.paginatedResultMap.get(0);
        }
        this.setState({rows, rowsSize});
        if(ViewUtils.isDefined(this.refs.paginator)){
            this.refs.paginator.forceUpdate();
        }
        
        $('.popup-search-wrap .lazy-load').removeClass('waiting');
    }


    onSearch(e){}

    onReset(e){}

    onHide(e){
        
        this.setState({visible:false});
    }

    onShow(e){
    }

    onSelectItem(e){
        if(e!=null){
            if(ViewUtils.isFunction(this.props.onSelectItem)){
                this.props.onSelectItem(e);
            }
            this.unselectAll();
            this.setState({visible:false});
        }else{
            ViewUtils.addErrorMessage(text.ERROR_SELECT_ONE_ELEMENT);
        }
    }

    show(){
        this.setState({visible:true});
    }

    hide(){
        this.setState({visible:false});
        this.onHide();
    }


    renderDataTable(){
        let cols = this.getTableMetadata();
        let self = this;
        let dynamicColumns = cols.map((col,i) => {
            return <Column className="col-popup-search" key={col.field} field={col.field} header={col.header} style={col.style}/>;
        });
        let rows = this.state.rows;
        if(ViewUtils.isDefined(this.state.rows)){
            for(let i=0; i<this.state.rows.length; i++){
                rows[i].action = function(e){self.onSelectItem(e)};
            }
        }

        return <DataTable ref="dataTable" value={this.state.rows} scrollable={true} scrollHeight="400px">
        {dynamicColumns}
        </DataTable>;
        
    }

    onPaginatorChange(e){
        if(ViewUtils.isDefined(this.refs.paginator)){
            let currentPage = e.data.currentPage;
            let rows = this.paginatedResultMap.get(currentPage-1);
            this.setState({rows});
            this.refs.paginator.forceUpdate();
        }
    }

    componentDidUpdate(){
        let trs = $(this.refs.dataTable.container).find('div.p-datatable-scrollable-body tbody tr:not(.click-ready)');
        let self = this;
        if(ViewUtils.isDefined(trs) && trs.length>0){
            $(trs).each(function(i){
                let tr = $(this);
                $(tr).addClass('click-ready');
                
                tr.bind('click',function(){
                    if(self.state.rows && self.state.rows.length>0){
                        $(trs).removeClass('selected');
                        tr.addClass('selected');
                        self.state.selectedItem = self.state.rows[i];
                    }
                });

                tr.bind('dblclick',function(){
                    self.onSelectItem(self.state.selectedItem);
                });

            
            });
        }
    }

    unselectAll(){
        $(this.refs.dataTable.container).find('div.p-datatable-scrollable-body tbody tr').removeClass('selected');
        this.setState({selectedItem:null});
    }

    render(){
        let paginator = this.state.rowsSize>100?<Input ref="paginator" label={`Showing 100 results per page (${this.state.rowsSize} records found)`} type="paginator" 
        width="100%" defaultValue="100" paginatorItemsPerPage={this.state.itemsPerPage}
        paginatorItemsSize={this.state.rowsSize} onChange={(e)=>this.onPaginatorChange(e)}/>:'';    


        return (<Dialog className="popup-search-wrap" width="800px" onHide={(e)=>this.onHide(e)} onShow={(e)=>this.onShow(e)} modal={true} closeOnEscape={true} visible={this.state.visible} header={this.props.header} responsive={true}>
            
                <SearchFilterPanel onSearch={(e)=>{$('.popup-search-wrap .lazy-load').addClass('waiting'); this.onSearch(e);}} onReset={(e)=>this.onReset(e)} smallButtons={true}>
                    {this.renderFields()}

                </SearchFilterPanel>
                <div className="popup-search-result lazy-load">    
                    {paginator}
                    {this.renderDataTable()}
                </div>
                <div className="div-buttons-footer">
                    <Button className="p-button-success" label="Ok" icon="fa fa-check" onClick={(e)=>this.onSelectItem(this.state.selectedItem)}></Button>
                    <Button className="p-button-secondary" label="Cancel" icon="fa fa-times" onClick={(e)=>this.setState({visible:false})}></Button>
                </div>
        </Dialog>);
    }
}

export default PopupSearch;
