import Dispatcher from "../helper/Dispatcher";
export default {
    doOpenView:function(path){
        if(path.indexOf('seek')!==-1){
            Dispatcher().dispatch(ON_OPEN_SEEK_VIEW, path);
        }else{
            Dispatcher().dispatch(ON_OPEN_JORA_VIEW, path);
        }
    },
    addStoreEventListener(fName, fn){
        Dispatcher().getStore('MainStore').addStoreEventListener(fName, fn);
    },
    selectCurrentFilters(key){
        Dispatcher().dispatch(key);
    },
    selectCurrentFiltersByClick(data){
        Dispatcher().dispatch(ON_SELECT_STORED_FILTER_BY_CLICK, data);
    },
    persistAsSavedSearch(item){
        Dispatcher().dispatch(ON_PERSIST_SAVED_SEARCH, item);
    },
    removePersistedSearch(id, context){
        Dispatcher().dispatch(ON_REMOVE_PERSISTED_SEARCH,{id, context});
    },
    populate(){
        Dispatcher().dispatch(LOCAL_STORAGE_KEY_RECENT_SEARCHES);
        Dispatcher().dispatch(LOCAL_STORAGE_KEY_STORED_SEARCHES);
    },
    removeRecentSearch(id){
        Dispatcher().dispatch(ON_REMOVE_CURRENT_FILTERS, {id, context:LOCAL_STORAGE_KEY_RECENT_SEARCHES});
    },
    playVersionChecker(payload){
        Dispatcher().dispatch(ON_PLAY_VERSION_CHECKER, payload);
    },
    setAppTitle(title){
        Dispatcher().dispatch(SET_APP_TITLE, {title});
    }
}

export const CS_JORA_SEARCH = '/jora-search';
export const LOCATIONS = '/locations';
export const LOCATIONS_DOCUMENTATION = '/locations-docs';
export const NERD_UI = '/nerd-ui';
export const NERD_DOCUMENTATION = '/nerd-docs';
export const NERD_CSV_INPUT = '/nerd-csv-input';
export const RECOMMENDED_QUESTIONS = '/recommended-questions';
export const CS_SEEK_SEARCH = '/seek-search';
export const MAIN_STORE_KEY = 'MAIN_STORE_KEY';
export const LOCAL_STORAGE_KEY_RECENT_SEARCHES = 'csrecentsearches';
export const LOCAL_STORAGE_KEY_STORED_SEARCHES = 'csstoredsearches';
export const ON_OPEN_VIEW = 'on-open-view';
export const ON_OPEN_SEEK_VIEW = 'on-open-seek-view';
export const ON_OPEN_JORA_VIEW = 'on-open-jora-view';
export const ON_SELECT_STORED_FILTER_BY_PATH = 'on-select-stored-filter-by-path';
export const ON_SELECT_STORED_FILTER_BY_CLICK = 'on-select-stored-filter-by-click';
export const ON_PERSIST_SAVED_SEARCH = 'on-persist-saved-search';
export const ON_PERSIST_CURRENT_FILTERS = 'on-persist-current-filters';
export const ON_REMOVE_CURRENT_FILTERS = 'on-remove-current-filters';
export const ON_REMOVE_PERSISTED_SEARCH = 'on-remove-persisted-search';
export const ON_PLAY_VERSION_CHECKER = 'on-play-version-checker';
export const SET_APP_TITLE = 'on-set-app-title';



