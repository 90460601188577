/**
 * 
 * @param {*} days 
 */
 // eslint-disable-next-line
Date.prototype.addDays = function(days) {
    var dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
}


/**
 * String
 */
 // eslint-disable-next-line
String.prototype.hasValue = function(){
    return this !=null && this.length>0;
}

/**
 * Array
 */
 // eslint-disable-next-line
Array.prototype.hasValue = function(){
    return this!=null && this.length>0;
}


