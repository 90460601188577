import React,{Component} from 'react';
import './VerticalToolBar.css'
import VericalTabBarIcon from './VerticalToolBarIcon';
import {For, If} from '../../helper/react-inline-logic';

class VerticalToolBar extends Component {
render=()=>(<div className="vertical-tool-bar-wrap">
    <div className="vertical-tool-bar-icons">
        <For list={this.props.children} onLoop={(item, index)=>(
            <If key={index} condition={isIcon(item)}>
                {item}
            </If>
        )}></For>
        
    </div>
    <div className="vertical-tool-bar-body">
        <For list={this.props.children} onLoop={(item, index)=>(
            <If key={index} condition={!isIcon(item)}>
                {item}
            </If>
        )}></For>
    </div>
</div>);
}

const isIcon = (item) => item.type===VericalTabBarIcon || (Array.isArray(item) && item.length>0 && item[0].type===VericalTabBarIcon);
export default VerticalToolBar;







