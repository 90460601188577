import {Component} from 'react';
import ViewUtils from '../helper/ViewUtils';
class WaitPage extends Component{

    componentDidMount(){
        ViewUtils.showWait();
        window.setTimeout(()=>ViewUtils.hideWait(),2000);
    }


    render(){
        return ('');
    }
}

export default WaitPage;