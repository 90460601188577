import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SearchFilterPanel.css'
import $ from 'jquery'
import {Button} from 'primereact/components/button/Button';
import MainViewAction from '../../actions/MainViewAction';

/**
 * Vendor:$VENDOR
 * @version $VERSION
 *  @augments {Component<{title: string,className:string,onSearch:function(e:Event)}}, State>}
 */
class SearchFilterPanel extends Component{
    componentDidMount=()=>this.props.title && MainViewAction.setAppTitle(this.props.title);

    resetFields(e){
        window.setTimeout(function(){
            $(e.target).closest('div.div-search-filter-painel').find('div.div-search-filter-painel-filters input[type=text]').val('');
            
            $(e.target).closest('div.div-search-filter-painel').find('div.div-search-filter-painel-filters select').each(function(){
              $(this).find('option:eq(0)').prop('selected', true);
            });    
            $(e.target).closest('div.div-search-filter-painel').find('div.div-search-filter-painel-filters .data-since button').removeClass('selected');
    
            
            $(e.target).closest('div.div-search-container').find('div.div-search-result:eq(0) input[type=text]').val('');
            $(e.target).closest('div.div-search-container').find('div.div-search-result:eq(0)  select').each(function(){
                $(this).find('option:eq(0)').prop('selected', true);
              });    
    
              $(`div.div-panel-job-item-root`).show();

        },100);
    }

    render(){
        let className = 'div-search-filter-painel '+this.props.className;

        let buttons = [];
        if(this.props.smallButtons){
            buttons.push(<Button type="button" key="search-btn" icon="fa fa-search" className="p-button-success" onClick={(e)=>this.props.onSearch(e)} data-tip="Search"></Button>);
            buttons.push(<Button type="button" key="reset-btn" icon="fa fa-undo" className="p-button-secondary" onClick={(e)=>this.props.onReset(e)} data-tip="Reset"></Button>);
            
        }else{
            buttons.push(<Button type="button" iconPos="right" key="search-btn" icon="fa fa-search" onClick={(e)=>this.props.onSearch(e)} className="p-button-primary primary-action">Search</Button>);
            buttons.push(<Button type="button" iconPos="right" key="reset-btn" icon="fa fa-undo" onClick={(e)=>{this.props.onReset(e);}} className="p-button-secondary secondary-action">Reset</Button>);
        
        }

        return (<div className={className}> 
            <form>
            <div className="div-search-filter-painel-components">
                <div className="div-search-filter-painel-filters">
                    
                    {this.props.children}
                </div>
                <div className={`div-search-filter-painel-actions small-btns-${this.props.smallButtons}`}>
                    {buttons}
                </div>
            </div>
            </form>
        </div>
        );
    }

    static propTypes = {
        title:PropTypes.string,
        className:PropTypes.string,
        onSearch:PropTypes.func,
        onReset:PropTypes.func,
        smallButtons:PropTypes.bool
   }

   static defaultProps = {
        title:'',
        className:'',
        onSearch:function(e){},
        smallButtons:false
    }

}
export default SearchFilterPanel;