import axios from 'axios';
import TokenHelper from './TokenHelper';
import {HTTP_REQUEST_TIMEOUT} from './Config'
import Dispatcher from "../helper/Dispatcher";

const httph = {
    get(url, onsuccess, onerror){
        const args = arguments;
        const e = ()=>{
            axios.get(url,{headers:headers(), timeout:HTTP_REQUEST_TIMEOUT})
                .then((response)=>onsuccess(response))
                .catch((err)=>errorHandler(err, onerror, e, args));
        };
        e();
    },
    post(url, data, onsuccess, onerror){
        const args = arguments;
        const e = ()=>{
            axios.post(url, data, {headers:headers(), timeout:HTTP_REQUEST_TIMEOUT})
            .then((res)=> onsuccess(res))
            .catch((err)=>errorHandler(err, onerror, e, args));
        };
        e();
    },
    put(url, data, onsuccess, onerror){
        const args = arguments;
        const e = ()=>{
            axios.put(url, data, {headers:headers(), timeout:HTTP_REQUEST_TIMEOUT})
            .then((res)=> onsuccess(res))
            .catch((err)=>errorHandler(err, onerror, e, args));
        };
        e();
    },
    errorHandler
};


function headers(){
    return {
            'Authorization': `Bearer ${TokenHelper.getCurrentToken()}`,
            'Accept':'application/json',
            'Content-Type':'application/json'
    };
}

/**
 * This function will handler the errors and if one token expired error is detected,
 * the routine to renew the token will be called. After renew, if there is no errors,
 * the original method will be repeated.
 * @param {*} err 
 * @param {*} cb 
 * @param {*} fn 
 * @param {*} args 
 */
function errorHandler(err, cb, fn, args){
    let isTokenExpired = err && err.response && err.response.status === 401 
        && err.response.data 
        && (
            err.response.data.name === 'TokenExpiredError' 
            || (err.response.data.error && err.response.data.error.indexOf('TokenExpiredError')!==-1)
        );
    if(isTokenExpired){
        let repeatEvent = ()=> fn.apply(args); 
        Dispatcher().getStore('AuthStore').addStoreEventListener('auto-renew-token-expired', (type, payload)=>{
            if(type==='auth-store-authorize' && payload.auth===true){
                repeatEvent();
            }else{
                cb(err);
            }
            Dispatcher().getStore('AuthStore').removeStoreEventListener('auto-renew-token-expired');
        });    
        Dispatcher().dispatch('auth-store-authorize',{});
        
    }else{
        cb(err);
    }
}


export default httph;