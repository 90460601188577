import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery'
import MenuEvent from './MenuEvent';
import ReactTooltip from 'react-tooltip';
import MaterialIcon from 'material-icons-react';

/**
 * Vendor:$VENDOR
 * @version $VERSION
 *  @augments {Component<{iconClassLeft: string,selected:bool,onClick:function(me:MenuEvent),onToggle(me:MenuEvent),onLeftIconClick(me:MenuEvent)}}, State>}
 */
class MenuItem extends Component{
    
    constructor(props){
        super(props);
        this.state = {};
        this.state.childExpanded = props.childExpanded;
    }


    dispatcher(event, func, value){
        if(typeof func !== 'undefined'){
            func(new MenuEvent(event, this.rootElement, value, this));
        }
    }

    unselectAll(){
        $(this.rootElement).closest('div.menu-items-area').find('div.menu-item-root.selected').removeClass('selected');
        $(this.rootElement).closest('div.vertical-tool-bar-body').find('div.menu-item-root.selected').removeClass('selected');
        
    }

    isMenuContainerExpanded(){
        return $(this.rootElement).closest('div.menu-div-root.expanded').length===1;
    }

    isNotChild(){
        return $(this.rootElement).parent().hasClass('menu-items-area');
    }

    componentDidMount(){
        if(this.props.path===window.location.pathname){
            $(this.rootElement).addClass('selected');    
        }
    }


    mouseEnter(e){
        this.dispatcher(e,this.props.onMouseEnter, null);
    }

    mouseOut(e){
        this.dispatcher(e,this.props.onMouseOut, null);
    }

    clickItem(e){
        this.unselectAll();
        $(this.rootElement).addClass('selected');
        if(typeof this.props.onClick === 'function'){
            this.dispatcher(e,this.props.onClick);
            
        }else{
            this.toggleBody(e);
        }
    }

    rightIconClick(e){
        this.dispatcher(e, this.props.onIconRightClick, null);
        this.unselectAll();
    }

    leftIconClick(e){
        if(typeof this.props.onLeftIconClick !== 'function'){
            this.clickItem(e);
        }else{
            this.dispatcher(e, this.props.onLeftIconClick, null);
            this.unselectAll();
        }
    }

    getItemBody(hasChild, items){
        if(hasChild){
            let classNameBody = 'body'+(this.state.childExpanded? ' expanded':'');
            return (<div className={classNameBody}>{items}</div>);
        }else{
            return '';
        }
    }

    toggleBody(e){
        let instance = this;
        $(this.rootElement).find('.body').slideToggle(function(){
            let childExpanded = !instance.state.childExpanded;
            instance.setState({childExpanded});
            instance.dispatcher(e, instance.props.onToggle, childExpanded);
        });
    }

    clickItemNotExpanded(e){
        if(!this.isMenuContainerExpanded()){
            this.unselectAll();
            if(this.isNotChild()){
                $(this.rootElement).addClass('selected');
            }
            this.dispatcher(e,this.props.onClick);            
        }
    }

    getIconRight(){
        if(this.props.iconRightRendered){
            if(this.props.iconRightTitle!==''){
                return (<i className={`fa ${this.props.iconRightClassName}`} data-tip={this.props.iconRightTitle} onClick={(me)=>this.rightIconClick(me)}>{this.props.iconRightText}</i>)
            }else{
                return (<i className={`fa ${this.props.iconRightClassName}`} onClick={(me)=>this.rightIconClick(me)}>{this.props.iconRightText}</i>)
            }
        }
        return '';
    }

    render(){
        if(this.props.type && this.props.type==='static')
            return <div className="menu-item-title-static">{this.props.text}</div>;
            
        let className = 'menu-item-root';
        let classNameHead = 'head';
        let hasChild = typeof this.props.children!== 'undefined' && this.props.children!==null && this.props.children.length!==0;
        let classNameIconLeft = 'fa '+this.props.iconClassLeft;
        let body = this.getItemBody(hasChild, this.props.children);
        let dadIconClass = 'fa '+(this.state.childExpanded?'fa-angle-up':'fa-angle-down');
        let dadIcon = hasChild?<i className={dadIconClass} onClick={(e)=> this.toggleBody(e)}></i>:<i/>;
        let notExpandedIcon = <MaterialIcon icon={this.props.materialIconName} className={classNameIconLeft + ' not-expanded-icon'} onClick={(e)=>this.clickItemNotExpanded(e)} />;
        let childNumber = hasChild && this.props.showChildNumber ? <span className="menu-item-small-child-number">{this.props.children.length}</span>:'';
        let iconRight = this.getIconRight();
        
        return (<div className={className} ref={(rootElement)=> this.rootElement = rootElement} onMouseEnter={(e)=>this.mouseEnter(e)} onMouseOut={(e)=>this.mouseOut(e)}>
            <ReactTooltip />
            {childNumber}
            {notExpandedIcon}
            <div className={classNameHead}>
                <i className={classNameIconLeft} data-tip={this.props.iconLeftTitle} onClick={(e)=>this.leftIconClick(e)}>{this.props.materialIconName}</i>
                <span onClick={(e)=> this.clickItem(e)}>{this.props.text}</span>
                {dadIcon}
                {iconRight}
            </div>
            {body}
        </div>);
    }

    static propTypes = {
        text:PropTypes.string,
        selected:PropTypes.bool,
        iconClassLeft:PropTypes.string,
        childExpanded:PropTypes.bool,
        onClick:PropTypes.func,
        onLeftIconClick:PropTypes.func,
        onToggle:PropTypes.func,
        path:PropTypes.string,
        data:PropTypes.object,
        showChildNumber:PropTypes.bool,
        materialIconName:PropTypes.string,
        onMouseEnter:PropTypes.func,
        onMouseOut:PropTypes.func,
        iconRightClassName: PropTypes.string,
        iconRightText:PropTypes.string,
        onIconRightClick:PropTypes.func,
        iconRightRendered:PropTypes.bool.isRequired,
        iconRightTitle:PropTypes.string,
        iconLeftTitle:PropTypes.string

   }

   static defaultProps = {
        iconRightRendered:false,
        text:'To be defined',
        selected:false,
        iconClassLeft:'',
        childExpanded:true,
        path:'',
        showChildNumber:false,
        materialIconName:'',
        iconRightTitle:'',
        iconLeftTitle:''
    }
}

export default MenuItem;