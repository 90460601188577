export default class MenuEvent{
    constructor(event, component, value, reactInstance){
        this.event = event;
        this.component = component;
        this.value =  value;
        this.reactInstance = reactInstance;
    }

    getComponent(){
        return this.component;
    }

    getEvent(){
        return this.event;
    }

    getValue(){
        return this.value;
    }
}
