/**
 * This fn will passivally cleanup the service workeres that will no longer be used inside
 * Partner Portal
 * 
 */

export default async function cleanup() {
    try{
        if(navigator && navigator.serviceWorker && navigator.serviceWorker.getRegistrations){
            const allRegisteredServiceWorkers = await navigator.serviceWorker.getRegistrations();
            for(const sw of allRegisteredServiceWorkers){
                sw.unregister();
            }
        }
    }catch(e){
        console.error(e);
    }
}