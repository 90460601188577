import React, { Component } from 'react';
import './Tab.css'
import $ from 'jquery'


class Tab extends Component{

    constructor(props){
        super(props);

        this.tabsHeader = [];
        if(this.props.children!=null){
            for(let i=0; i<this.props.children.length; i++){
                var instance = this.props.children[i];
                let tabClass = i===0? 'tab-head selected':'tab-head';
                this.tabsHeader.push(<div key={i} id={'header-'+instance.props.index} onClick={(event)=> this.onTabClick(event)} className={tabClass}>{instance.props.title}</div>);
            }
        }
    }

    onTabClick(event){
        if(event!=null){
            let tabClass = '.'+event.target.id;

            $(event.target).parent().parent().find('.tab-item').hide();
            $(event.target).parent().parent().find(tabClass).show();
            $(event.target).parent().find('.tab-head').removeClass('selected');
            $(event.target).parent().find(event.target).addClass('selected');
            let f = this.props.onTabClick || function(){};
            f({tabId:event.target.id, event});
        }else{
            $(this.refs.tabContainer).find('.tab-item').hide();
            $(this.refs.tabContainer).find('.tab-item:first').show();
            $(this.refs.tabContainer).find('.tab-head').removeClass('selected');
            $(this.refs.tabContainer).find('.tab-head:first').addClass('selected');
        }
    }

    componentDidMount(){
        this.onTabClick(null);
    }

    render(){
        return (<div className="tab-container" ref="tabContainer">
                <div className="tab-tabs-reagion">
                    {this.tabsHeader}
                </div>
            {this.props.children}
        </div>);
    }
}

export default Tab;