import PopupSearch from "./PopupSearch";
import BasicDataAction, { SEEK_CATEGORIES, JORA_CATEGORIES, SEEK_PARENT_CLASSIFICATIONS, JORA_PARENT_CLASSIFICATIONS, JORA_COUNTRIES } from "../../actions/BasicDataAction";
import Input from '../input/Input';
import React from 'react';
import ViewUtils from "../../helper/ViewUtils";
import text from "../../helper/text_en_au";
import { If, For } from "../logic/Logic";

class CategoryPopupSearch extends PopupSearch{
    
    constructor(props){
        super(props);
        this.type = CategoryPopupSearch;
        let self = this;
        let onCatEventListener = function(type, payload){
            if(type===SEEK_CATEGORIES || type===JORA_CATEGORIES){
                self.onSearchComplete(payload);
            }
            if(type===SEEK_PARENT_CLASSIFICATIONS || type===JORA_PARENT_CLASSIFICATIONS){
                let classificationOptions = payload;
                self.setState({classificationOptions});
            }
        };
        BasicDataAction.addStoreEventListener('onCatEventListener',onCatEventListener);
        //advertiserId,advertiserName,status,employerTypeDescription,companyTypeDescription
        this.state.data = {id:'',label:'',parentId:''};
        this.state.classificationOptions = [];
        this.state.popupType = '';
    }

    getTableMetadata(){
        return [
            {field:'id', header:'Id', style:{width:'150px'}},
            {field:'label',header:'Sub '+this.contextLabel}
        ];
    }

    onReset(e){
        let data = {id:'',label:'',parentName:'',parentId:'', country:this.country || 'AU', popupType:this.popupType};
        this.setState({data});
    }

    renderClassificationOptions(){
        let options = [];
        // eslint-disable-next-line
        for(let item of this.state.classificationOptions){
            options.push(<option value={item.id}>{item.label}</option>)
        }
        return options;
    }

    renderFields(){
        let data = this.state.data;
        return (<div className="div-filters">
            <div className="div-filters-row">
                <Input onChange={(e)=>{data.id = e.value;this.setState({data});}} label="Id" ref="id" value={data.id}></Input>
                <Input type="select" onChange={(e)=>{data.parentId = e.value;this.setState({data});}} label={this.contextLabel} ref="parentId" value={data.parentId}>
                    <option value=""></option>
                    {this.renderClassificationOptions()}
                </Input>
            </div>
            <div className="div-filters-row">
                <Input onChange={(e)=>{data.label = e.value;this.setState({data});}} label={`${text.LBL_SUB} ${this.contextLabel}`} ref="name" value={data.label}></Input>
                <Input type="select" ref="country" onChange={(e)=>{data.country = e.target.value; this.setState({data});}} 
                       value={data.country} label="Country">
                       <If condition={this.popupType===CAT_POPUP_SEEK}>
                            <option value="AU">AU</option>
                            <option value="NZ">NZ</option>
                       </If>
                       <If condition={this.popupType===CAT_POPUP_JORA}>
                            <For list={JORA_COUNTRIES} onLoop={(item=>(<option value={item.toLowerCase()}>{item.toUpperCase()}</option>))}/>
                       </If>
                </Input>
            </div>
        </div>);
    }

    show(){
        const [popupType, country] = arguments;
        this.popupType = popupType;
        this.contextLabel = this.popupType===CAT_POPUP_JORA?text.LBL_CATEGORY:text.LBL_CLASSIFICATION;
        this.country = country;
        let data = this.state.data;
        data.popupType = popupType;
        data.country = country;
        this.setState({visible:true, data});
        let i = this;
        window.setTimeout(()=>{i.refs.id.focus()},300);
        BasicDataAction.findInSeekParentClassifications(this.country);
    }


    onSearch(e){
        let filter = this.state.data;

        let payload = {country:filter.country, func:(e)=>
            (!filter.label.hasValue() || e.label.toLowerCase().indexOf(filter.label.toLowerCase())!==-1)
            && (!filter.id.toString().hasValue() || String(e.id).indexOf(filter.id.toString())!==-1)
            && (!filter.parentId.hasValue() || (ViewUtils.isDefined(e.parentId) && Number(e.parentId)=== Number(filter.parentId)))
        };

        if(filter.popupType===CAT_POPUP_JORA){
            BasicDataAction.findInJoraCategories(payload);
        }
        if(filter.popupType===CAT_POPUP_SEEK){
            BasicDataAction.findInSeekCategory(payload);
        }
    }

}

export default CategoryPopupSearch;
export const CAT_POPUP_JORA = 'cat-popup-jora';
export const CAT_POPUP_SEEK = 'cat-popup-seek';