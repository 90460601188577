import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputEvent from './InputEvent';


class InputPaginator extends Component{
    constructor(props){
        super(props);
        this.state = {
            currentPage: props.currentPage,
            itemsPerPage: props.itemsPerPage,
            itemsSize:props.itemsSize,
            maxVisibleButtons:props.maxVisibleButtons,
        }
        this.state.pagesSize = this.getPagesSize()
    }
 
    getPagesSize(){
        const value = this.state.itemsSize/this.state.itemsPerPage;
        let returnValue = null;
        if(value<1){
            returnValue =1;
        }else{
            //rouding up
            returnValue = (String(value).indexOf('.')!==-1 ? (1+Number(value.toFixed(0))) : value);
        }
        return returnValue;
    }

    changeValue(e,type, num){
        let value = type;
        let data = this.state;
        if(type==='next'){
            if(data.currentPage<data.pagesSize){
                data.currentPage++;
            }
        }else if(type==='back'){
            if(data.currentPage>1){
                data.currentPage--;
            }
        }
        else if(type==='first'){
            data.currentPage = 1;
        }else if(type==='last'){
            data.currentPage = (data.pagesSize-1);
        }else if(type==='click'){
            data.currentPage = num;
        }else{
            data.itemsPerPage = e.target.value;
            data.pagesSize = this.getPagesSize();
        }
        let event = new InputEvent(e, this.comp, value, data);
        this.setState({currentPage:data.currentPage,
            pagesSize:data.pagesSize, 
            itemsPerPage:data.itemsPerPage
        });
        if(typeof this.props.onChange === 'function'){
            this.props.onChange(event);
        }
    }

    render(){
        let pageButtons = [];
        // eslint-disable-next-line
        let pagesSize = this.state.pagesSize = this.getPagesSize();

        let indexStart = pagesSize>this.props.maxVisibleButtons? this.state.currentPage-1:0;
        
        while(indexStart>0 && pagesSize - indexStart < this.props.maxVisibleButtons){
            indexStart--;
        }

        for(indexStart; indexStart<pagesSize; indexStart++){
            let pageNumber = indexStart+1;
            pageButtons.push(<button key={indexStart} type="button" className={`page-number${this.state.currentPage===pageNumber?' selected':''}`}
                onClick={(e)=>{this.changeValue(e,'click',pageNumber)}}>{pageNumber}</button>)
            
            if(pageButtons.length>=this.props.maxVisibleButtons){
                break;
            }
        }

        return (<div className="paginator-wrap" ref={(c)=>this.comp = c}>
        
        <div className="paginator-border-div">
            <button type="button" className="first" onClick={(e)=>{this.changeValue(e,'first')}} disabled={this.state.currentPage<=1}><i className="fa fa-step-backward"/></button>
            <button type="button" className="back" onClick={(e)=>{this.changeValue(e,'back')}} disabled={this.state.currentPage<=1}><i className="fa fa-backward"/></button>
            {pageButtons}
            <button type="button" className="next" onClick={(e)=>{this.changeValue(e,'next')}} disabled={this.state.currentPage >= this.state.pagesSize}><i className="fa fa-forward"/></button>
            <button type="button" className="last" onClick={(e)=>{this.changeValue(e,'last')}} disabled={this.state.currentPage >= this.state.pagesSize}><i className="fa fa-step-forward"/></button>
        </div>     
    </div>);
    }

    static propTypes = {
        onChange:PropTypes.func,
        currentPage:PropTypes.number,
        itemsPerPage:PropTypes.number,
        itemsSize:PropTypes.number.isRequired,
        maxVisibleButtons:PropTypes.number.isRequired
   }

   static defaultProps = {
        itemsPerPage:100,
        currentPage:1,
        itemsSize:1,
        maxVisibleButtons:4
    }


}

export default InputPaginator;