import React,{Component} from 'react';
import SearchFilterPanel from '../../../components/search/SearchFilterPanel';
import Input from '../../../components/input/Input';
import CsJoraAction, { ON_JORA_SEARCH_AUTOCOMPLETE_POPULATE, ON_JORA_SEARCH } from '../../../actions/CsJoraAction'
import MainViewAction, { CS_JORA_SEARCH, LOCAL_STORAGE_KEY_RECENT_SEARCHES, ON_SELECT_STORED_FILTER_BY_CLICK, ON_OPEN_JORA_VIEW } from '../../../actions/MainViewAction';
import ViewUtils from '../../../helper/ViewUtils';
import PanelJobItem from '../../../components/panel-job-item/PanelJobItem';
import ChartBox from '../../../components/chart-box/ChartBox';
import $ from 'jquery'
import Box from '../../../components/box/Box';
import BasicDataAction, { MAP_JORA_AUTOCOMPLETE_RESULTS, MAP_JORA_AUTOCOMPLETE_RESULTS_FILTERS, FIND_GEOCODE, MAP_JORA_CATEGORIES_BY_IDS, JORA_ADVERTISER, JORA_EMPLOYERS, JORA_JOB_TITLE, JORA_COUNTRIES, JORA_RADIUS } from '../../../actions/BasicDataAction'
import JoraWorkTypePopupSearch from '../../../components/popup/JoraWorkTypePopupSearch'; 
import {ToggleButton} from 'primereact/components/togglebutton/ToggleButton';
import AdvertiserPopupSearch, { ADV_POPUP_JORA } from '../../../components/popup/AdvertiserPopupSearch';
import { JORA_API_LOCATION_SUGGESTION } from '../../../helper/Config';
import {SelectButton} from 'primereact/components/selectbutton/SelectButton';
import text from '../../../helper/text_en_au'
import CategoryPopupSearch, { CAT_POPUP_JORA } from '../../../components/popup/CategoryPopupSearch';
import ReactTooltip from 'react-tooltip' 
import { For } from '../../../helper/react-inline-logic';

class CsJoraSearch extends Component{

    constructor(props){
        super(props, ['CsJoraSearchStore']);
        this.state = {
            data: {
                country:'au',
                keyWords:'',
                locationId:'',
                pageSize:'100',
                sort:'score',
                updatedAt:'',
                radius:'',
                allDetailsShowed:false
            },
            autocompleteAdvertisers: [],
            autocompleteEmployers: [],
            autocompleteJobTitle: [],
            autocomplete:{},
            filterAutocomplete:{},
            result:[],
            summaryVisible:false,
            clientSearchMessage:''
        };
        let data = this.getDataObjectByUrl();
        this.state.data = this.getDataInstance(data);
        this.configureListeners();
    }
    
    onStoreUpdated(type, payload, instance){
        if(typeof payload !== 'undefined' && payload!==null && payload.hasErrors===true){
            ViewUtils.addErrorMessage(payload.errorMessage);
            ViewUtils.hideWait();
            return;
        }

        switch(type){
            case ON_JORA_SEARCH:{
                ViewUtils.hideWait();
                if(payload.result.jobs.length===0){
                    ViewUtils.addInfoMessage(text.MSG_NO_JOBS_FOUND);
                }else{
                    this.setState({result:payload.result, clientSearchMessage:''});
                    payload.result = null;
                    instance.setState({data:payload});
                    BasicDataAction.mapJoraAutoCompleteResultsFilters({list:this.state.result.facets, country:instance.state.data.country});
                    ViewUtils.addInfoMessage(text.MSG_JOBS_FOUND(this.state.result.page.resultCount, this.state.result.page.pageSize));
                    BasicDataAction.mapJoraCategoriesByIds({list:instance.state.result.jobs, country:instance.state.data.country});
                }
                break;
            }
            case ON_JORA_SEARCH_AUTOCOMPLETE_POPULATE:{
                BasicDataAction.mapJoraAutoCompleteResults(payload);
                break;
            }
            case ON_SELECT_STORED_FILTER_BY_CLICK:{
                let data = instance.getDataObjectByUrl();
                data = instance.getDataInstance(data);
                instance.setState({data});
                break;
            }
            case ON_OPEN_JORA_VIEW:{
                let data = instance.getDataInstance(payload);
                instance.setState({data});
                break;
            }
            case MAP_JORA_AUTOCOMPLETE_RESULTS:{
                instance.renderAutocompleteClientOptions(payload);
                break;
            }
            case MAP_JORA_AUTOCOMPLETE_RESULTS_FILTERS:{
                instance.populateSearchSummary(payload, instance); 
                instance.populateFilterAutocompleteOptions(payload, instance);
                break;
            }
            case FIND_GEOCODE:{
                let data = instance.state.data;
                data.locationId = payload;
                instance.setState({data});
                break;
            }
            case MAP_JORA_CATEGORIES_BY_IDS:{
                let result = instance.state.result;
                result.jobs = payload;
                instance.setState({result});
                break;
            }
            case JORA_ADVERTISER:{
                let autocompleteAdvertisers = this.castToAutocompleteOption(payload, JORA_ADVERTISER);
                this.setState({autocompleteAdvertisers});
                break;
            }
            case JORA_EMPLOYERS:{
                let autocompleteEmployers = this.castToAutocompleteOption(payload, JORA_EMPLOYERS);
                this.setState({autocompleteEmployers});
                break;
            }
            case JORA_JOB_TITLE:{
                let autocompleteJobTitle = this.castToAutocompleteOption(payload, JORA_JOB_TITLE);
                this.setState({autocompleteJobTitle});
                break;
            }
            default:{}
        }

    }
    
    getDataObjectByUrl(){
        let data = null;
        data = ViewUtils.getObjectByPath(window.location.pathname,data, 
            ['pageSize:100','country:au', 'locationId:',
             'keyWords:','jobtitle','id', 'employer', 'salary', 'createdAt:',
             'updatedAt:', 'where', 'advertiser', 'category', 'dedup', 'sort:score']);
        return data;
    }

    configureListeners(){
        let instance = this;
        let onCsJoraSearchListener = function(type, payload){
            instance.onStoreUpdated(type, payload, instance);
        };
        CsJoraAction.addStoreEventListener('onCsJoraSearchListener',onCsJoraSearchListener);
        MainViewAction.addStoreEventListener('onCsJoraSearchListener', onCsJoraSearchListener);
        BasicDataAction.addStoreEventListener('onCsJoraSearchListener', onCsJoraSearchListener);
    }

    autocompleteLoad(country){
        BasicDataAction.findInJoraAdvertiser({country});
        BasicDataAction.findInJoraEmployer({country});
        BasicDataAction.findInJoraJobTitle({country});
        BasicDataAction.findInJoraCategories({country});
    }


    getFacetsOptionsCategory(facetList){
        let facetName = 'category';
        let returnComponent = [];
        let currentFacet = null;
        try{
            currentFacet = facetList[facetName];
        }catch(e){
            currentFacet = null;
        }
        if(currentFacet!=null){
            currentFacet.forEach(element=>{
                if(element.parentId && element.parentId!== null){
                    
                    let val = <div data-search-key={`${element.key},${element.value}`} value={element.value} key={element.key} id={element.key}>
                        <span>{element.key}</span><span>{element.value}<b> in </b>{element.parentName}</span>
                        
                        </div>;
                    
                    returnComponent.push(val);

                }else{
                    let val = <div data-search-key={`${element.key},${element.value}`} value={element.value} key={element.key} id={element.key}>
                        <span>{element.key}</span><span>{element.value} ({text.LBL_CATEGORY})</span>
                        
                        </div>;
                    
                    returnComponent.push(val);
                }
            });
        }
        return returnComponent;
    }

    componentDidMount(){

        if(ViewUtils.isDefined(this.firstInput)){
            this.firstInput.focus();
        }
        CsJoraAction.populateAutoCompleteOptions(this.state.data);
        this.autocompleteLoad(this.state.data.country);
    }


    renderAutocompleteClientOptions(payload){
        let jobtitle = this.getFacetsOptions('title',payload, true);
        let advertiser = this.getFacetsOptions('advertiser',payload);
        let category = this.getFacetsOptionsCategory(payload);
        let employer = this.getFacetsOptions('employer',payload, true);
        let workType = this.getFacetsOptions('workType',payload);
        let autocomplete = {
            jobtitle,advertiser,category,employer, workType
        };
        this.setState({autocomplete});
    }



    onPaginatorChange(inputEvent){
        let data = this.state.data;
        data.pageSize = inputEvent.data.itemsPerPage;
        data.page= inputEvent.data.currentPage;
        ViewUtils.showWait();
        CsJoraAction.doSearch(data);
    }

    getDataInstance(payload, instance){
        let returnValue = null;
        if(payload===null || payload==='/jora-search'){
            this.onReset();
            returnValue = {
                country:'au',
                keyWords:'',
                locationId:'',
                pageSize:100,
                updatedAt:''
            };
        }else{
            returnValue = payload;
        }
        return returnValue;
       
    }

    castToAutocompleteOption(list, type){
        let returnComponent = [];
        if(list!=null){
            switch(type){
                case JORA_EMPLOYERS:{
                    // eslint-disable-next-line
                    for(let element of list) returnComponent.push(<div data-search-key={`${element.id},${element.label}`} value={element.label} key={element.id} id={element.id}><span>{element.label}</span></div>);        
                    break;
                }
                case JORA_JOB_TITLE:{
                    // eslint-disable-next-line
                    for(let element of list) returnComponent.push(<div data-search-key={`${element.id}`} value={element.id} key={element.id} id={element.id}><span>{element.id}</span></div>);        
                    break;
                }
                default:{
                    // eslint-disable-next-line
                    for(let element of list) returnComponent.push(<div data-search-key={`${element.id},${element.label}`} value={element.label} key={element.id} id={element.id}><span>{element.id}</span><span>{element.label}</span></div>);        
                }
            }    
        }
        return returnComponent;
    }

    getFacetsOptions(facetName, facetList, printKeyAndValue){
        
        let returnComponent = [];
        let currentFacet = null;
        try{
            currentFacet = facetList[facetName];
        }catch(e){
            currentFacet = null;
        }
        if(currentFacet!=null){
            currentFacet.forEach(element=>{
                let val = null;
                if(printKeyAndValue===true){
                    val = <div data-search-key={`${element.key},${element.value}`} value={element.value} key={element.key} id={element.key}>{`${element.key} (${element.value})`}</div>;
                }else{
                    val = <div data-search-key={`${element.key},${element.value}`} value={element.value} key={element.key} id={element.key}><span>{element.key}</span><span>{element.value}</span></div>;
                }
                returnComponent.push(val);
            });
        }
        return returnComponent;
    }

    getFacetsOptionsFilter(facetName, facetList, printKeyAndValue){
        
        let returnComponent = [];
        let currentFacet = null;
        try{
            currentFacet = facetList[facetName];
        }catch(e){
            currentFacet = null;
        }
        if(currentFacet!=null){
            currentFacet.forEach(element=>{
                let val = null;
                if(printKeyAndValue===true){
                    val = `${element.key} (${element.value})`;
                }else{
                    val = element.value;
                }
                returnComponent.push(<option data-search-key={`${element.key},${element.value}`} value={element.key} key={element.key}>{val}</option>);
            });
        }
        return returnComponent;
    }

    getFacetsOptionsFilterCategory(facetList){
        let facetName = 'category';
        let returnComponent = [];
        let currentFacet = null;
        try{
            currentFacet = facetList[facetName];
        }catch(e){
            currentFacet = null;
        }
        if(currentFacet!=null){
            currentFacet.forEach(element=>{
                let val =  `${element.value} ${element.parentName===null?' (Category)':' in '+element.parentName}`;
                returnComponent.push(<option data-search-key={`${element.key},${element.value}`} value={element.key} key={element.key}>{val}</option>);
            });
        }
        return returnComponent;
    }

    onSearch(){
        ViewUtils.showWait();
        let data = this.state.data;
        data.page = 1;
        CsJoraAction.doSearch(data);
        CsJoraAction.persistCurrentFilters(CS_JORA_SEARCH, data, LOCAL_STORAGE_KEY_RECENT_SEARCHES);
    }

    onTextSearch(e){
        let currentList = null;
        if(ViewUtils.isEmpty(this.state.result.textSearch)){
            currentList = $(`div.div-panel-job-item-root`);
            currentList.show();
        }else{
            currentList = $(`div.div-panel-job-item-root:contains(${this.state.result.textSearch})`);
            currentList.show();        
            $(`div.div-panel-job-item-root:not(:contains(${this.state.result.textSearch}))`).hide();
        }
        let jobMessage = `${currentList.length===0?'No job found!':''+currentList.length +' job'+(currentList.length>1?'s':'')+' found'}`;
        $(e.target).parent().find('span.job-number-result').remove();
        $(e.target).parent().prepend(`<span class=job-number-result>${jobMessage}</span>`);
        let refInput = e.target;
        window.setTimeout(function(){
            $(refInput).parent().find('span.job-number-result').fadeOut(500);

        },2000);
    }

    onClientSearch(e){
        let r = this.state.result;
        const p = {
            text:r.textSearch,
            category:r.category,
            employer:r.employer,
            jobTitle:r.jobTitle,
            workType:r.workType,
            advertiser:r.advertiser,
            salary:r.salary,
            createdAt:ViewUtils.getTimeByStrKey(r.createdAt)
        };
        this.applyClientFilter(p);
    }
    
    applyClientFilter(param){
        let currentList = $('div.div-panel-job-item-root');
        let jobsShowed = 0;
        $(currentList).each(function(){
            let jobPanel = $(this);
            let shouldBeVisible = true;
            if(shouldBeVisible && typeof param.text !== 'undefined' && param.text.hasValue()){
                shouldBeVisible = $(jobPanel).find(`*:contains(${param.text})`).length>0;
            }
            let json = null;
            if(shouldBeVisible){
                json = JSON.parse($(jobPanel).attr('client-search-values'));
            }
            if(shouldBeVisible && typeof param.category !== 'undefined' && param.category.hasValue()){
                shouldBeVisible = ViewUtils.isDefined(json.categoryIds) && json.categoryIds.hasValue() 
                && json.categoryIds.join(',').indexOf(param.category)!==-1;
            }

            if(shouldBeVisible && typeof param.employer !== 'undefined' && param.employer.hasValue()){
                shouldBeVisible = ViewUtils.isDefined(json.employer) && ViewUtils.isDefined(json.employer.name)
                    && json.employer.name.hasValue() && json.employer.name === param.employer;
            }

            if(shouldBeVisible && typeof param.jobTitle !== 'undefined' && param.jobTitle.hasValue()){
                shouldBeVisible = ViewUtils.isDefined(json.jobTitle) && json.jobTitle.hasValue() 
                    && json.jobTitle.indexOf(param.jobTitle);
            }

            if(shouldBeVisible && typeof param.workType !== 'undefined' && param.workType.hasValue()){
                shouldBeVisible = false;
                if(ViewUtils.isDefined(json.workType) && json.workType.hasValue()){
                    // eslint-disable-next-line
                    for(let i in json.workType){
                        let wt = json.workType[i];
                        if(ViewUtils.isDefined(wt) && wt.id === param.workType){
                            shouldBeVisible = true;
                            break;
                        }
                    }
                }
            }

            if(shouldBeVisible && ViewUtils.isDefined(param.createdAt)){
                shouldBeVisible = ViewUtils.isDefined(json.createdAt) && json.createdAt <= param.createdAt;
            }

            if(shouldBeVisible){
                $(jobPanel).show();
                jobsShowed++;
            }else{
                $(jobPanel).hide();
            }
        });

        
        let clientSearchMessage = `${jobsShowed===0?`No job found on the ${this.state.data.page}th page!`:''+jobsShowed +' job'+(jobsShowed>1?'s':'')+` found on the ${this.state.data.page}th page!`}`;
        this.setState({clientSearchMessage:' -> '+clientSearchMessage});
    

    }

    populateSearchSummary(payload, self){
        let searchSummary = {
            advertiser : self.state.result.facets.advertiser,
            category : self.state.result.facets.category,
            location : self.state.result.facets.location,
            createdAt : payload['createdAt'],
            employer : payload['employer'],
            salaryMin : payload['salaryMin'],
            title : payload['title'],
            workType : payload['workType']
        };

        console.log('searchSummary', searchSummary);
        self.state.result && self.state.result.page && self.state.result.page.totalJobs &&
        (searchSummary = this.replaceFacetsResultIfNecessary(searchSummary, self.state.data, self.state.result.page.totalJobs));

        let summaryBuiltData = self.getChartViewData(searchSummary); 
        let summary = self.getFacetsViewBox(summaryBuiltData);

        self.setState({summary, summaryBuiltData}, ()=>{
            self.summaryBox && summaryBuiltData && summaryBuiltData.length>0 && self.summaryBox.onSelectChart(summaryBuiltData[0]);
        });
    }
    /**
     * The job api by default returns all the results for a facet present on the filter, 
     * for instance, if you specify employer and it returns 14 jobs, on the employer facede
     * response will be all the employers and not only what's had been specified.
     * Saying that, Nadia from jora asked to change this behavior showing only the queried filter instead.
     * 
     * This function implements it.
     */
    replaceFacetsResultIfNecessary(values, filter, totalJobs=10){
        values = this.replaceFacetResult('employer', 'employer', filter, values, totalJobs, 20);
        values = this.replaceFacetResult('advertiser', 'advertiser', filter, values, totalJobs, 20);
        values = this.replaceFacetResult('jobtitle', 'title', filter, values, totalJobs, 20);
        return values;
    }

    replaceFacetResult(filterName, facetName, filter, values, totalJobs, maxSize){
        if(filter[filterName]){
            let hadBeenFound = false;
            values[facetName].forEach((item, index)=>{
                item.key===filter[filterName] && index<maxSize && (hadBeenFound=true);
                if(hadBeenFound) return;
            });
            if(!hadBeenFound){
                ['employer', 'title'].indexOf(facetName)!==-1 && 
                (values[facetName] = [{key:filter[filterName], value:totalJobs}]);
            }
        }
        return values;
    }

    populateFilterAutocompleteOptions(payload, self){
        let filterAutocomplete = {
            advertiserOptions : self.getFacetsOptionsFilter('advertiser',payload),
            categoryOptions : self.getFacetsOptionsFilterCategory(payload),
            createdAtOptions : self.getFacetsOptionsFilter('createdAt',payload, true),
            employerOptions : self.getFacetsOptionsFilter('employer',payload, true),
            salaryMinOptions : self.getFacetsOptionsFilter('salaryMin',payload, true),
            titleOptions : self.getFacetsOptionsFilter('title',payload, true),
            workTypeOptions : self.getFacetsOptionsFilter('workType',payload)
        };
        self.setState({filterAutocomplete});

    }

    getChartViewData(searchSummary, data=[]){
        if(ViewUtils.isDefined(this.state.result) && ViewUtils.isDefined(this.state.result.page)){
            data = [
                {name:text.LBL_SITE_ID, value:searchSummary.advertiser, maxItem:20},
                {name:'Category', value:searchSummary.category, maxItem:20},
                {name:'Date Created', value:searchSummary.createdAt, maxItem:50},
                {name:'Employer', value:searchSummary.employer, maxItem:20},
                {name:'Salary', value:searchSummary.salaryMin, maxItem:50},
                {name:'Title', value:searchSummary.title, maxItem:20},
                {name:'Work Type', value:searchSummary.workType, maxItem:7},
                {name:'Location', value:searchSummary.location, maxItem:20}
            ];
        }
        return data;
    }

    getFacetsViewBox(summaryBuiltData){
        if(ViewUtils.isDefined(this.state.result) && ViewUtils.isDefined(this.state.result.page)){
            
            return <ChartBox className="box-summary" type="JORA" ref={(e)=> this.summaryBox = e} title={text.LBL_SUMMARY} data={summaryBuiltData} visible={this.state.summaryVisible}>

                </ChartBox>;
        }
        return '';
    }

    getSearchResultsElement(){ 
        if(!ViewUtils.isEmpty(this.state.result)){
            let jobsDiv = [];
            let i = 0;
            this.state.result.jobs.forEach(element => {
                jobsDiv.push(<PanelJobItem showJobId={true} job={element} country={this.state.data.country} key={element.id} ref={`job-item-${i++}`}/>);
            });
            let result = this.state.result;

            let paginator = this.state.result.page.resultCount>100?(<div className="div-filters-row">
            <Input label={`Showing ${this.state.result.page.pageSize} jobs per page`} 
                type="paginator" width="25%" defaultValue="100" paginatorItemsPerPage={100} 
                paginatorItemsSize={this.state.result.page.resultCount} onChange={(e)=>this.onPaginatorChange(e)}/>
            </div>):'';

        return (<div>
                    <div className="div-search-result">
                        <div className="div-filters">
                            <div className="div-filters-row">
                                <div className="display-cell">

                                <div className="div-box-wrap">
                                    <div className="div-box-header">
                                        <div className="display-table full-size">
                                            <span className="full-size display-cell">Additional Filters {this.state.clientSearchMessage}</span>
                                            
                                        </div>
                                    </div>
                                    <div className="div-box-body">
                                    <div className="div-search-result">
                                        <div className="div-filters">
                                            <div className="div-filters-row">
                                                <Input ref="textSearch" className="additional-filter" onChange={(e)=>{result.textSearch = e.target.value;this.onClientSearch(e);}} placeHolder="Find within results (case sensitive)" label="Find" width="66%"></Input>
                                                <Input ref="filterCategory" type="select" onChange={(e)=>{result.category = e.target.value; this.onClientSearch(e);}} defaultValue="all" label="Category" width="33%">
                                                        <option value="">All</option>
                                                        {this.state.filterAutocomplete.categoryOptions}
                                                </Input>
                                                
                                            </div>
                                        </div>
                                        <div className="div-filters">
                                            <div className="div-filters-row">
                                                <Input ref="filterEmployer" type="select" onChange={(e)=>{result.employer = e.target.value;this.onClientSearch(e);}} defaultValue="all" label="Employer" width="33%">
                                                        <option value="">All</option>
                                                        {this.state.filterAutocomplete.employerOptions}
                                                </Input>
                                                <Input type="select" ref="filterJobTitle" onChange={(e)=>{result.jobTitle = e.target.value;this.onClientSearch(e);}} defaultValue="all" label="Job Title" width="33%">
                                                        <option value="">All</option>
                                                        {this.state.filterAutocomplete.titleOptions}
                                                </Input>
                                                <Input type="select" ref="filterWorkType" onChange={(e)=>{result.workType = e.target.value;this.onClientSearch(e);}} defaultValue="all" label="Work Type" width="33%">
                                                        <option value="">All</option>
                                                        {this.state.filterAutocomplete.workTypeOptions}
                                                </Input>
                                                
                                            </div>
                                        </div>
                                        <div className="div-filters">
                                            <div className="div-filters-row">
                                                <Input type="select"ref="filterAdvertiser" onChange={(e)=>{result.advertiser = e.target.value;this.onClientSearch(e);}} defaultValue="all" label={text.LBL_SITE_ID} width="33%">
                                                        <option value="all">All</option>
                                                        {this.state.filterAutocomplete.advertiserOptions}
                                                </Input>
                                                <Input type="select" ref="filterCreatedAt" onChange={(e)=>{result.createdAt = e.target.value;this.onClientSearch(e);}} defaultValue="all" label="Date Created" width="33%">
                                                        <option value="all">All</option>
                                                        {this.state.filterAutocomplete.createdAtOptions}
                                                </Input>
                                                <Input type="select" ref="filterSalary" onChange={(e)=>{result.salary = e.target.value;this.onClientSearch(e);}} defaultValue="all" label="Salary" width="33%">
                                                        <option value="all">All</option>
                                                        {this.state.filterAutocomplete.salaryMinOptions}
                                                </Input>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                </div>
                                <div className="display-cell">
                                    <Box title={`${text.LBL_DISPLAY_OPTIONS} - ${text.LBL_TOTAL_JOBS_FOUND(this.state.result.page.resultCount)}`}>

                                        <div className="div-search-result">
                                            {paginator}
                                            <div className="div-filters-row">
                                                <ToggleButton style={{width:'250px', height:'38px', lineHeight:'26px', marginTop:'2px'}} onLabel="Hide all jobs details" offLabel="Show all jobs details" 
                                                    onIcon="fa fa-window-minimize" offIcon="fa fa-window-maximize" checked={this.state.data.allDetailsShowed} 
                                                    onChange={(e)=>this.onToggleAllJobs()}/>
                                                <ToggleButton style={{width:'250px', height:'38px', lineHeight:'26px', marginTop:'2px'}} onLabel="Order by score" 
                                                    offLabel="Order by Freshness" onIcon="fa fa-trophy" offIcon="fa fa-calendar"
                                                    checked={this.state.data.sort==='score'} onChange={(e)=>this.onToggleOrder()}/>
                                                <ToggleButton style={{width:'250px', height:'38px', lineHeight:'26px', marginTop:'2px'}} onLabel="Hide search summary" 
                                                    offLabel="Show search summary" onIcon="fa fa-pie-chart" offIcon="fa fa-pie-chart"
                                                    checked={this.state.summaryVisible} 
                                                    onChange={(e)=>{
                                                        this.summaryBox.setVisible(!this.state.summaryVisible); 
                                                        this.setState({summaryVisible:!this.state.summaryVisible}, ()=>{
                                                            this.state.summaryBuiltData && this.state.summaryVisible && this.summaryBox.onSelectChart(this.state.summaryBuiltData[0])
                                                        });}
                                                    }/>
                                            </div>
                                        </div>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    
                    </div>        
                    {this.state.summary}
                <div className="div-search-result">
                    {jobsDiv}
                </div>
                </div>

        );
        }else{
            return [];
        }
    }

    onToggleOrder(){
        let data = this.state.data;
        data.sort = data.sort==='score'?'createdAt':'score';
        this.onSearch();
    }


    onToggleAllJobs(){
        let i=0;
        let currentJob = null;
        let data = this.state.data;
        data.allDetailsShowed = !data.allDetailsShowed;
        this.setState({data});
        while(typeof (currentJob = this.refs[`job-item-${i++}`]) !=='undefined'){
            if(data.allDetailsShowed){
                currentJob.showDetails();
            }else{
                currentJob.hideDetails();
            }
        }
    }

    onReset(e){ 
       ViewUtils.clean(this);
       ViewUtils.clean(this.refs);
       if(ViewUtils.isDefined(this.firstInput) && ViewUtils.isFunction(this.firstInput.focus)){
           this.firstInput.focus();
       }
       let data = this.state.data;
       data.locationId = '';
       data.where = '';
       data.updatedAt='';
       data.dedup='off';
       this.setState({result:[]});
    }

    onCountryChange(e, data, country = e.target.value){
        data.country = country; 
        this.autocompleteLoad(country); 
        let urlCountry = country;
        if(urlCountry==='uk'){
            urlCountry = 'gb';
        }
        this.inputWhere.setUrl(`${JORA_API_LOCATION_SUGGESTION}?country=${urlCountry.toUpperCase()}&query=`); 
        this.setState({data});
    }    

    render(){
        const AUTOCOMPLETE_TIP = 'Click to search for ';
        const AUTOCOMPLETE_TIP_NO_POPUP = 'Enter any value and select an option.';
        let searchResultElement = this.getSearchResultsElement();
        let data = this.state.data;
        return (<div className="div-search-container">
            <ReactTooltip html={true}/>
            <CategoryPopupSearch header="Search for Categories" modal={true} ref="popupCat" onSelectItem={(e)=> {data.category = e.id; this.setState({data})}}/>
            <JoraWorkTypePopupSearch header="Search for Work Type" modal={true} ref="popupWorkType" onSelectItem={(e)=> {data.workType = e.id; this.setState({data})}}/>
            <AdvertiserPopupSearch header="Search for Site Id" modal={true} ref="popupAdvertiser" onSelectItem={(e)=> {data.advertiser = e.id; this.setState({data})}}/>
            
                
            <Box title="Site Search">
                <SearchFilterPanel title="Jora Search" className="jora" onSearch={()=>this.onSearch()} onReset={(e)=>this.onReset(e)}>
                <div className="div-filters">
                    
                    <div className="div-filters-row">
                        <Input onChange={(e)=>{data.keyWords = e.value;this.setState({data})}} label="Keywords" ref={(i)=> this.firstInput = i} value={data.keyWords}></Input>
                        <Input type="select" ref="country" onChange={(e)=>this.onCountryChange(e, data)} 
                            value={this.state.data.country} label="Country">
                            <For list={JORA_COUNTRIES} onLoop={(item=>(<option value={item.toLowerCase()}>{item.toUpperCase()}</option>))}/>
                        </Input>
                        <Input ref={(i)=>this.inputWhere = i} onChange={(e, el)=>{data.where = el?el.value:'';data.locationId = el ? el.id: ''; console.log(el);this.setState({data});}}
                            label="Where" placeHolder="city, state or postcode" icon={true} iconText="info" iconTip={AUTOCOMPLETE_TIP_NO_POPUP}
                            defaultValue={data.where} type="auto-complete">
                        </Input>
                        <Input type="select" ref="radius" onChange={(e)=>{data.radius = e.value; this.setState({data})}}
                            value={data.radius} label="Radius">
                            <option></option>
                            <option value={0}>Exact Location</option>
                            <For list={JORA_RADIUS} onLoop={(item=>(<option value={item}>{item}km</option>))}/>
                        </Input>
                        
                    </div>

                    <div className="div-filters-row">
                        <Input onChange={(e)=>{data.jobtitle=e; this.setState({data})}} type="auto-complete-client-side" ref="jobTitle"
                            value={this.state.data.jobtitle} label="Job Title" icon={true} iconText="info" iconTip={AUTOCOMPLETE_TIP_NO_POPUP}>
                            {this.state.autocompleteJobTitle} 
                        </Input>
                        <Input onChange={(e)=>{data.category = e;this.setState({data})}} label="Category" ref="category" type="auto-complete-client-side" value={data.category}
                            icon={true} iconText="search" iconTip={`${AUTOCOMPLETE_TIP}${'Category'}`} onIconClick={(e)=>this.refs.popupCat.show(CAT_POPUP_JORA, data.country.toUpperCase())}>
                            {this.state.autocomplete.category}
                        </Input>
                        <Input type="select-between" onChange={(e)=>{data.salary = e.value;this.setState({data})}} value={data.salary} label="Salary" ref="salary">
                            <option value="all">all</option>
                            <option value="30000">$30,000</option>
                            <option value="50000">$50,000</option>
                            <option value="70000">$70,000</option>
                            <option value="110000">$110,000</option>
                            <option value="110000">$150,000</option>
                        </Input>
                        <Input onChange={(e)=>{data.updatedAt = e.target.value;this.setState({data})}} ref="updatedAt" label="Date Modified" type="datesince" value={data.updatedAt}></Input>
                    </div>          
                    <div className="div-filters-row">
                        <Input onChange={(e)=>{data.workType = e; this.setState({data})}} label="Work Type" ref="workType" type="auto-complete-client-side" 
                            value={data.workType} icon={true} iconText="search" iconTip={`${AUTOCOMPLETE_TIP}${'Work Type'}`} onIconClick={(e)=>this.refs.popupWorkType.show()}>
                            {this.state.autocomplete.workType}
                        </Input>
                        <Input onChange={(e)=>{data.employer = e;this.setState({data})}} label="Employer" 
                            type="auto-complete-client-side" ref="employer" 
                            value={data.employer} icon={true} iconText="info" iconTip={AUTOCOMPLETE_TIP_NO_POPUP}>
                            {this.state.autocompleteEmployers}
                        </Input>
                        <Input onChange={(e)=>{data.advertiser = e; this.setState({data})}} label={text.LBL_SITE_ID} ref="advertiser" 
                            type="auto-complete-client-side" value={data.advertiser} icon={true} iconText="search" 
                            iconTip={`${AUTOCOMPLETE_TIP}${'Advertiser'}`} onIconClick={(e)=>this.refs.popupAdvertiser.show(ADV_POPUP_JORA, data.country.toUpperCase())}> 
                            {this.state.autocompleteAdvertisers}
                        </Input>

                        <Input className="dedup-filter" label="Deduplication" ref="deduplication" type="template" icon={true} iconText="info" iconTip="Select how the presented records should be processed">
                            <SelectButton value={data.dedup || 'off'} options={[{label:'On', value:'filter'},{label:'Off', value:'off'}]}
                                ref="deduplication" onChange={(e)=>{data.dedup = e.value; this.setState({data})}}/>
                        </Input> 

                    </div>   
                    <div className="div-filters-row">
                        <Input onChange={(e)=>{data.createdAt = e.target.value; this.setState({data})}} label="Date Created" ref="createdAt" type="datesince" value={data.createdAt}></Input>
                    </div>       
                </div> 
            </SearchFilterPanel>
            </Box>
            
            {searchResultElement}
        </div>);
    }
}

export default CsJoraSearch;
