import Dispatcher from './Dispatcher';
import Messages from '../components/messages/messages'
import Wait from '../components/wait/Wait';

window.Messages = Messages;

class ViewUtils{

    static showWait(){
        try{
            Wait('show');
        }catch(e){
            setTimeout(()=>{ViewUtils.showWait()},100);
        }
    }

    static hideWait(){
        Wait('hide');
    }

    static uniqueId(){
        return Dispatcher().getUniqueId();
    }

    static addInfoMessage(message){
        return window.Messages.addInfoMessage(message);
    }

    static addWarnMessage(message){
        return window.Messages.addWarnMessage(message);
    }

    static updateMessage(index, message){
        return window.Messages.updateMessage(index, message);
    }

    static addErrorMessage(message){
        return window.Messages.addErrorMessage(message);
    }

    static isDefined(element){
        return typeof element !== 'undefined' && element!==null;
    }

    static isFunction(element){
        return typeof element === 'function';
    }

    static isBlank(value){
        return !(ViewUtils.isDefined(value) && String(value).length>0);
    }

    /**
     * This method can parse one path in to one object reference assuming like key the array value informed in keys.
     * @important When is expected some default value in not found case, is possible to pass together with key :value expected.
     * for instance 'pageSize:100' value, once some value to pageSize is not found on the path, 100 will be returned.
     * @param {*} path 
     * @param {*} objInstance 
     * @param {*} keys 
     */
    static getObjectByPath(path, objInstance, keys){
        if(ViewUtils.isDefined(path) && ViewUtils.isDefined(keys)){
            if(!ViewUtils.isDefined(objInstance)){
                objInstance = {};
            }

            let pathAsArray = path.split('/');
            for(let i=0; i<keys.length;i++){
                let fullKey = keys[i];
                let key = fullKey.indexOf(':')!==-1?fullKey.split(':')[0]:fullKey;

                let indexValue = pathAsArray.indexOf(key);
                indexValue = indexValue!==-1?++indexValue:-1;
                objInstance[key]=pathAsArray[indexValue];
                if(fullKey.indexOf(':')!==-1 && !ViewUtils.isDefined(objInstance[key])){
                    objInstance[key] = fullKey.split(':')[1];
                }else{
                    if(ViewUtils.isDefined(objInstance[key]) && objInstance[key].indexOf('+')!==-1 && ViewUtils.isFunction(objInstance[key].replace)){
                        objInstance[key] = objInstance[key].replace(/\+/g, ' ');
                    }
                }
                
            }

        } 
        return objInstance;
    }


    static castUndefinedAndNulltoEmpty(obj, props){

        for(let i=0; i<props.length; i++){
            let element = props[i];
            if(typeof obj[element] === 'undefined' || obj[element]===null){
                obj[element] = '';
            }
        }
        return obj;
    }    

    static clean(instance){
        // eslint-disable-next-line
        for(let index in instance){
            let item = instance[index];
            if(ViewUtils.isDefined(item) && ViewUtils.isFunction(item.clean)){
                item.clean();
            }
        }
    }


    static cleanFields(refsName, instance){
        let fields = refsName.split(',');
        for(let i=0; i<fields.length; i++){
            let name = fields[i];
            let ref = instance.refs[name];
            if(typeof ref==='undefined'){
                ref = instance[name];
            }

            if(this.isDefined(ref) && typeof ref.clean === 'function'){
                ref.clean();
            }
        }
    }


    static isEmpty(value){
        return typeof value === 'undefined' || value === null || value.length === 0;
    }

    static currencyFormat(value){
        value = Number(value).toLocaleString();
        return value.concat('.00');
    }

    static dateAndTime(dateParam){
        let dt = null;
        if(typeof dateParam === 'string'){
            dt = new Date(dateParam);
        }else{
            dt = dateParam;
        }

        if(dt.constructor.name==='Date'){
            return `${dt.getDate()}/${dt.getMonth()+1}/${dt.getFullYear()} ${dt.getHours()}:${dt.getMinutes()}`;
        }

        return '';
    }

    static getTimeByStrKey(strKey){
        if(typeof strKey === 'undefined' || strKey===null || strKey.length===0 || strKey === 'all'){
            return null;
        }
        let daysToAdd = strKey.replace('h','').replace('d', '');
        daysToAdd = daysToAdd === '24'?1:Number(daysToAdd);
        daysToAdd = -1*daysToAdd;
        return new Date().addDays(daysToAdd).getTime();
    }

    static hasHttpError(event){
        
        if(ViewUtils.isDefined(event) && ViewUtils.isDefined(event.currentTarget)){
            const status = event.currentTarget.status;
            if(status>=200 && status<=299){
                return false;
            }

            const MSG_ERROR = `One integration error had been received. Error details: 
                code=${status}, responsePath:${event.currentTarget.responseURL}`;
            window.Messages.addFatalMessage(MSG_ERROR);
            console.error(MSG_ERROR);
            console.error(event);
        }else{
            const MSG_ERROR_GENERIC = `One integration error had been received.`;
            window.Messages.addFatalMessage(MSG_ERROR_GENERIC);
            console.error(MSG_ERROR_GENERIC);
            console.error(event);
        }
        return true;
    }
}

export default ViewUtils;