let instance = null;
class Dispatcher{
    static getInstance(){
        if(instance===null){
            instance = new Dispatcher();
            instance.storeInstances = new Map();
        }
        return instance;
    }

    registerStore(name, storeInstance){
        if(typeof instance.storeInstances.get(name) === 'undefined' || instance.storeInstances.get(name)===null)
            instance.storeInstances.set(name, storeInstance);
        return instance;
    }

    getStore(name){
        if(typeof name !== 'string'){
            throw new DOMException(`The param name with value ${name} must be string.`);
        } 
        return instance.storeInstances.get(name);
    }

    waitFor(type){
        instance.waitFor = type;
        return instance;
    }


    dispatch(type, payload){
        if(typeof instance.storeInstances==='undefined'){
            return;
        }
        let returnedValues = new Map();
        instance.storeInstances.forEach(function(val,key){
            returnedValues.set(type,val.reduce(type,payload));
            
        });
        return returnedValues;
    }

}

export default Dispatcher.getInstance;