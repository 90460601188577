import React,{Component} from 'react';

class VerticalToolBarItem extends Component{
    constructor(props){
        super(props);
        this.state ={
            children : this.props.children
        };
    }

    render(){
        return (<div className="vertical-tool-bar-item-wrap" data-index={this.props.index}>
            {this.state.children}
        </div>);
    }
}
export default VerticalToolBarItem;



