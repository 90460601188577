import Store from '../helper/Store'
import {ON_JORA_SEARCH, ON_JORA_SEARCH_AUTOCOMPLETE_POPULATE } from '../actions/CsJoraAction';
import Dispatcher from '../helper/Dispatcher';
import ViewUtils from '../helper/ViewUtils';
import { JOB_API_HOST_JORA } from '../helper/Config';

const JOB_API_HOST = JOB_API_HOST_JORA;



class CsJoraStore extends Store{

    getSearchUrl(data){

        console.log({data});

        //fill the undefined or null filters with ''
        data = ViewUtils.castUndefinedAndNulltoEmpty(data, ['jobtitle','advertiser','category','employer', 'dedup', 'createdAt','updatedAt', 'salary', 'workType']);
        data.salary = data.salary===''?'-':data.salary;

        //adding 'es' on the timemillis case
        data.updatedAt = data.updatedAt+''.length>3?`${data.updatedAt/1000}es`:data.updatedAt;
        data.createdAt = data.createdAt+''.length>3?`${data.createdAt/1000}es`:data.createdAt;

        //make prefix short
        const filterPrefix = '&filter.';
        let f = filterPrefix;
        
        let filter = `${f}title=${this.encodeToUrlVal(data.jobtitle)}${f}advertiser=${this.encodeToUrlVal(data.advertiser)}${f}workType=${this.encodeToUrlVal(data.workType)}${f}category=${this.encodeToUrlVal(data.category)}${f}employer=${this.encodeToUrlVal(data.employer)}${f}createdAt=${data.createdAt}${f}updatedFrom=${data.updatedAt}${f}salaryMin=${data.salary.split('-')[0]}${f}salaryMax=${data.salary.split('-')[1]}`;
        let fields = '&fields=abstract,advertiser,categoryIds,content,createdAt,employer,expiresAt,language,location,metadata,rank,salary,score,title,updatedAt,url';
        let query = `?diagnostics=false&country=${this.encodeToUrlVal(data.country)}&duplicates=${data.dedup}&page=${data.page}&pageSize=${data.pageSize}&keywords=${encodeURIComponent(data.keyWords)}&locationId=${data.locationId}&sort=${data.sort}&distanceKms=${data.radius || ""}`;
        let facets = `&facets=advertiser,category,createdAt,employer,location,salaryMax,salaryMin,title,workType&facetDepth=500`;
        return `${JOB_API_HOST}${query}${facets}${fields}${filter}`;
    }

    getSearchUrlToAutocompleteOptions(data){
        let query = `?diagnostics=true&country=${data.country}&duplicates=rollup&pageSize=100&keywords=&locationId=`;
        let facets = `&facets=advertiser,category,createdAt,employer,location,salaryMax,salaryMin,title,workType&facetDepth=500`;
        return JOB_API_HOST+query+facets;
    }

    doSearch(type, payload){
        let instance = this;
        payload.result = {};
        
        instance.httpGet(instance.getSearchUrl(payload),function(a,b){
            payload.result = a;
            instance.invokeListeners(type, payload);
            payload.hasErrors = false;
        },function(responseText){
            payload.hasErrors = true;
            payload.errorMessage ='Unexpected error received!';
            instance.invokeListeners(type, payload);
        });
    }

    populateAutocomplete(type, payload){
        payload.result = null;
        let instance = this;
        
        this.httpGet(instance.getSearchUrlToAutocompleteOptions(payload),function(a, b){
            payload.autocompleteOptions = a.facets;
            instance.invokeListeners(type, payload);
        },function(e){
            payload.errorMessage='Error';
            instance.invokeListeners(type, payload);
        });
    }

    reduce(type, payload){
        switch(type){
            case ON_JORA_SEARCH: this.doSearch(type, payload); break;
            case ON_JORA_SEARCH_AUTOCOMPLETE_POPULATE: this.populateAutocomplete(type, payload); break;
            default: return null;
        }
    }
}
export default new CsJoraStore(Dispatcher());