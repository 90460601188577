import Store from '../helper/Store'
import {ON_SEEK_SEARCH, ON_SEEK_SEARCH_AUTOCOMPLETE_POPULATE, ON_SEEK_SEARCH_BY_ID } from '../actions/CsSeekAction';
import Dispatcher from '../helper/Dispatcher';
import ViewUtils from '../helper/ViewUtils';
import { JOB_API_HOST_SEEK, JOB_API_HOST_SEEK_BY_ID } from '../helper/Config';

const JOB_API_HOST = JOB_API_HOST_SEEK;
class CsSeekStore extends Store{

    getSearchUrl(data){
        //fill the undefined or null filters with ''
        data = ViewUtils.castUndefinedAndNulltoEmpty(data, ['jobtitle','advertiser','category','employer', 'dedup', 'createdAt','updatedAt', 'salary', 'workType']);
        data.salary = data.salary===''?'-':data.salary;

        //adding 'es' on the timemillis case
        data.updatedAt = data.updatedAt+''.length>3?`${data.updatedAt/1000}es`:data.updatedAt;
        data.createdAt = data.createdAt+''.length>3?`${data.createdAt/1000}es`:data.createdAt;

        //make prefix short
        const filterPrefix = '&filter.';
        let f = filterPrefix;
        
        let filter = `${f}title=${this.encodeToUrlVal(data.jobtitle)}${f}advertiser=${this.encodeToUrlVal(data.advertiser)}${f}workType=${this.encodeToUrlVal(data.workType)}${f}category=${this.encodeToUrlVal(data.category)}${f}employer=${this.encodeToUrlVal(data.employer)}${f}createdAt=${data.createdAt}${f}updatedFrom=${data.updatedAt}${f}salaryMin=${data.salary.split('-')[0]}${f}salaryMax=${data.salary.split('-')[1]}`;
        filter = this.cleanEmptyFilters(filter);
        let fields = '&fields=abstract,advertiser,categoryIds,content,createdAt,employer,expiresAt,language,location,metadata,rank,salary,score,title,updatedAt,url';
        let query = `?diagnostics=false&country=${this.encodeToUrlVal(data.country)}&duplicates=${data.dedup}&page=${data.page}&pageSize=${data.pageSize}&keywords=${this.encodeToUrlVal(data.keyWords)}&locationId=seek-${data.locationId}&sort=${data.sort}`;
        let facets = `&facets=advertiser,category,createdAt,employer,location,salaryMax,salaryMin,title,workType&facetDepth=10`;
        return `${JOB_API_HOST}${query}${facets}${fields}${filter}`;
    }

    /**
     * There is one different behaviour on the jobapi if is in use the seek search.
     * If some empty filter had been informed, the sort doesn't work like expected.
     * 
     * @param {*} data 
     */
    cleanEmptyFilters(url){
        if(typeof url!=='undefined' && url!==null){
            let checkBy = ['title', 'advertiser', 'workType', 'category', 'employer', 'createdAt', 'updatedFrom', 'salaryMin', 'salaryMax'];
            url = url+'&';
            // eslint-disable-next-line
            for(let key of checkBy){
                url = url.replace(`&filter.${key}=&`,'&');
            }
            url = url.substr(0, url.length-1);
        }
        return url;
    }




    getSearchUrlToAutocompleteOptions(data){
        let query = `?diagnostics=true&country=${data.country}&duplicates=rollup&pageSize=100&keywords=&locationId=`;
        let facets = `&facets=advertiser,category,createdAt,employer,location,salaryMax,salaryMin,title,workType&facetDepth=50`;
        return JOB_API_HOST+query+facets;
    }

    doSearch(type, payload){
        let instance = this;
        payload.result = {};
        instance.httpGet(instance.getSearchUrl(payload),function(a,b){
            payload.result = a;
            instance.invokeListeners(type, payload);
            payload.hasErrors = false;
        },function(responseText){
            payload.hasErrors = true;
            payload.errorMessage ='Unexpected error received!';
            instance.invokeListeners(type, payload);
        });
        
    }

    matchJobSchema(job){
        let jc = {...job.job};
        jc.id = jc.globalJobId;
        jc.categoryReady = jc.categories;
        jc.categoryReady.forEach(e=>e.label=e.name);
        jc.categoryIds=[];
        jc.categories.forEach(e=>jc.categoryIds.push(e.id));
        jc.content = jc.description;
        jc.language='EN';
        jc.rank=1;
        return {
            jobs:[jc],
            page:{}
        };
    }

    populateAutocomplete(type, payload){
        payload.result = null;
        let instance = this;
        
        this.httpGet(instance.getSearchUrlToAutocompleteOptions(payload),function(a, b){
            payload.autocompleteOptions = a.facets;
            instance.invokeListeners(type, payload);
        },function(e){
            payload.errorMessage='Error';
            instance.invokeListeners(type, payload);
        });
    }

    doSearchById(type, p){
        let payload = {...p};
        payload.result = {};
        //searching job by Id
        this.httpGet(`${JOB_API_HOST_SEEK_BY_ID}/seek:${payload.jobId}`,(a,b)=>{
            payload.result = this.matchJobSchema(a);
            payload.hasErrors = false;
            this.invokeListeners(type, payload);
        },(error)=>{
            payload.hasErrors = true;
            payload.errorMessage = error;
            console.error(error);
            this.invokeListeners(type, payload);
        });
    }

    reduce(type, payload){
        switch(type){
            case ON_SEEK_SEARCH: this.doSearch(type, payload); break;
            case ON_SEEK_SEARCH_BY_ID: this.doSearchById(type, payload); break;
            case ON_SEEK_SEARCH_AUTOCOMPLETE_POPULATE: this.populateAutocomplete(type, payload); break;
            default: return null;
        }
    }
}
export default new CsSeekStore(Dispatcher());