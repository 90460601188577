import React,{Component} from 'react';
import Box from '../box/Box';
import { Chart } from 'primereact/components/chart/Chart';
import {Button} from 'primereact/components/button/Button';
import ViewUtils from '../../helper/ViewUtils';

class ChartBox extends Component{


    constructor(props){
        super(props);
        this.directItems = ['Category','Classification','Location', 'Site Id', 'Advertiser'];
        this.state = {
            selected:this.props.data[0],
            visible:this.props.visible
        };
    }

    setVisible(visible){
        this.setState({visible});
    }


    onSelectChart(dataItem){
        if(this.state.selected===null || dataItem.name!== this.state.selected.name || this.state.selected.value!==dataItem.value){
            this.setState({selected:dataItem});
        }
        
    }

    getChartData(selectedItem){
        if(selectedItem && selectedItem.value){
            const colors = ["#2399e5","#5cb85c","#5bc0de","#f0ad4e","#d9534f","#cacaca",'#7d7cf','#49ed19','#e4d9cb','#ee8785','#488917','#a878a4','#a10ac5','#698479','#335c63','#4cf56c','#a273f1','#141877','#3097f5','#e41250','#bed896','#6b24b2','#c33f1a','#baef6a','#fc74ab'];
            let max = 0;
            let chartData = {labels:[], datasets: [
                {
                    data: [],
                    backgroundColor: colors,
                    hoverBackgroundColor: colors
                }]    
            };
            // eslint-disable-next-line
            for(let current of selectedItem.value){
                max++;
                if(max===selectedItem.maxItem){
                    break;
                }
    
                if(selectedItem.name.indexOf('Salary')!==-1){
                    chartData.labels.push(current.formattedValue);
                    chartData.datasets[0].data.push(current.value);
                }if(this.directItems.indexOf(selectedItem.name)!==-1){
                    chartData.labels.push(current.label);
                    chartData.datasets[0].data.push(current.count);
                }else{
                    if(ViewUtils.isDefined(current.size)){
                        chartData.labels.push(current.value);
                        chartData.datasets[0].data.push(current.size);
                    }else{
                        chartData.labels.push(current.key);
                        chartData.datasets[0].data.push(current.value);
                    }
                }
            }
            return chartData;
        }
        return null;
    }

    render(){
            let chartData = this.getChartData(this.state.selected);
            let buttons = [];
            let key = new Date().getMilliseconds();
            // eslint-disable-next-line
            for(let dataItem of this.props.data){
                if(this.props.type==='SEEK' && dataItem.name==="Employer") continue;
                let selectedButton = typeof this.state.selected!=='undefined' && this.state.selected!==null && this.state.selected.name===dataItem.name;
                buttons.push(<Button key={`${dataItem.name}-${key++}`} 
                    className={`${selectedButton?'p-button-primary':'p-button-success'}`} 
                    onClick={(e)=> this.onSelectChart(dataItem)} icon={`${selectedButton?'fa fa-arrow-right':''}`} 
                    iconPos="right" label={dataItem.name}/>);
            }

            let summaryRows = [];
            let max = 0;
            if(ViewUtils.isDefined(this.state.selected.value)){
                // eslint-disable-next-line
                for(let current of this.state.selected.value){
                    max++;
                    if(max===this.state.selected.maxItem){
                        max = 0;
                        break;
                    }
                    let label = ViewUtils.isDefined(current.size)?current.value:current.key;
                    let value = ViewUtils.isDefined(current.size)?current.size:current.value;
                    if(this.state.selected.name.indexOf('Salary')!==-1){
                        label = current.formattedValue;
                    }else if(this.directItems.indexOf(this.state.selected.name)!==-1){
                        label = current.label;
                        value = current.count;
                    }
                    summaryRows.push(<tr key={`row-${key++}`}>
                        <td>{label}</td>
                        <td>{value}</td>
                    </tr>);
                }
            }

        return (<Box title={this.props.title} className={this.props.className} style={{display:`${this.state.visible===false?'none':'block'}`}}>
            <div className="display-table full-size">
                <div  className="display-table-row">
                    <div className="display-cell center top" style={{width:'180px'}}>
                        
                            <div className="chart-box-block-btns">
                                {buttons}
                            </div>
                        
                    </div>
                    <div className="display-cell center top" style={{width:'350px'}}>
                        <Box title={`${this.state.selected.name} summary`}>
                            <table className="table-summary-search">
                                <thead>
                                    <tr>
                                        <th>{this.state.selected.name}</th>
                                        <th>Jobs</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {summaryRows}
                                </tbody>
                            </table>
                        </Box>

                    </div>
                    <div className="display-cell center top" style={{width:'50%'}}>
                        <Box title={`${this.state.selected.name} chart`}>
                            <Chart type="pie" data={chartData} />
                        </Box>
                    
                    </div>
                </div>
            </div>
        </Box>)
    }
}

export default ChartBox