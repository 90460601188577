/* eslint-disable */
import React, { Component} from 'react'
import PropTypes from 'prop-types';
import './InputAutoComplete.css'
import './cache-api.min.js'
import ViewUtils from '../../helper/ViewUtils';
import $ from 'jquery';
import httph from '../../helper/HttpHelper';

class InputAutoComplete extends Component{
    constructor(props){
        super(props);
        this.cacheApi = window.CacheApi;
        this.state = {
            items:[],
            url:props.httpGetUrl
        }
        if(ViewUtils.isDefined(this.props.value)){
            this.state.inputValue = this.props.value;
        }
        if(ViewUtils.isDefined(props.defaultValue)){
            this.state.inputValue = props.defaultValue;
        }
        this.currentSelectedItem = null;
        let currentInstance = this;
        this.cacheApi.options().onCacheStored = function(key, value){currentInstance.dispatcher(props.onCacheStored, key, value);};
        this.cacheApi.options().onCacheExpired = function(key, value){currentInstance.dispatcher(props.onCacheExpired, key, value);};
        this.cacheApi.options().onMaxSize = function(){currentInstance.dispatcher(props.onCacheMaxSize);};
        this.cacheApi.options().maxSize=props.cacheMaxSize;
        this.cacheApi.options().timeOut=props.cacheTimeOut;
    }

    dispatcher(func, param1, param2){
        if(typeof func === 'function'){
            func(param1, param2);
        }
    }

    onChange(e){
        this.dispatcher(this.props.onInputChange, e.target.value);
        this.setState({inputValue:e.target.value});
        let instance = this;
        if(this.state.url!=null){
            let responseData = this.props.cache?this.cacheApi.cache(this.state.url+e.target.value):null;
            if(responseData==null){
                this.doGet(this.state.url, e.target.value, (resp)=>{
                    responseData = resp; 
                    instance.dispatcher(instance.props.onHttpSuccess, instance.renderItems(responseData));
                },(error)=>{
                    console.log(error);
                    instance.dispatcher(instance.props.onHttpError, error);
                });
            }else{
                instance.dispatcher(this.props.onHttpSuccess,this.renderItems(responseData));
            }
        }else{
            if(typeof this.props.children!=='undefined' && this.props.children!=null && this.props.children.length>0){
                let items = [];
                let currentInstance = this;
                for(let element of this.props.children){
                    if(e.target.value!=='' && element.props['data-search-key'].toUpperCase().indexOf(e.target.value.toUpperCase())!==-1){
                        items.push(<div key={element.props.id} className="input-auto-complete-div-result-item" 
                        textvalue={element.props.value} idvalue={element.props.id} 
                        onClick={(event)=> currentInstance.itemClicked(event)}>
                        {element.props.children}</div>); 
                    }
                    if(items.length===10){
                        break;
                    }
                }                
                /*this.props.children.forEach(function(element){
                });*/
                this.showDivResult(items.length>0);
                this.setState({items});
                this.currentSelectedItem = null;
            }
        }

        

    }

    doGet(httpUrl, inputValue, onsuccess, onerror){
        const url = httpUrl+inputValue 
        httph.get(url, (response)=>{
            if(this.props.cache){
                this.cacheApi.cache(url, response.data);    
            } 
            onsuccess(response.data);
        },(error)=>{
            if(error.response && error.response.status && error.response.status === 404){
                onsuccess(null);
            }
            onerror(error);
        });
    }

    itemClicked(event){
        let element = event.target;
        if($(element).hasClass('input-auto-complete-div-result-item')){
            this.itemSelected(element);
        }else{
            this.itemSelected($(element).closest('div.input-auto-complete-div-result-item')[0]);
        }
    }

    itemSelected(element){
        this.inputElement.value = element.getAttribute('textvalue');
        this.inputElement.idvalue = element.getAttribute('idvalue');
        this.showDivResult(false);
        if(this.currentSelectedItem!=null && this.currentSelectedItem.classList!=null){
            this.currentSelectedItem.classList.remove('selected');
        }
        this.dispatcher(this.props.onSelectValue,this.inputElement.idvalue ,{id:this.inputElement.idvalue, value:this.inputElement.value});
        this.setState({inputValue:this.inputElement.value});
    }

    renderItems(responseData){
        let items = [];
        if(Array.isArray(responseData)){
            if(responseData!=null){
                let results = responseData;
                results.forEach((element) => {
                    if(typeof element === 'object'){
                        items.push(<div key={element.id || element.geonameid} className="input-auto-complete-div-result-item" 
                            textvalue={element.displayText || element.display_name} idvalue={element.id || element.geonameid} onClick={(event)=> this.itemClicked(event)}>
                        {element.displayText || element.display_name} 
                    </div>)
                    }else{
                        items.push(<div key={element} className="input-auto-complete-div-result-item" textvalue={element} idvalue={element} onClick={(event)=> this.itemClicked(event)}>
                        {element} 
                    </div>)
                    }
                    
                });
            }
            this.showDivResult(items.length>0);
            this.setState({items});
            this.currentSelectedItem = null;
            return responseData;
        }else{//another supported case
            if(responseData!== 'undefined' && responseData!==null){
                let castedArray  = this.castResponseDataToArray(responseData); //cast values to array and calling again the same method
                return this.renderItems(castedArray);
            }else{
                return [];
            }
        }
    }

    castResponseDataToArray(responseData){
        if(typeof responseData !== 'undefined' && responseData!==null){
            return responseData.local;
        }
    }


    showDivResult(show){
        if(ViewUtils.isDefined(this.divResult)){
            if(show){
                this.divResult.style.display='block';
            }else{
                this.divResult.style.display='none';
            }
        }

    }

    hasVisibleResults(){
        return this.divResult.style.display==='block';
    }

    changeItemSelection(keyCode){
        if(keyCode===40 || keyCode===38){
            let currentSelectionIndex = null;
            for(let index = 0; index < this.divResult.childNodes.length; index++){
                let element = this.divResult.childNodes[index];
                
                if(element.className.indexOf('selected')!==-1){
                    element.classList.remove('selected');
                    currentSelectionIndex = index;
                }
            }
            let indexToBeSelected = 0;
            if(keyCode===38 && currentSelectionIndex!=null && currentSelectionIndex>0){
                indexToBeSelected = currentSelectionIndex-1;
            }
            
            if(keyCode===40 && currentSelectionIndex!==null && currentSelectionIndex<this.divResult.childNodes.length-1){
                indexToBeSelected = currentSelectionIndex+1;
            }
            this.divResult.childNodes[indexToBeSelected].classList.add('selected');
            this.currentSelectedItem = this.divResult.childNodes[indexToBeSelected];
        }else if(keyCode===13){
           if(this.currentSelectedItem!=null){
               this.itemSelected(this.currentSelectedItem);

           }
        }else if(keyCode===46 || keyCode===27){
            this.cancelAutocomplete();
        }
    }

    cancelAutocomplete(){
        if(this.currentSelectedItem!=null){
            this.currentSelectedItem.classList.remove('selected');
        }
        this.showDivResult(false);
        this.dispatcher(this.props.onCancel);
    }

    focus(){
        if(ViewUtils.isDefined(this.inputElement)){
            this.inputElement.focus();  
        }
    }

    clean(){
        if(ViewUtils.isDefined(this.inputElement)){
            this.inputElement.value = '';
            this.onChange({target:this.inputElement});
        }
    }

    keyDownPressed(event){
        if(this.hasVisibleResults()){
            this.changeItemSelection(event.keyCode);
            event.stopPropagation();            
        }
    }

    setUrl(url){
        this.setState({url});
    }


    render(){
        return (<div className={'input-auto-complete '+this.props.className} ref={(rootElement) => this.rootElement = rootElement}>
            <input id={this.props.inputId} type="text" className={'input-auto-complete-input '+this.props.classNameInput} onKeyDown={(event)=> this.keyDownPressed(event)}
                ref={(inputElement) => this.inputElement = inputElement} value={this.props.value} autoComplete="off" onBlur={()=>setTimeout(()=>this.showDivResult(false),300)}
                placeholder={this.props.placeholder} required={this.props.required} onChange={(e)=> this.onChange(e)}/>
            <div className={'input-auto-complete-div-result '+this.props.classNameDivResult} ref={(divResult) => this.divResult = divResult}>
                {this.state.items}
            </div>
        </div>);
    }

    static propTypes = {
        className : PropTypes.string,
        placeholder : PropTypes.string,
        classNameInput : PropTypes.string,
        required : PropTypes.string,
        classNameDivResult : PropTypes.string,
        defaultValue : PropTypes.string,
        cache:PropTypes.bool,
        cacheMaxSize:PropTypes.number,
        cacheTimeOut:PropTypes.number,
        httpGetUrl:PropTypes.string,
        inputId:PropTypes.string,
        onInputChange:PropTypes.func,
        onSelectValue:PropTypes.func,
        onHttpSuccess:PropTypes.func,
        onHttpError:PropTypes.func,
        onCancel:PropTypes.func,
        onCacheStored:PropTypes.func,
        onCacheExpired:PropTypes.func,
        onCacheMaxSize:PropTypes.func,
   }

   static defaultProps = {
        className : '',
        placeholder : '',
        classNameInput : '',
        classNameDivResult : '',
        cache:true,
        cacheMaxSize:500,
        cacheTimeOut:600000,
        httpGetUrl:null
    }
}

export default InputAutoComplete;
