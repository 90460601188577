import React, {Component} from 'react';
import {render} from 'react-dom';
import './Wait.css'

let instance = null;
class Wait extends Component{
    
    render(){
        return this.state && this.state.visible===true ? <div className="div-wait-modal"><div className="div-wait-icon spin"/></div> : '';
    }

    static getInstance(method, div){
        if(instance === null){
            document.body.appendChild(div = document.createElement('div'));
            render(<Wait ref={(r)=> instance = r}/>, div);
            
        }    
        instance.setState({visible:method==='show'});
        return instance;
    }
}

export default Wait.getInstance;