import Dispatcher from "../helper/Dispatcher";
import AuthStore from "../stores/AuthStore";

export default {
    logIn(payload){
        Dispatcher().dispatch(LOG_IN, payload);
    },
    logOut(payload){
        Dispatcher().dispatch(LOG_OUT, payload);
    },
    revalidateLogin(payload){
        Dispatcher().dispatch(REVALIDATE_LOGIN, payload);
    },
    addStoreEventListener(fName, fn){
        if(!Dispatcher().getStore('AuthStore')){
            Dispatcher().registerStore('AuthStore', AuthStore);
        }
        Dispatcher().getStore('AuthStore').addStoreEventListener(fName, fn);
    },

    authorize(){
        Dispatcher().dispatch(AUTHORIZE,{});
    },
    authorizeAsync(){
        Dispatcher().dispatch(AUTHORIZE_ASYNC,{});
    }
}
export const LOG_IN = 'log-in';
export const AUTHORIZE = 'auth-store-authorize';
export const AUTHORIZE_ASYNC = 'auth-store-authorize-async';
export const LOG_OUT = 'log-out';
export const REVALIDATE_LOGIN = 'revalidate-log-in';
export const STORE_AUTH = 'cs-store-user-auth';
export const EMAIL_NOT_VERIFIED = 'email-not-verified';