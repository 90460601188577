import React, { Component } from 'react';

class TabItem extends Component{
    constructor(props){
        super(props);
        this.state = {
            id:''
        };
    }


    render(){
        return (<div className={'tab-item header-'+this.props.index} id={this.state.id}>
            {this.props.children}
        </div>);
    }
}

export default TabItem;