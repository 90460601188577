import React,{Component} from 'react';
import './PanelJobItem.css'
import PropTypes from 'prop-types';
import $ from 'jquery'
import ViewUtils from '../../helper/ViewUtils';
import Box from '../box/Box';
import Tab from '../tab/Tab';
import TabItem from '../tab/TabItem';
import {TieredMenu} from 'primereact/components/tieredmenu/TieredMenu';
import AdPerformancePanel from '../AdPerformancePanel';
import { If } from '../logic/Logic';
import AdPerformanceAction, { FETCH_DATA } from '../../actions/AdPerformanceAction';

class PanelJobItem extends Component{

    constructor(props){
        super(props);
        this.state = {
            detailsOpened : false,
            sourceOpened : false,
            categories:[],
            adPerformanceData:{
                loaded:false
            }
        };
    }

    componentDidMount(){
        AdPerformanceAction.addStoreEventListener(`ap-evt-listener-seek-job-${this.props.job.id}`,(type, payload)=>{
            if(type===FETCH_DATA && payload.job && payload.job.id===this.props.job.id){
                this.setState({adPerformanceData:payload});
            }
        });
    }

    getCategories(payload, self){
        let categories = [];
        if(!ViewUtils.isEmpty(payload.categoryReady)){
            payload.categoryReady.forEach((element)=>{
                categories.push(<div key={`${element.label}-${element.id}`}>{element.label} ({element.id})</div>);
            });
        }
        return categories;
    }

    isSeekJob(){
        return (this.props.job.id.indexOf('seek')!==-1);
    }

    isJoraJob(){
       return (this.props.job.id.indexOf('jora')!==-1);
    }

    getValue(obj, prop){
        return (typeof obj !== 'undefined' && obj!==null && typeof obj[prop] !== 'undefined' && obj[prop]!==null? obj[prop]:'');
    }

    getLocation(){
        return (this.props.job.locations && this.props.job.locations.length>0 && this.props.job.locations[0].value) || ""
    }

    getArrayValues(obj){
        return typeof obj !== 'undefined' && obj!==null && obj.length > 0? obj.join(','):'';
    }

    getWorkTypes(obj){
        if(this.isJoraJob()){
            let returnValues = [];
            let values = obj['work_types'];
            if(ViewUtils.isDefined(values)){
                for(let i = 0; i< values.length; i++){
                    let item = values[i];
                    returnValues.push(item.name);
                }
            }
            return returnValues.join(' or ');
        }else if(this.isSeekJob()){
            return obj.workType.name;
        }
    }

    getFormattedSalary(salary){
        if(typeof salary === 'undefined' || salary===null){
            return '';
        }

        return `${ViewUtils.currencyFormat(salary.min)} to ${ViewUtils.currencyFormat(salary.max)} (${salary.currency || this.getSalaryByCurrencyCode(salary.currencyCode)} - ${salary.period})`;
    }

    getSalaryByCurrencyCode(code){
        switch(code){
            case 0: return 'AUS';
            case 1: return 'NZD';
            default:return '';
        }
    }

    getContent(content){
        return  { __html: content };
    }



    getClientSearchKeyValues(){
        let json = null;
        if(this.isJoraJob()){
            json = {
                categoryIds:this.props.job.categoryIds,
                employer: this.props.job.employer,
                salary:this.props.job.salary ? this.props.job.salary.max : 0,
                createdAt: new Date(this.props.job.createdAt).getTime(),
                title:this.props.job.title,
                workType:this.props.job.metadata.work_types
            };
        }else if(this.isSeekJob()){
            json = {
                categoryIds:this.props.job.categoryIds,
                employer: this.props.job.employer,
                salary:this.props.job.salary.max,
                createdAt: new Date(this.props.job.createdAt).getTime(),
                title:this.props.job.title,
                workType:[this.props.job.metadata.workType]
            };
        }
        return JSON.stringify(json);
    }

    renderDetailsInCard(){
        return (<div className="card-table">
                <div className="div-panel-job-item-details">
                    <div className="row icon-label">

                            <div className="cell lbl">
                                <i className="fa fa-building"/>
                                <span className="small-icon-sub-title">Organization</span>
                            </div>
                            <div className="cell left">
                                {this.getValue(this.props.job.employer,'name')}
                            </div>
                    </div>

                    <div className="row icon-label">
                            <div className="cell lbl">
                                <i className="material-icons">timelapse</i>
                                <span className="small-icon-sub-title">Type</span>
                            </div>
                            <div className="cell left">
                            {this.getWorkTypes(this.props.job.metadata)}
                            </div>
                    </div>
                    <div className="row icon-label">
                            <div className="cell lbl">
                                <i className="fa fa-calendar"/>
                                <span className="small-icon-sub-title">When</span>
                            </div>
                            <div className="cell left">
                                Created at: {ViewUtils.dateAndTime(this.props.job.createdAt)}<br/>
                                Updated at: {ViewUtils.dateAndTime(this.props.job.updatedAt)}<br/>
                                Expires at: {ViewUtils.dateAndTime(this.props.job.expiresAt)}
                            </div>
                    </div>
                    <div className="row icon-label">

                        <div className="cell lbl">
                                <i className="fa fa-globe"/>
                                <span className="small-icon-sub-title">Where</span>
                            </div>
                            <div className="cell left">
                                {this.getValue(this.props.job.location,'value') || this.getValue(this.props.job.location,'description') || this.getLocation()}
                            </div>
                    </div>

                    <div className="row icon-label">
                            <div className="cell lbl">
                                <i className="fa fa-language"/>
                                <span className="small-icon-sub-title">Language</span>
                            </div>
                            <div className="cell left upper">
                                {this.props.job.language}
                            </div>
                   </div>

                   <div className="row icon-label">

                         <div className="cell lbl">
                                <i className="fa fa-dollar"/>
                                <span className="small-icon-sub-title">Salary</span>
                            </div>
                            <div className="cell left">
                                {this.getFormattedSalary(this.props.job.salary)}
                            </div>
                    </div>

                    <div className="row icon-label">
                             <div className="cell lbl">
                                <i className="fa fa-trophy"/>
                                <span className="small-icon-sub-title">Rank</span>
                            </div>
                            <div className="cell left">
                                {this.props.job.rank}th
                            </div>
                      </div>

                    <div className="row icon-label">
                            <div className="cell lbl">
                                <i className="fa">ad</i>
                                <span className="small-icon-sub-title">Advertiser</span>
                            </div>
                            <div className="cell left">
                                {`${this.getValue(this.props.job.advertiser,'name')} (${this.getValue(this.props.job.advertiser,'id')})`}
                            </div>
                    </div>

                    <div className="row icon-label">
                            <div className="cell lbl">
                                <i className="fa text">cat</i>
                                <span className="small-icon-sub-title">Categories</span>
                            </div>
                            <div className="cell left">
                                {this.getCategories(this.props.job)}
                            </div>
                        </div>


            </div></div>);


}

    onIframeLoad(e){
        $(e.currentTarget).parent().find('div.internal-spin-wait').remove();
    }


    renderSource(){
        if(this.state.sourceOpened){
            return (<div className="div-panel-job-item-source">
                <div>
                    <div className="div-wait-icon-internal spin internal-spin-wait"/>
                    <iframe title={this.props.job.id} id={this.props.job.id} className="div-panel-job-item-source-iframe" src={this.props.job.url} onLoad={(e)=>{this.onIframeLoad(e)}}></iframe>
                </div>
            </div>);
        }
        return '';
    }

    componentDidUpdate(){
        let instance = this;
        if(!this.state.detailsOpened){
            $(this.refs.jobBody).slideUp(200, function(){
                $(instance.refs.jobAbstract).fadeIn(200);
            });
        }else{
            $(instance.refs.jobAbstract).fadeOut(200,function(){
                $(instance.refs.jobBody).slideDown(200);
            });
        }
    }

    toggle(){
        this.setState({detailsOpened:!this.state.detailsOpened});
    }

    showDetails(){
        this.setState({detailsOpened:true});
    }

    hideDetails(){
        this.setState({detailsOpened:false});
    }

    getJoraLink(context){
        switch(context){
            case 'firefly' :{
                return `http://firefly.ssod.skinfra.xyz/jobs/${this.props.job.id.replace('jora:','')}`;
            }
            case 'permalink' :{
                let id = this.props.job.id.replace('jora:','');
                let title = this.props.job.title.replace(/ /g,'-');
                return `http://${this.props.country}.jora.com/job/${title}-${id}`;
            }
            case 'permalink-rd' :{
                return `http://${this.props.job.country}.jora.com/jobs/rd-${this.props.job.id.replace('jora:','')}`;
            }

            default: return this.props.job.url;
        }
    }


    linkItems(){
        if(this.isJoraJob()){
            let items = [
                {
                    label: 'Origin',
                    url:this.getJoraLink(),
                    target:"_blank",
                    icon:"fa fa-code"
                },
                {
                    label: 'Firefly',
                    url:this.getJoraLink('firefly'),
                    target:"_blank",
                    icon:"fa fa-fire"
                },
                {
                    label: 'Permalink',
                    url:this.getJoraLink('permalink'),
                    target:"_blank",
                    icon:"fa fa-link"
                },
                {
                    label: 'Permalink (-rd)',
                    url:this.getJoraLink('permalink-rd'),
                    target:"_blank",
                    icon:"fa fa-unlink"
                }
            ];

            // eslint-disable-next-line
            return (<a className="display-cell vertical-align-middle cursor-pointer" onClick={(event) => this.menu.toggle(event)}>
                        <i className="material-icons icon-toolbox vertical-align-middle margin-right">open_in_browser</i>
                        <TieredMenu model={items} popup={true} ref={el => this.menu = el} />
                    </a>
            );
        }
        return (<a className="display-cell vertical-align-middle" href={this.props.job.url} target="_blank" rel="noopener noreferrer">
                    <i className="material-icons icon-toolbox vertical-align-middle margin-right">open_in_browser</i>
                </a>);
    }


    render(){
        let colapseIcon = this.state.detailsOpened===true?'expand_less':'expand_more';
        let additionalLinks = this.linkItems();


        return (<div className="div-panel-job-item-root" client-search-values={this.getClientSearchKeyValues()}>
                    <div className="div-box-wrap">
                        <div className="div-box-header">
                            <div className="display-table full-size">
                                <span className="full-size display-cell">
                                {/* eslint-disable-next-line */}
                                <a onClick={(e)=>{this.setState({detailsOpened:!this.state.detailsOpened});}} className="job-title-link">{this.props.job.title}</a>{` ${this.props.showJobId?'[id:'+this.props.job.id.replace('jora:','').replace('seek:','')+']':''}`}</span>
                                {additionalLinks}

                                {/* eslint-disable-next-line */}
                                <a className="display-cell vertical-align-middle toogle-btn"
                                    onClick={(e)=>{this.setState({detailsOpened:!this.state.detailsOpened});}} ><i title={`${this.state.detailsOpened===false?'Show':'Hide'} the job details`} className="material-icons icon-toolbox vertical-align-middle margin-right">{colapseIcon}</i></a>
                            </div>
                        </div>
                        <div ref="jobAbstract" className="div-box-body">
                            <div dangerouslySetInnerHTML={this.getContent(this.props.job.abstract)}></div>
                            </div>
                        <div className="div-box-body hidden" ref="jobBody">


                            <div className="display-table full-size" >
                                <div className="display-cell full-size">
                                    <If condition={this.isSeekJob()}>
                                    <Tab onTabClick={(e)=>{if(e.tabId==='header-ad-perf'){AdPerformanceAction.fetchData({job:this.props.job,country:this.props.country});}}}>
                                        <TabItem title="Job Content" index="content">
                                            <div className="div-panel-job-item-job-content" dangerouslySetInnerHTML={this.getContent(this.props.job.content)}></div>
                                        </TabItem>
                                        <TabItem title="Abstract" index="abstract">
                                            <div className="job-abstract"><div dangerouslySetInnerHTML={this.getContent(this.props.job.abstract)}></div></div>
                                        </TabItem>
                                    </Tab>
                                    </If>
                                    <If condition={this.isJoraJob()}>
                                    <Tab>
                                        <TabItem title="Job Content" index="content">
                                            <div className="div-panel-job-item-job-content" dangerouslySetInnerHTML={this.getContent(this.props.job.content)}></div>
                                        </TabItem>
                                        <TabItem title="Abstract" index="abstract">
                                            <div className="job-abstract"><div dangerouslySetInnerHTML={this.getContent(this.props.job.abstract)}></div></div>

                                        </TabItem>
                                    </Tab>
                                    </If>
                                </div>
                                <div className="display-cell job-details-card">
                                    <Box title="Job Details">
                                    {this.renderDetailsInCard()}

                                    </Box>
                                </div>
                            </div>

                        </div>
                    </div>
        </div>);
    }

    static propTypes = {
        job:PropTypes.object.isRequired,
        showJobId:PropTypes.bool,
        country:PropTypes.string
   }
}
export default PanelJobItem;
