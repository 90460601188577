import Dispatcher from "../helper/Dispatcher";

export default {
    findInJoraCategories:function(fn){
        Dispatcher().dispatch(JORA_CATEGORIES, fn);
    },
    findInSeekEmployers:function(fn){
        Dispatcher().dispatch(SEEK_EMPLOYES, fn);
    },
    findInJoraWorkType:function(fn){
        Dispatcher().dispatch(JORA_WORK_TYPE, fn);
    },
    findInSeekAdvertiser:function(fn){
        Dispatcher().dispatch(SEEK_ADVERTISER, fn);
    },
    findInSeekCategory:function(fn){
        Dispatcher().dispatch(SEEK_CATEGORIES, fn);
    },
    findInJoraAdvertiser:function(fn){
        Dispatcher().dispatch(JORA_ADVERTISER, fn);
    },
    findInJoraEmployer:function(fn){
        Dispatcher().dispatch(JORA_EMPLOYERS, fn);
    },
    findInJoraJobTitle:function(fn){
        Dispatcher().dispatch(JORA_JOB_TITLE, fn);
    },
    findInSeekJobTitle:function(fn){
        Dispatcher().dispatch(SEEK_JOB_TITLE, fn);
    },
    addStoreEventListener(fName, fn){
        Dispatcher().getStore('BasicDataStore').addStoreEventListener(fName, fn);
    },
    mapJoraAutoCompleteResults(results){
        Dispatcher().dispatch(MAP_JORA_AUTOCOMPLETE_RESULTS, results);
    },
    mapJoraAutoCompleteResultsFilters(results){
        Dispatcher().dispatch(MAP_JORA_AUTOCOMPLETE_RESULTS_FILTERS, results);
    },
    mapJoraCategoriesByIds(payload){
        Dispatcher().dispatch(MAP_JORA_CATEGORIES_BY_IDS, payload);
    },
    mapSeekCategoriesByIds(payload){
        Dispatcher().dispatch(MAP_SEEK_CATEGORIES_BY_IDS, payload);
    },
    findGeocode(location, country){
        Dispatcher().dispatch(FIND_GEOCODE, {location, country});
    },
    mapSeekAutoCompleteResultsFilters(results){
        Dispatcher().dispatch(MAP_SEEK_AUTOCOMPLETE_RESULTS_FILTERS, results);
    },
    mapSeekAutoCompleteResults(results){
        Dispatcher().dispatch(MAP_SEEK_AUTOCOMPLETE_RESULTS, results);
    },
    findInSeekParentClassifications(country){
       Dispatcher().dispatch(SEEK_PARENT_CLASSIFICATIONS, {country}); 
    },
    findInJoraParentClassifications(country){
        Dispatcher().dispatch(JORA_PARENT_CLASSIFICATIONS, {country}); 
    },
    fetchAppMenu(){
        Dispatcher().dispatch(FETCH_APP_MENU); 
    },
    updateUserProfile(user){
        Dispatcher().dispatch(UPDATE_USER_PROFILE, user); 
    }
};

export const SEEK_PARENT_CLASSIFICATIONS = 'basic-data-seek-parent-classifications'
export const JORA_PARENT_CLASSIFICATIONS = 'basic-data-jora-parent-classifications'
export const JORA_CATEGORIES = 'basic-data-jora-categories';
export const JORA_EMPLOYERS = 'basic-data-jora-employers';
export const SEEK_CATEGORIES = 'basic-data-seek-categories';
export const SEEK_EMPLOYES = 'basic-data-seek-employes';
export const JORA_JOB_TITLE = 'bacis-data-jora-job-title';
export const SEEK_JOB_TITLE = 'bacis-data-seek-job-title';
export const SEEK_ADVERTISER = 'basic-data-seek-advertiser';
export const JORA_ADVERTISER = 'basic-data-jora-advertiser';
export const SEEK_CLASS_SUB_CLASS = 'basic-data-seek-class-sub-class';
export const SEEK_WORK_TYPE = 'basic-data-seek-work-type';
export const JORA_WORK_TYPE = 'basic-data-jora-work-type';
export const MAP_JORA_AUTOCOMPLETE_RESULTS = 'basic-data-map-jora-autocomplete-results';
export const MAP_JORA_AUTOCOMPLETE_RESULTS_FILTERS = 'basic-data-map-jora-autocomplete-results-filters';
export const MAP_SEEK_AUTOCOMPLETE_RESULTS = 'basic-data-map-seek-autocomplete-results';
export const MAP_SEEK_AUTOCOMPLETE_RESULTS_FILTERS = 'basic-data-map-seek-autocomplete-results-filters';
export const MAP_JORA_CATEGORIES_BY_IDS = 'basic-data-map-jora-categories-by-ids';
export const MAP_SEEK_CATEGORIES_BY_IDS = 'basic-data-map-seek-categories-by-ids';
export const FIND_GEOCODE = 'basic-data-find-geocode';
export const FETCH_APP_MENU = 'fetch-app-menu';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const JORA_COUNTRIES = [
    'AE', 'AU', 'BD', 'CA',
    'HK', 'ID', 'IE', 'IN',
    'KH', 'MM', 'MY', 'NZ',
    'PH', 'PK', 'QA', 'SG',
    'TH', 'UK', 'VN', 'ZA',
    'LA'
  ].sort();

export const JORA_RADIUS = [
    5,10,25,50,100
  ];