import { ON_SELECT_STORED_FILTER_BY_PATH, ON_PERSIST_CURRENT_FILTERS } from "./MainViewAction";
import Dispatcher from "../helper/Dispatcher";
export default {
    doSearch:function(data){
        Dispatcher().dispatch(ON_SEEK_SEARCH, data);
    },
    persistCurrentFilters:function(path, data, key){
        Dispatcher().dispatch(ON_PERSIST_CURRENT_FILTERS, {path, data, key});
    },
    selectStoredFilterByPath:function(path){
        return Dispatcher().dispatch(ON_SELECT_STORED_FILTER_BY_PATH, path).get(ON_SELECT_STORED_FILTER_BY_PATH);
    },
    addStoreEventListener(fName, fn){
        Dispatcher().getStore('CsSeekStore').addStoreEventListener(fName, fn);
    },
    populateAutoCompleteOptions(data){
        Dispatcher().dispatch(ON_SEEK_SEARCH_AUTOCOMPLETE_POPULATE, data);
    },
    doSearchById:function(data){
        Dispatcher().dispatch(ON_SEEK_SEARCH_BY_ID, data);
    }
}

export const ON_SEEK_SEARCH = 'seek-search';
export const ON_SEEK_SEARCH_BY_ID = 'seek-search-by-id';
export const ON_SEEK_LOAD = 'seek-load';
export const ON_SEEK_SEARCH_AUTOCOMPLETE_POPULATE = 'seek-search-autocomplete-populate';