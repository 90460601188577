import Store from "../helper/Store";
import {LOCAL_STORAGE_KEY_STORED_SEARCHES, LOCAL_STORAGE_KEY_RECENT_SEARCHES, ON_SELECT_STORED_FILTER_BY_PATH, ON_PERSIST_SAVED_SEARCH, ON_PERSIST_CURRENT_FILTERS, ON_REMOVE_PERSISTED_SEARCH, ON_REMOVE_CURRENT_FILTERS, ON_SELECT_STORED_FILTER_BY_CLICK, ON_OPEN_SEEK_VIEW, ON_OPEN_JORA_VIEW, ON_PLAY_VERSION_CHECKER, SET_APP_TITLE} from '../actions/MainViewAction'
import Dispatcher from "../helper/Dispatcher";
import httph from "../helper/HttpHelper";
import { UPDATE_CHECKER_API } from "../helper/Config";
import packJson from '../../package.json'

class MainStore extends Store{

    doOpenView(type, path){
        return path;
    }

    persistCurrentFilters(type, payload){
        let path = payload.path;
        let data = payload.data;
        let context = payload.key;
        let self = this;
        let filters = self.selectCurrentFilters(context);
        if(filters===null){
            filters = [];
        }
        data.path = path;
        data.context = context;
        data.result = null;
        data.id = new Date().getTime();
        filters.unshift(data);
        window.localStorage.setItem(context,JSON.stringify(filters));
    }

    addSavedFilter(item){
        let self = this;
        let filters = self.selectCurrentFilters(item.context);
        if(filters===null){
            filters = [];
        }
        item.result = null;
        item.id = new Date().getTime();
        filters.unshift(item);
        window.localStorage.setItem(item.context, JSON.stringify(filters));
    }

    doPersistCurrentFilters(type, payload){
        let path = payload.path;
        let data = payload.data;
        let context = payload.key;
        let self = this;
        let filters = self.selectCurrentFilters(context);
        if(typeof filters === 'undefined' || filters===null){
            filters = [];
        }
        data.path = path;
        data.context = context;
        data.result = null;
        data.autocompleteOptions = null;
        data.id = new Date().getTime();
        filters.unshift(data);
        if(filters.length>5){
            filters.splice(filters.length-1,1);
        }
        window.localStorage.setItem(context,JSON.stringify(filters));
        //self.invokeListeners(type, filters);
        return filters;
    }

    doRemoveCurrentFilters(type, payload){
        let id = payload.id, context = payload.context;
        let self = this;
        let dataList = self.selectCurrentFilters(context);
        if(dataList!=null){
            let index = 0;
            let item = {};
            for(let i=0; i<dataList.length;i++){
                if(dataList[i].id===id){
                    item = dataList[i];
                    index = i;
                    break;
                }
            }
            dataList.splice(index,1);
            window.localStorage.setItem(context,JSON.stringify(dataList));
            //self.invokeListeners(type, {dataList, removedItem:item}); 
            return {dataList, removedItem:item};
        }
        return null;
    }

    doRemovePersistedSearch(type, payload){
        let id = payload.id, context = payload.context;
        let self = this;
        let dataList = self.selectCurrentFilters(context);
        if(dataList!=null){
            let index = 0;
            
            for(let i=0; i<dataList.length;i++){
                if(dataList[i].id===id){
                    
                    index = i;
                    break;
                }
            }
            dataList.splice(index,1);
            window.localStorage.setItem(context,JSON.stringify(dataList));
            //self.invokeListeners(type, dataList);   
            return dataList;
        }
    }

    removePersistedSearch(type, payload){
        let id = payload.id, context = payload.context;
        let self = this;
        let dataList = self.selectCurrentFilters(context);
        if(dataList!=null){
            let index = 0;
            let item = {};
            for(let i=0; i<dataList.length;i++){
                if(dataList[i].id===id){
                    item = dataList[i];
                    index = i;
                    break;
                }
            }
            dataList.splice(index,1);
            window.localStorage.setItem(context,JSON.stringify(dataList));
            return item;
        }
        return null;
    }

    persistAsSavedSearch(type, payload){
        let self = this;
        payload.context = LOCAL_STORAGE_KEY_STORED_SEARCHES;
        self.addSavedFilter(payload);
        return self.selectCurrentFilters(LOCAL_STORAGE_KEY_STORED_SEARCHES);
    }


    selectCurrentFilters(type){
        try{
            let asString = window.localStorage.getItem(type);
            let returnValue = null;
            if(typeof asString === 'string' && asString.length>0){
                returnValue = JSON.parse(asString)
            }
            return returnValue;
        }catch(e){
            console.log(e);
            return null;
        }
    }

    doSelectCurrentFilters(type){
        return this.selectCurrentFilters(type);
        //this.invokeListeners(type,values);
    }

    populateStoredFilters(type){
        let values = this.selectCurrentFilters(type);
        this.invokeListeners(type,values);
    }



    selectStoredFilterByPath(type, path){
        let self = this;
        let pathItems = path.split('/');
        if(typeof pathItems!=='undefined' && pathItems!==null && pathItems.length>=4){
            let context = pathItems[2];
            let id = Number(pathItems[3]);
            let dataList = self.selectCurrentFilters(context);
            if(dataList!=null){
                for(let i=0; i<dataList.length;i++){
                    if(dataList[i].id===id){
                        return dataList[i];
                    }
                }
            }
        }
        return null;
        
    }

    playVersionChecker(type, payload){
        this.getVersion(type);
    }

    getVersion(type){
        const f = (type)=>{
            httph.get(UPDATE_CHECKER_API,(resp)=>{
                this.checkVersion(type, resp.data);
            },(error)=>{
                console.error(error);
            });
        }
        f(type);
        setInterval(()=>{f(type)},60000*10);
    }

    checkVersion(type, payload){
        const version = Number(packJson.version.replace('.',''));
        const retrievedVersion = Number(payload['latest-version'].replace('.',''));
        const pages = payload['latest-pages'];
        this.hasUpdate = (version<retrievedVersion);
        this.latestVersion = payload['latest-version'];
        if(!this.versionChecker){
            this.versionChecker = (type, hasUpdate, latestVersion, pages)=>{
                this.invokeListeners(type, {
                    hasUpdate:(hasUpdate && pages.filter((e)=>e===window.location.pathname).length>0),
                    latestVersion:latestVersion
                });
            }
            this.versionChecker(type, this.hasUpdate, this.latestVersion, pages);
            setInterval(()=>{this.versionChecker(type, this.hasUpdate, this.latestVersion, pages)},3000);
        }
    }
    
    reduce(type, payload){
        let state = null;
        switch(type){
            case ON_OPEN_SEEK_VIEW : state = this.doOpenView(type,payload); break;
            case ON_OPEN_JORA_VIEW : state = this.doOpenView(type,payload); break;
            case LOCAL_STORAGE_KEY_RECENT_SEARCHES : state = this.doSelectCurrentFilters(type);break;
            case LOCAL_STORAGE_KEY_STORED_SEARCHES : state = this.doSelectCurrentFilters(type);break;
            case ON_SELECT_STORED_FILTER_BY_PATH : state = this.selectStoredFilterByPath(type, payload); break;
            case ON_PERSIST_SAVED_SEARCH : state = this.persistAsSavedSearch(type, payload); break;
            case ON_PERSIST_CURRENT_FILTERS : state = this.doPersistCurrentFilters(type, payload); break;
            case ON_REMOVE_PERSISTED_SEARCH : state = this.doRemovePersistedSearch(type, payload); break;
            case ON_REMOVE_CURRENT_FILTERS : state = this.doRemoveCurrentFilters(type, payload); break;
            case SET_APP_TITLE : state = payload; break;
            case ON_PLAY_VERSION_CHECKER : state = undefined; this.playVersionChecker(type, payload); break;
            case ON_SELECT_STORED_FILTER_BY_CLICK : state = payload; break;//just to keep the pattern
            default: state = undefined;
        }
        if(typeof state !== 'undefined'){
            this.invokeListeners(type, state);
        }
        return state;
    }


}

export default new MainStore(Dispatcher());