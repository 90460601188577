import React from 'react';
import Box from './box/Box';

const SLACK_CHANNEL = 'https://seekchat.slack.com/archives/CC7H4Q06A';
const getPathName = () => window.location.pathname;

const RequestAccessInfo = () => (  
 <div className="request-access-info-wrapper">
     <Box title="Extra permission required">
        <div>It looks like you don't have the required level of access to access <b>{getPathName()}</b> inside Partner Portal.</div>
        <br/>
        <div>If you want to request the required access, please, copy the message below</div>
        <br/>
        <div>"Hi team, can I have the required level of access to <b>{getPathName()}</b> inside AIPS Partner Portal? Thanks in advance!"</div>
        <br/>
        <br/>
        <div>And click <a href={SLACK_CHANNEL}>here</a> to open the <a href={SLACK_CHANNEL}>#hey-dds</a> slack channel to paste it.</div>
     </Box>   
 </div>
);

export default RequestAccessInfo;
