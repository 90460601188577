//#TODO automate version and build.
const version = '1.3.57'
const build = '1'

//import the correct config file
export const config  = require(`./config.${process.env.REACT_APP_CURRENT_ENV}.json`);
const API_HOST  = config.http.xapi.host;
export const APP_VERSION = `v${version}${process.env.REACT_APP_CURRENT_ENV!=='production'?'-B'+build:''}`;
export const HTTP_REQUEST_TIMEOUT = config.http.timeout;
export const ADVERTISER_API_HOST = `${API_HOST}${config.http.xapi.endpoint.advertisers}`;
export const CATEGORY_API_HOST = `${API_HOST}${config.http.xapi.endpoint.categories}`;
export const EMPLOYERS_API_HOST = `${API_HOST}${config.http.xapi.endpoint.employers}`;
export const JOB_TITLE_API_HOST = `${API_HOST}${config.http.xapi.endpoint.titles}`;

// Configuration for the Location Suggest service
// https://gitlab.jobapi.io/knowledge-graph/location-suggest
export const LOCATION_SUGGEST_API_URL = `${API_HOST}${config.http.xapi.endpoint['kg-location-suggest']}`;

export const STATIC_SECURED_FILES = `${API_HOST}api/file/`;

export const RECOMMENDED_QUESTIONS_CLASSIFICATION_API = `${API_HOST}${config.http.xapi.endpoint['classification-api']}`;
export const RECOMMENDED_QUESTIONS_CLASSIFICATION_MY_API = `${API_HOST}${config.http.xapi.endpoint['classification-api-my']}`;
export const RECOMMENDED_QUESTIONS_ALGORITHM_API = `${API_HOST}${config.http.xapi.endpoint['algorithm-api']}`;

//AP runway api
export const RUNWAY_AP_API = `${API_HOST}${config.http.xapi.endpoint["runway-ap-api"]}`;
export const REALIZED_AD_API = `${API_HOST}${config.http.xapi.endpoint["realized-ad-api"]}`;
export const LOCATION_MAPPING_API = `${API_HOST}${config.http.xapi.endpoint["location-mapping-api"]}`;

export const AIPS_CQ_QUESTIONS_API = `${API_HOST}${config.http.xapi.endpoint['aips-cq-questions-api']}`;

export const JOB_API_HOST_JORA = `${API_HOST}${config.http.xapi.endpoint['job-api-jora']}`;
export const JOB_API_HOST_SEEK = `${API_HOST}${config.http.xapi.endpoint['job-api-seek']}`;
export const JOB_API_HOST_SEEK_BY_ID = `${API_HOST}${config.http.xapi.endpoint['job-api-seek-by-id']}`;
export const JOB_API_HOST_JORA_BY_ID = `${API_HOST}${config.http.xapi.endpoint['job-api-jora-by-id']}`;

export const EMAIL_CHECK_API = `${API_HOST}${config.http.xapi.endpoint['email-checker']}`;
export const UPDATE_CHECKER_API = `${API_HOST}${config.http.xapi.endpoint['update-checker']}`;

//location api to load geoid
export const GEOCODE_API_HOST = `${API_HOST}${config.http.xapi.endpoint['geo-id']}`;

//kg location api autosugestions
export const LOCATION_API_HOST = `${API_HOST}${config.http.xapi.endpoint['locations-suggest']}`;

export const JORA_API_LOCATION_SUGGESTION = `${API_HOST}${config.http.xapi.endpoint['jora-api']}/geocoder/suggest`;

export const SEEK_API_LOCATION_SUGGESTION = `${API_HOST}${config.http.xapi.endpoint['seek-locations-api']}/v3/suggest`;

//kg nerd api
export const NERD_API_MATCHING = `${API_HOST}${config.http.xapi.endpoint['nerd-api']}/roletitles/matching`;
export const NERD_API_BATCH = `${API_HOST}${config.http.xapi.endpoint['nerd-api']}/batch`;

export const FETCH_MENU_API_HOST = `${API_HOST}api/pp-menuitems`;
export const UPDATE_USER_PROFILE_API_HOST = `${API_HOST}api/user`;
//api mapping to get seek id based on geonameid
export const LOCATION_SEEK_BY_GEONAME_API_HOST = `${API_HOST}api/location-geoname`;
export const LOCATION_SEEK_BY_GLOBAL_API_HOST = `${API_HOST}api/location-global`;

export const OKTA_CLIENT_ID = config.security.okta.clientId;
export const OKTA_REDIRECT_URL = config.security.okta.redirectUrl;
