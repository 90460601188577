/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Input.css'
import InputDateSince from './InputDateSince';
import InputEvent from './InputEvent';
import InputPaginator from './InputPaginator';
import InputAutoComplete from '../input-autocomplete/InputAutoComplete';
import ViewUtils from '../../helper/ViewUtils';
import $ from 'jquery'
import { JORA_API_LOCATION_SUGGESTION, SEEK_API_LOCATION_SUGGESTION } from '../../helper/Config';

class Input extends Component{
   
    
    selectBetweenChange(e){
        if(typeof this.input.value !== 'undefined' && typeof this.secondInput.value !== 'undefined'){
            let event = new InputEvent(e, this.comp, this.input.value+'-'+this.secondInput.value);    
            this.props.onChange(event);
        }
    }

    bindSelectBetweenDefaultValue(){
        if(typeof this.props.defaultValue!=='undefined' && this.props.defaultValue!==null && this.props.defaultValue.length>0 && this.props.defaultValue.indexOf('-')!==-1){
            this.defaultValueSelect1 = this.props.defaultValue.split('-')[0];
            this.defaultValueSelect2 = this.props.defaultValue.split('-')[1];
        }else{
            this.defaultValueSelect1='';
            this.defaultValueSelect2='';
        }
        
    }

    bindSelectBetweenValue(){
        if(typeof this.props.value!=='undefined' && this.props.value!==null && this.props.value.length>0 && this.props.value.indexOf('-')!==-1){
            this.valueSelect1 = this.props.value.split('-')[0];
            this.valueSelect2 = this.props.value.split('-')[1];
        }else{
            this.valueSelect1='';
            this.valueSelect2='';
        }
        
    }

    getControledInputByType(type){
        const defaultUrl = this.props.site==='jora'?`${JORA_API_LOCATION_SUGGESTION}?country=AU&query=`:`${SEEK_API_LOCATION_SUGGESTION}?source=chalice&limit=20&country=AU&q=`;
        if(type==='password'){
            return (<input type="password" ref={(input)=>this.input = input} placeholder={this.props.placeHolder}  value={this.props.value} onChange={(e)=>this.props.onChange(new InputEvent(e))}></input>);    
        }else if(type==='text'){
            return (<input type="text" ref={(input)=>this.input = input} placeholder={this.props.placeHolder}  value={this.props.value} onChange={(e)=>this.props.onChange(new InputEvent(e))}></input>);    
        }else if(type==='select'){    
            return (<select value={this.props.value} ref={(input)=>this.input = input} onChange={(e)=>this.props.onChange(new InputEvent(e))}>
                {this.props.children}
            </select>);
        }else if(type==='select-between'){    
            this.bindSelectBetweenValue();
            return (<div className="select-between-wrap">
                <select value={this.valueSelect1} ref={(input)=>this.input = input} onChange={(e)=>this.selectBetweenChange(e)}>
                    {this.props.children}
                </select>
                <span>to</span>
                <select value={this.valueSelect2} ref={(input)=>this.secondInput = input} onChange={(e)=>this.selectBetweenChange(e)}>
                    {this.props.children}
                </select>
            </div>);
        }else if(type==='datesince'){    
            return (<InputDateSince ref={(input)=>this.input = input} value={this.props.value} onChange={(e)=>this.props.onChange(new InputEvent(e))}></InputDateSince> );
        }else if(type==='paginator'){
            return (<InputPaginator currentPage={this.props.paginatorCurrentPage} ref="paginator"
                itemsPerPage={this.props.paginatorItemsPerPage} itemsSize={this.props.paginatorItemsSize} onChange={(e)=>this.props.onChange(e)}/>);
        }else if(type==='auto-complete'){
            return (<InputAutoComplete value={this.props.value} placeholder={this.props.placeHolder} 
                    httpGetUrl={defaultUrl} onInputChange={(e, el)=>this.props.onChange(e, el)}
                 onSelectValue={(e, el)=>this.props.onChange(e, el)} onHttpError={(e)=>{console.error(e);this.props.onChange(e, {});}} ref={(input)=>this.input = input}/>);
        }else if(type==='auto-complete-client-side'){
            return (<InputAutoComplete value={this.props.value} placeholder={this.props.placeHolder} ref={(input)=>this.input = input} onInputChange={(e, el)=>this.props.onChange(e, el)} onSelectValue={(e, el)=>this.props.onChange(e, el)}>
                    {this.props.children}
                </InputAutoComplete>);    
        }else if(type==='checkbox'){
            return (<input type="checkbox" defaultChecked={this.props.defaultValue==='true'} ref={(input)=>this.input = input} defaultValue={this.props.defaultValue} onChange={(e)=>this.props.onChange(new InputEvent(e))}></input>);
        }else if(type==='template'){
            return this.props.children;
        }else{
            return '';
        }
    }

    getUncontroledInputByType(type){
        const defaultUrl = this.props.site==='jora'?`${JORA_API_LOCATION_SUGGESTION}?country=AU&query=`:`${SEEK_API_LOCATION_SUGGESTION}?source=chalice&limit=20&country=AU&q=`;
        if(type==='password'){
            return (<input type="password" ref={(input)=>this.input = input} placeholder={this.props.placeHolder} defaultValue={this.props.defaultValue} onChange={(e)=>this.props.onChange(new InputEvent(e))}></input>);
        }else if(type==='text'){
            return (<input type="text" ref={(input)=>this.input = input} placeholder={this.props.placeHolder} defaultValue={this.props.defaultValue} onChange={(e)=>this.props.onChange(new InputEvent(e))}></input>);
        }else if(type==='select'){    
            return (<select defaultValue={this.props.defaultValue} ref={(input)=>this.input = input} onChange={(e)=>this.props.onChange(new InputEvent(e))}>
                {this.props.children}
            </select>);
        }else if(type==='select-between'){    
            this.bindSelectBetweenDefaultValue();
            this.bindSelectBetweenValue();
            
            return (<div className="select-between-wrap">
                <select defaultValue={this.defaultValueSelect1} ref={(input)=>this.input = input} onChange={(e)=>this.selectBetweenChange(e)}>
                    {this.props.children}
                </select>
                <span>to</span>
                <select defaultValue={this.defaultValueSelect2} ref={(input)=>this.secondInput = input} onChange={(e)=>this.selectBetweenChange(e)}>
                    {this.props.children}
                </select>
            </div>);
        }else if(type==='datesince'){    
            return (<InputDateSince ref={(input)=>this.input = input} defaultValue={this.props.defaultValue} onChange={(e)=>this.props.onChange(new InputEvent(e))}></InputDateSince> );
        }else if(type==='paginator'){
            return (<InputPaginator defaultValue={this.props.defaultValue} currentPage={this.props.paginatorCurrentPage} ref="paginator"
                itemsPerPage={this.props.paginatorItemsPerPage} itemsSize={this.props.paginatorItemsSize} onChange={(e)=>this.props.onChange(e)}/>);
        }else if(type==='auto-complete'){
            return (<InputAutoComplete placeholder={this.props.placeHolder} httpGetUrl={defaultUrl}
                defaultValue={this.props.defaultValue} onSelectValue={(e, el)=>this.props.onChange(e, el)} onInputChange={(e, el)=>this.props.onChange(e, el)}
                onHttpError={(e)=>{console.error(e);this.props.onChange(e, {});}} ref={(input)=>this.input = input}/>);
        }else if(type==='auto-complete-client-side'){
            return (<InputAutoComplete defaultValue={this.props.defaultValue} placeholder={this.props.placeHolder} ref={(input)=>this.input = input} onInputChange={(e, el)=>this.props.onChange(e, el)} onSelectValue={(e, el)=>this.props.onChange(e, el)}>
                    {this.props.children}
                </InputAutoComplete>);    
        }else if(type==='checkbox'){
            return (<input type="checkbox" defaultChecked={this.props.defaultValue==='true'} ref={(input)=>this.input = input} defaultValue={this.props.defaultValue} onChange={(e)=>this.props.onChange(new InputEvent(e))}></input>);
        }else if(type==='template'){
            return this.props.children;
        }else{
            return '';
        }
    }
    
    focus(){
        if(ViewUtils.isDefined(this.input)){
            this.input.focus();
        }
    }

    componentDidUpdate(){//to fix the paginator problem
        if(this.props.type==='paginator'){
            this.refs.paginator.setState({
                itemsSize:this.props.paginatorItemsSize,
                itemsPerPage: this.props.paginatorItemsPerPage,
            });
        }
    }


    clean(){
        if(ViewUtils.isDefined(this.input)){
            if(this.props.type === 'auto-complete-client-side' || this.props.type === 'auto-complete' || this.props.type === 'datesince'){
                this.input.clean();
            }else if(this.props.type === 'select'){
                $(this.input).find('option:eq(0)').prop('selected', true);
                this.props.onChange(new InputEvent({target:this.input}));
            }else if(this.props.type==="text"){
                this.input.value = '';
                this.props.onChange(new InputEvent({target:this.input}));
            }else if(this.props.type === 'select-between'){
                $(this.input).find('option:eq(0)').prop('selected', true);
                this.selectBetweenChange({target:this.input});
                $(this.secondInput).find('option:eq(0)').prop('selected', true);
                this.selectBetweenChange({target:this.secondInput});
            }
        }
    }

    setUrl(url){
        if(typeof this.input !== 'undefined' && typeof this.input.setUrl === 'function'){
            this.input.setUrl(url);
            console.log(url);
        }
    }

    render(){
        let input = !ViewUtils.isDefined(this.props.defaultValue) ? this.getControledInputByType(this.props.type): this.getUncontroledInputByType(this.props.type);
        let label = this.props.label!=null && this.props.type!=='checkbox'?<label>{this.props.label}</label>:'';
        let afterLabel = this.props.label!=null && this.props.type==='checkbox'?<label className="after-label">{this.props.label}</label>:'';
        let className = `input-wrap ${this.props.type}-wrap ${this.props.className}`;
        let style = {};
        let icon = '';
        if(this.props.icon){
            icon = <i className="material-icons input-info" onClick={(e)=>{if(ViewUtils.isFunction(this.props.onIconClick)) this.props.onIconClick(e);}} data-tip={this.props.iconTip}>{this.props.iconText}</i>
        }
       
        if(this.props.width!==null){
            style.width = this.props.width
        }
        return (<div className={className} ref={(comp)=>{this.comp = comp}} style={style}>
            {label}
            <div className="inputable-element-wrap">{input}{icon}</div>
            
            {afterLabel}
        </div>);
    }

    static propTypes = {
        type:PropTypes.string,
        className:PropTypes.string,
        defaultValue:PropTypes.string,
        placeHolder:PropTypes.string,
        label:PropTypes.string,
        onChange:PropTypes.func,
        width:PropTypes.string,
        paginatorCurrentPage:PropTypes.number,
        paginatorItemsPerPage:PropTypes.number,
        paginatorItemsSize:PropTypes.number,
        info:PropTypes.string,
        icon:PropTypes.bool,
        itemsTotal:PropTypes.number,
        value:PropTypes.string,
        openPopupSearch:PropTypes.object,
        onIconClick:PropTypes.func,
        iconTip:PropTypes.string,
        iconText:PropTypes.string,
        site:PropTypes.string
   }

   static defaultProps = {
        type:'text',
        className:'',
        label:null,
        placeHolder:null,
        width:null,
        paginatorItemsPerPage:100,
        paginatorCurrentPage:1,
        icon:false,
        site:'jora'
    }
}
export default Input;