import React, { Component,Suspense } from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import './primeflex.css'
import CsJoraSearch from './containers/cs/jora/CsJoraSearch';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import MainView from './containers/MainView';
import AuthAction, { LOG_OUT, AUTHORIZE } from './actions/AuthAction';
import { APP_VERSION, EMAIL_CHECK_API, STATIC_SECURED_FILES } from './helper/Config';
import CsSeekSearch from './containers/cs/seek/CsSeekSearch';
import RequestAccessInfo from './components/RequestAccessInfo'
import { LOCATIONS_DOCUMENTATION, NERD_DOCUMENTATION } from './actions/MainViewAction';
import RecommendedQuestions from './containers/RecommendedQuestions';
import {LoadingFrag} from './components/LoadingFrag'
//import Nerd from './containers/Nerd';
import WaitPage from './containers/WaitPage';
import MdOutput from './components/md-output/MdOutput';
import EmailNotVerified from './components/EmailNotVerified';
import { If } from './helper/react-inline-logic';
import BasicDataAction, { FETCH_APP_MENU } from './actions/BasicDataAction';
import zoid from 'zoid';
import TokenHelper from './helper/TokenHelper';
import { AddMessage } from './components/messages/messages';

const NerdDocu = () => (<MdOutput path={`${STATIC_SECURED_FILES}nerd-api.md`} title="NERD API Documentation"/>);
const LocationDocs = () => (<MdOutput path={`${STATIC_SECURED_FILES}location-suggest.md`} title="Location Suggest documentation"/>);

const dynamicPreBuiltRoutes = [
  {uri:"/seek-search", component: CsSeekSearch},
  {uri:"/jora-search", component: CsJoraSearch},
  {uri:"/rec-questions", component: RecommendedQuestions}
];

export const getMenuItemPath = (item) => {
  switch(item.type){
      case 'documentation': return DOCUMENTATION_PATH + item.uri;
      case 'xcomponent': return XCOMPONENTS_PATH + item.uri
      default: return item.uri
  }
}


class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      auth:false,
      userLogin:'',
      user:{},
      menuItems:[],
      xcomponents:new Map()
    };
    let self = this;

    let onAppListener = function(type, payload){

      if(type===LOG_OUT){
        self.setState({auth:false, type});
      }

      if(type===EMAIL_CHECK_API){
          self.setState({auth:false,type, emailNotVerifiedData:payload.data});
      }

      if(type===AUTHORIZE){
        if(payload.auth){
          self.setState({auth:true, user: {
            name: payload.name,
            picture:self.getPicture(payload),
            givenName: payload.givenName,
            familyName: payload.familyName,
            email: payload.email,
            locale: payload.locale
          }});
        }
      }
    };

    AuthAction.addStoreEventListener('onAppListener', onAppListener);
    BasicDataAction.addStoreEventListener('onAppBasicDataListener',(type, payload)=>{
      if(type===FETCH_APP_MENU && !payload.error){
        self.setState({menuItems:payload.data});
        BasicDataAction.updateUserProfile(this.state.user);
      }
    });


    AuthAction.authorize();
  }
  
  getPicture(payload) {
    return (payload.picture? payload.picture : `https://i0.wp.com/cdn.auth0.com/avatars/${payload.email.charAt(0)}${payload.email.charAt(1)}.png`);
  }

  render() {
    if(this.state.auth){


      return (
        <Router>
            <MainView version={APP_VERSION} user={this.state.user}>
              <Suspense fallback={<LoadingFrag/>}>
                <Route key={LOCATIONS_DOCUMENTATION} path={LOCATIONS_DOCUMENTATION} component={LocationDocs} />
              </Suspense>
              {makeDynamicRoutes(this.state.menuItems, this.state.xcomponents)}
              <Route key={NERD_DOCUMENTATION} path={NERD_DOCUMENTATION} component={NerdDocu} />
              <Route key="request-access-info" path="/request-access-info" component={RequestAccessInfo}/>
            </MainView>
      </Router>
      );
    }else{
      return (
        <Router>
        <Route key="out" path="/">
          <If condition={this.state.type===EMAIL_CHECK_API || this.state.type===LOG_OUT}>  
              <If condition={this.state.type===EMAIL_CHECK_API}>
                <EmailNotVerified data={this.state.emailNotVerifiedData}/>
              </If>
              <If condition={this.state.type===LOG_OUT}>
                <WaitPage></WaitPage>
              </If>
          </If>
        </Route>
        </Router> 
      );
    }

  }
}

export default App;

export const XCOMPONENTS_PATH = '/xc';
export const DOCUMENTATION_PATH = '/docs';

const makeDynamicRoutes = (items, xcomponents=Map(), values=[]) => {
  let i=0;
  // eslint-disable-next-line
  for(let item of items){
      if(item.items && item.items.length>0){
        // eslint-disable-next-line
        for(let subItem of item.items){
            if(subItem.locked){
                values.push(<Route key={`${item.name}-${i++}`} path={getMenuItemPath(subItem)} component={RequestAccessInfo}>
                </Route>);
                continue;
            }
         
            if (subItem.type === 'redirect') {
              values.push(
                <Route
                  key={`${item.name}-${i++}`}
                  path={getMenuItemPath(subItem)}
                  component={() => {
                    // https://stackoverflow.com/a/44563899
                    window.location.href = subItem.redirectUrl;
                    return null;
                  }}
                />
              );
            } else if(subItem.type!=='static' && subItem.type!=='documentation' && subItem.type !== 'pre-built'){
              if(!xcomponents.get(subItem.xcomponent.tag)){
                xcomponents.set(subItem.xcomponent.tag, createZoidComponent(subItem.xcomponent));
              } 
              let DXComp = ({ZoidComponent = xcomponents.get(subItem.xcomponent.tag)})=>(<ZoidComponent {...subItem.xcomponent.xprops} 
                onTokenRequested={TokenHelper.getCurrentToken} 
                onTokenExpired={AuthAction.authorizeAsync}
                onMessage={AddMessage}/>);
              values.push(<Route key={`${item.name}-${i++}`} path={getMenuItemPath(subItem)} component={
                DXComp
              }/>
              );
            }else if(subItem.type==='documentation'){
              let DocuComponent = ()=>(<MdOutput path={`${STATIC_SECURED_FILES}${subItem.file}`} title={`${subItem.label} Documentation`}/>);
              values.push(<Route key={`${item.name}-${i++}`} path={getMenuItemPath(subItem)} component={
                DocuComponent
              }/>);
            }else if(subItem.type==='pre-built'){
                const instance = dynamicPreBuiltRoutes.filter(pb=>pb.uri===subItem.uri)[0].component;
                values.push(<Route key={`${item.name}-${i++}`} path={getMenuItemPath(subItem)} component={instance}/>);
            }
            
          

    
        }

      }
    }
    return values;
  }
  
  const createZoidComponent = (xcomponent)=>{
    return zoid.create({
      tag: xcomponent.tag,
      url: xcomponent.url,
      dimensions: xcomponent.dimensions,
      autoResize: true,
    }).driver('react', {
      React, ReactDOM
    });
  }