import ViewUtils from "./ViewUtils";
import httph from './HttpHelper'
class JsonHelper{
    constructor(url, parserDefaults){
        if(typeof url==='string'){
            this.url = url;
            this.loaded = false;
            this.parserDefaults = parserDefaults;
        }else if(typeof url === 'object'){
            this.loaded=true;
            this.rows = url;
        }
        this.loading = false;
    }

    isLoaded(){
        return (typeof this.loaded!=='undefined' && this.loaded===true);
    }

    isLoading(){
        return (typeof this.loading!=='undefined' && this.loading===true);
    }
    

    load(onsuccess, onerror){
        httph.get(this.url,(response)=>{
            this.loading = false;
            this.rows = response.data;
            this.loaded = true;
            onsuccess(this);
        },
        (error)=>{
            this.loading = false;
            if(ViewUtils.isFunction(onerror)){
                onerror(error, 'error');
            }
        }
        );
    }

    getRows(){
        return this.rows;
    }

    selectAll(){
        this.filteredRows = Object.assign(this.rows);
        return this;
    }

    first(){
        return this.size()>0?this.filteredRows[0]:null;
    }

    val(){
        return this.filteredRows;
    }

    size(){
        return ViewUtils.isDefined(this.filteredRows)? this.filteredRows.length:0;
    }

    orderBy(prop){
        if(this.size()>0){
            this.filteredRows.sort(function(a,b){
                if(a[prop]<b[prop]) return -1;
                if(a[prop]>b[prop]) return 1;
                return 0;
            });
        }
        return this;
    }

    orderByDesc(prop){
        if(this.size()>0){
            this.filteredRows.sort(function(a,b){
                if(a[prop]<b[prop]) return 1;
                if(a[prop]>b[prop]) return -1;
                return 0;
            });
        }
        return this;
    }


    where(func){
        let tempRows = [];
        let filteredIndex = 0;    
       for(let i=0; i<this.filteredRows.length; i++){
            if(!ViewUtils.isFunction(func) || func(this.filteredRows[i])){
                this.filteredRows[i].currentSearchIndex = filteredIndex++;
                tempRows.push(this.filteredRows[i]);
            }
        }
       this.filteredRows = tempRows;
       return this;
    }

}
export default JsonHelper;