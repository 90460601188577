import Dispatcher from "../helper/Dispatcher";

export default {
    doSearch : function(payload){
        Dispatcher().dispatch(SEARCH_QUESTIONS, payload);
    },
    addStoreEventListener(fName, fn){
        Dispatcher().getStore('RecommendedQuestionsStore').addStoreEventListener(fName, fn);
    },

    fetchCountries(payload){
        Dispatcher().dispatch(REC_QUESTIONS_FETCH_COUNTRIES, payload);
    },

    fetchClassificationsByCountry(payload){
        Dispatcher().dispatch(REC_QUESTIONS_FETCH_CLASSIFICATIONS, payload);
    },

    fetchSubClassificationsByClassification(payload){
        Dispatcher().dispatch(REC_QUESTIONS_FETCH_SUB_CLASSIFICATIONS, payload);
    },

    fetchAlg(payload){
        Dispatcher().dispatch(REC_QUESTIONS_FETCH_ALG, payload);
    }

}

export const SEARCH_QUESTIONS = 'recommended-questions-search-questions'
export const REC_QUESTIONS_FETCH_COUNTRIES = 'recommended-questions-fetch-countries'
export const REC_QUESTIONS_FETCH_ALG = 'recommended-questions-fetch-alg'
export const REC_QUESTIONS_FETCH_CLASSIFICATIONS = 'recommended-questions-fetch-classifications'
export const REC_QUESTIONS_FETCH_SUB_CLASSIFICATIONS = 'recommended-questions-fetch-sub-classifications'
