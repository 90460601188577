import React from 'react'
import {OverlayPanel} from 'primereact/components/overlaypanel/OverlayPanel';
import './UiUpdateInfo.css'
import $ from 'jquery'
import InfoBox from '../InfoBox';
import {If} from '../../helper/react-inline-logic'
const UiUpdateInfo = ({panel, show=false,latestVersion}) => (
    <If condition={show}>
        <div className="div-ui-update-info-wrap clickable" onClick={(e) => {panel.toggle(e); $($(e.target).hasClass('play-shake') ? $(e.target): $(e.target).find('.play-shake')).removeClass('play-shake')}}>
            <i className="fa fa-refresh play-shake"></i>
            <OverlayPanel ref={(el) => panel = el} appendTo={document.body}>
                There is one new version avaliable that could improve this page!<br/>
                Please, refresh this page to get it! You should see the version <b>v{latestVersion}</b>.
                <InfoBox icon="apple" text="Command + Shift + R on Mac to refresh the page."/>
                <InfoBox icon="windows" text="Ctrl + Shift + F5 on Windows to refresh the page."/>
                <InfoBox icon="linux" text="Ctrl + Shift + F5 usualy is the refresh short cut on linux distributions to refresh the page."/>
                * Sometimes it is necessary to reopen the page in a new window.
            </OverlayPanel>
        </div>
    </If>
);
export default UiUpdateInfo;