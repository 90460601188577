import { ON_SELECT_STORED_FILTER_BY_PATH, ON_PERSIST_CURRENT_FILTERS } from "./MainViewAction";
import Dispatcher from "../helper/Dispatcher";
export default {
    doSearch:function(data){
        Dispatcher().dispatch(ON_JORA_SEARCH, data);
    },
    persistCurrentFilters:function(path, data, key){
        Dispatcher().dispatch(ON_PERSIST_CURRENT_FILTERS, {path, data, key});
    },
    selectStoredFilterByPath:function(path){
        return Dispatcher().dispatch(ON_SELECT_STORED_FILTER_BY_PATH, path).get(ON_SELECT_STORED_FILTER_BY_PATH);
    },
    addStoreEventListener(fName, fn){
        Dispatcher().getStore('CsJoraStore').addStoreEventListener(fName, fn);
    },
    populateAutoCompleteOptions(data){
        Dispatcher().dispatch(ON_JORA_SEARCH_AUTOCOMPLETE_POPULATE, data);
    }
}

export const CS_JORA_STORE_KEY = 'CS_JORA_STORE_KEY';
export const ON_JORA_SEARCH = 'jora-search';
export const ON_JORA_LOAD = 'jora-load';
export const ON_JORA_SEARCH_AUTOCOMPLETE_POPULATE = 'jora-search-autocomplete-populate';