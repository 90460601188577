/**
 * Because there is a couple of fn's inside okta pack that aren't working as expected when we tried using it, 
 * we did create this js to encapsulate a couple of functionalities.
 */
import { OktaAuth } from '@okta/okta-auth-js';
import axios from 'axios';
import TokenHelper from './TokenHelper';
import { OKTA_CLIENT_ID, OKTA_REDIRECT_URL } from './Config';

const OKTA_AUTH_PARAMS = {
    issuer: 'https://seek.okta.com',
    clientId: OKTA_CLIENT_ID,
    redirectUri: OKTA_REDIRECT_URL,
    responseMode: 'query',
    responseType: 'code',
    scope: 'openid profile email',
    tokenManager: {
        storage: 'sessionStorage'
    }
}

const getStoredTokenParams = () => sessionStorage.getItem('okta-transaction-storage') ? JSON.parse(sessionStorage.getItem('okta-transaction-storage')) : null;

const bindTokenUrlParams = (tokenParams)  => {
    const urlParams = new URLSearchParams();
    urlParams.append('grant_type','authorization_code');
    urlParams.append('code',tokenParams.code);
    urlParams.append('client_id',OKTA_AUTH_PARAMS.clientId);
    urlParams.append('code_verifier', tokenParams.codeVerifier);
    urlParams.append('code_challenge', tokenParams.codeChallenge);
    urlParams.append('code_challenge_method', tokenParams.codeChallengeMethod);
    urlParams.append('redirect_uri', OKTA_AUTH_PARAMS.redirectUri);
    return urlParams;
}

const fetchToken = async ({tokenParams, httpClient}) => await httpClient.post(tokenParams.urls.tokenUrl, bindTokenUrlParams(tokenParams),
    {
        "content-type": "application/x-www-form-urlencoded"
    });

const redirectToLogin = async (authClient) => {
    const scopes = ['openid','profile','email'];  
    await authClient.token.getWithRedirect({scopes});
};

const codeFlow = async (authClient, httpClient) => {
    const tokenParams = getStoredTokenParams();
    const responseUrlParams = new URLSearchParams(window.location.search);
    tokenParams.code = responseUrlParams.get('code');
    const fetchTokenResponse = await fetchToken({tokenParams, httpClient});
    if(fetchTokenResponse.status === 200) {
        window.history.pushState("object or string", "AIPS - Partner Portal", "/");
    }else throw new Error("Impossible to run the code flow");
};

const authenticated = async (authClient) => {
    const tokenResponse = await authClient.token.getWithoutPrompt({scopes : ['openid','profile','email']});
    TokenHelper.storeCurrentToken(tokenResponse.tokens.accessToken.accessToken);
    const userInfo = await authClient.token.getUserInfo(tokenResponse.tokens.accessToken, tokenResponse.tokens.idToken);
    return {auth:true, email: userInfo.email, name: userInfo.name, givenName: userInfo.given_name, familyName: userInfo.family_name, locale: userInfo.locale};
};

export const authorize = async (authClient = new OktaAuth(OKTA_AUTH_PARAMS), urlSearch = window.location.search, httpClient = axios) => {
    try{
        if(authClient.isAuthorizationCodeFlow() && urlSearch.indexOf("?code")===0){
            await codeFlow(authClient, httpClient);
        }
        if(authClient.isAuthenticated()){
            return await authenticated(authClient);
        }else {
            redirectToLogin(authClient);   
        }
    }catch(e){
        redirectToLogin(authClient);
    }
    return {auth : false};
};

export const logout = async (authClient = new OktaAuth(OKTA_AUTH_PARAMS), logoutUrl = "https://seek.okta.com/login/signout") => {
    await authClient.tokenManager.clear();
    TokenHelper.deleteCurrentToken();
    window.location.href = logoutUrl;
};