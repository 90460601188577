import Store from "../helper/Store";
import Dispatcher from "../helper/Dispatcher";
import { RECOMMENDED_QUESTIONS_CLASSIFICATION_API, RECOMMENDED_QUESTIONS_CLASSIFICATION_MY_API, AIPS_CQ_QUESTIONS_API, RECOMMENDED_QUESTIONS_ALGORITHM_API } from "../helper/Config";
import { SEARCH_QUESTIONS, REC_QUESTIONS_FETCH_CLASSIFICATIONS, REC_QUESTIONS_FETCH_SUB_CLASSIFICATIONS, REC_QUESTIONS_FETCH_ALG } from "../actions/RecommendedQuestionsAction";
import JsonHelper from "../helper/JsonHelper";
import httph from "../helper/HttpHelper";

class RecommendedQuestionsStore extends Store{

    reduce(type, payload){
        switch(type){
            case SEARCH_QUESTIONS : this.searchQuestions(type,payload); break;
            case REC_QUESTIONS_FETCH_CLASSIFICATIONS : this.fetchClassifications(type,payload); break;
            case REC_QUESTIONS_FETCH_SUB_CLASSIFICATIONS : this.fetchSubClassifications(type,payload); break;
            case REC_QUESTIONS_FETCH_ALG : this.fetchAlgorithm(type, payload); break;
            default: break;
        }
    }

    fetchSubClassifications(type, payload){
        let country = payload.country;
        let classificationId = payload.classificationId;
        this.invokeListeners(type, this.formatSubClassByCountry(classificationId,country));
        
    }

    fetchAlgorithm(type, payload){
        this.algorithims = this.algorithims || new JsonHelper(RECOMMENDED_QUESTIONS_ALGORITHM_API);    
        if(!this.algorithims.isLoaded()){
            this.algorithims.load((instance)=>{
                this.algorithims = instance;
                this.invokeListeners(type, {val:this.algorithims.selectAll().where(payload.func).val(),ref:payload.ref});
            });
        }else{
            this.invokeListeners(type, {val:this.algorithims.selectAll().where(payload.func).val(),ref:payload.ref});
        }
    }
    
    formatSubClassByCountry(classificationId, country){
        let classifications = this[`classifications${country}`];
        let filtered = [];
        let returnList = [];
        if(country==='au' || country==='nz'){
            filtered = classifications.selectAll().where((e)=>Number(e.classificationId)===Number(classificationId)).val();
            if(filtered){
                // eslint-disable-next-line
                for(let item of filtered){
                    returnList.push({id:item.subClassficationId, name: item.subClassficationName});
                }
            }
        }else{
            filtered = classifications.selectAll().where((e)=>Number(e.code)===Number(classificationId)).first();
            if(filtered && filtered.children && Array.isArray(filtered.children.items)){
                // eslint-disable-next-line
                for(let item of filtered.children.items){
                    returnList.push({id:item.code, name: item.name});
                }
            }
        }
        return returnList;
    }

    fetchClassifications(type, payload){
        let country = payload.country;
        let classifications = this[`classifications${country}`];
        if(typeof classifications === 'undefined' || classifications===null){
            if(country==='au' || country==='nz'){
                this[`classifications${country}`] = classifications = new JsonHelper(`${RECOMMENDED_QUESTIONS_CLASSIFICATION_API}`);    
            }else{
                this[`classifications${country}`] = classifications = new JsonHelper(`${RECOMMENDED_QUESTIONS_CLASSIFICATION_MY_API}`);
            }
        }
        
        let result = null;
        let self = this;
        if(!classifications.isLoaded()){
            
            classifications.load(function(instance){
                self[`classifications${country}`] = classifications = instance;
                result = classifications.selectAll().where(payload.func).val(); 
                let payloadReturn = result;
                self.invokeListeners(type, self.formatByCountry(payloadReturn,country));
            });
        }else{
            result = classifications.selectAll().where(payload.func).val();
            let payloadReturn = result;
            self.invokeListeners(type, self.formatByCountry(payloadReturn,country));
        }
        return result;
    }

    formatByCountry(classifications, country){
        let filtered = [];
        if(country==='au' || country==='nz'){
            // eslint-disable-next-line
            for(let item of classifications){
                if(filtered.map(function(e) { return e.id; }).indexOf(item.classificationId)===-1){
                    filtered.push({id : item.classificationId,
                    name : item.classficationName});
                }
            }
        }else{
            // eslint-disable-next-line
            for(let item of classifications){
                filtered.push({id : item.code,
                    name: item.name});
            }
        }
        return filtered;
    }

    searchQuestionsPayload(payload){
        return {
            "advertiserId": payload.advertiserId,
            "classifications": [
              {
                "primary": {
                  "id": payload.classificationId
                },
                "secondary": {
                  "id": payload.subClassificationId
                }
              }
            ],
            "draftJobId": "",
            "jobText": payload.jobText,
            "jobTitle": payload.jobTitle?payload.jobTitle:'',
            "locations": [
              {
                "areaId": "string",
                "countryId": payload.country,
                "languageId": "3",
                "locationId": "string",
                "partnerId": "string"
              }
            ],
            "workTypeId": payload.workType
          };
    }

    searchQuestions(type, payload){
        let data = this.searchQuestionsPayload(payload);
        httph.post(AIPS_CQ_QUESTIONS_API, data,
            (res)=>this.invokeListeners(type, res.data),
            (error)=>this.invokeListeners(type, {hasError:true, error}));
    }
}

export default new RecommendedQuestionsStore(Dispatcher());

