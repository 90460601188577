import React from 'react';
import ReactDOM from 'react-dom';
import serviceWorkerCleanup from './serviceWorkerCleanup';
import './App.css';
import 'font-awesome/css/font-awesome.min.css';
import './components/messages/messages.css'
import Messages from './components/messages/messages.js'
import Dispatcher from './helper/Dispatcher'
import CsJoraStore from './stores/CsJoraStore';
import './helper/Prototype'
import BasicDataStore from './stores/BasicDataStore';
import MainStore from './stores/MainStore'

//third part components and resources
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-colored/theme.css';
import 'highlight.js/styles/github.css';
import AuthStore from './stores/AuthStore';
import App from './App';
import './default.scss';
import CsSeekStore from './stores/CsSeekStore';
import RecommendedQuestionsStore from './stores/RecommendedQuestionsStore';
import AdPerformanceStore from './stores/AdPerformanceStore';


Dispatcher().registerStore('CsJoraStore', CsJoraStore);
Dispatcher().registerStore('MainStore', MainStore);
Dispatcher().registerStore('BasicDataStore', BasicDataStore);
Dispatcher().registerStore('AuthStore', AuthStore);
Dispatcher().registerStore('CsSeekStore', CsSeekStore);
Dispatcher().registerStore('RecommendedQuestionsStore', RecommendedQuestionsStore);
Dispatcher().registerStore('AdPerformanceStore', AdPerformanceStore);

window.Messages = Messages;

ReactDOM.render(<App/>, document.getElementById('root'));

serviceWorkerCleanup();
