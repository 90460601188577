import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Input.css'
import ViewUtils from '../../helper/ViewUtils';

class InputDateSince extends Component{
    constructor(props){
        super(props);
        this.state = {
            value: ViewUtils.isDefined(this.props.value)?this.props.value:this.props.defaultValue
        };
    }

    changeValue(e){
        let value = e.target.value;
        let currentValue = this.state.value;
        if(value===currentValue){
            value = 'all';
            e.target.value = 'all';
        }
        //if(e.target.value!=='since'){
        this.props.onChange(e);
        //}
        this.setState({value});
    }
    
    changeInputValue(e){
        let stringValue = e.target.value+'-00-00-00-000';
        let splitedValues = stringValue.split('-');
        let date = Date.UTC(splitedValues[0], (Number(splitedValues[1])-1), splitedValues[2], splitedValues[3], splitedValues[4], splitedValues[5], splitedValues[6]);
        let param = {
            target:{
                value:date+''
            }
        };
        this.props.onChange(param);
    }

    clean(){
        this.changeValue({target:{value:''}});
    }

    focus(){
        this.refs.firstBtn.focus();
    }

    getAsDate(value){
        try{
            if(value!==''){
                let num = Number(value.replace('es',''));
                let date = new Date(num);
                if(date.getTime()===num){
                    let month = date.getMonth();
                    month = month++;
                    month = month<10?'0'+month:month;
                    let day = date.getDay();
                    day = day++;
                    day = day<10?'0'+day:day;
                    return `${date.getFullYear()}-${month}-${day}`;
                }
            }
            return null;
        }catch(e){
            return null;
        }
    }


    render(){
        if(ViewUtils.isDefined(this.props.value)){
            // eslint-disable-next-line
            this.state.value = this.props.value;
        }
        let hasDateValue = this.getAsDate(this.state.value);
        let inputDate = this.state.value==='since' || hasDateValue!==null?<input type="date" onChange={(e)=>this.changeInputValue(e)} defaultValue={hasDateValue}/>:'';
        return (
            <div className="data-since" ref={(rootElement)=>this.rootElement = rootElement}>
                <div className="choices">
                    <button type="button" ref="firstBtn" className={this.state.value==='24h'?'first selected':'first'} onClick={(e)=>{e.target.value='24h'; this.changeValue(e)}}>24h</button>
                    <button type="button" className={this.state.value==='3d'?'mid selected':'mid'} onClick={(e)=>{e.target.value='3d'; this.changeValue(e)}}>3d</button>
                    <button type="button" className={this.state.value==='7d'?'mid selected':'mid'} onClick={(e)=>{e.target.value='7d'; this.changeValue(e)}}>7d</button>
                    <button type="button" className={this.state.value==='14d'?'mid selected':'mid'} onClick={(e)=>{e.target.value='14d'; this.changeValue(e)}}>14d</button>
                    <button type="button" className={this.state.value==='30d'?'mid selected':'mid'} onClick={(e)=>{e.target.value='30d'; this.changeValue(e)}}>30d</button>
                    <button type="button" className={this.state.value==='since'|| hasDateValue!==null?'last selected':'last'} onClick={(e)=>{e.target.value='since'; this.changeValue(e)}}><i className="fa fa-calendar"/></button>
                </div>
                {inputDate}
            </div>
        );
    }

    static propTypes = {
        defaultValue:PropTypes.string,
        onChange:PropTypes.func,
        value:PropTypes.string
   }

}
export default InputDateSince;