import Store from "../helper/Store";
import Dispatcher from "../helper/Dispatcher";
import { JORA_CATEGORIES, SEEK_ADVERTISER, SEEK_CLASS_SUB_CLASS, SEEK_WORK_TYPE, MAP_JORA_AUTOCOMPLETE_RESULTS, MAP_JORA_AUTOCOMPLETE_RESULTS_FILTERS, MAP_JORA_CATEGORIES_BY_IDS, JORA_WORK_TYPE, JORA_ADVERTISER, FIND_GEOCODE, MAP_SEEK_AUTOCOMPLETE_RESULTS_FILTERS, MAP_SEEK_AUTOCOMPLETE_RESULTS, SEEK_CATEGORIES, SEEK_EMPLOYES, MAP_SEEK_CATEGORIES_BY_IDS, SEEK_PARENT_CLASSIFICATIONS, JORA_PARENT_CLASSIFICATIONS, JORA_EMPLOYERS, JORA_JOB_TITLE, SEEK_JOB_TITLE, FETCH_APP_MENU, UPDATE_USER_PROFILE } from "../actions/BasicDataAction";
import ViewUtils from "../helper/ViewUtils";
import JsonHelper from "../helper/JsonHelper";
import { ADVERTISER_API_HOST, GEOCODE_API_HOST, EMPLOYERS_API_HOST, JOB_TITLE_API_HOST, STATIC_SECURED_FILES, FETCH_MENU_API_HOST, UPDATE_USER_PROFILE_API_HOST } from "../helper/Config";
import httph from "../helper/HttpHelper";

class BasicDataStore extends Store{
    reduce(type, payload){
        switch(type){
            case JORA_CATEGORIES : this.onJoraCategories(type,payload); break;
            case SEEK_EMPLOYES : this.onSeekEmployer(type,payload); break;
            case SEEK_CATEGORIES : this.onSeekCategories(type,payload); break;
            case JORA_WORK_TYPE : this.onJoraWorkType(type,payload); break;
            case SEEK_ADVERTISER : this.onSeekAdv(type,payload); break;
            case JORA_ADVERTISER : this.onJoraAdv(type,payload); break;
            case SEEK_CLASS_SUB_CLASS : this.onSeekClass(type,payload); break;
            case SEEK_WORK_TYPE : this.onSeekWorkType(type,payload); break;
            case MAP_JORA_AUTOCOMPLETE_RESULTS : this.mapJoraAutocompleteResult(type,payload); break;
            case MAP_JORA_AUTOCOMPLETE_RESULTS_FILTERS : this.mapJoraAutocompleteResultFilter(type,payload); break;
            case MAP_JORA_CATEGORIES_BY_IDS : this.mapJoraCategoriesByIds(type, payload);break;
            case MAP_SEEK_CATEGORIES_BY_IDS : this.mapSeekCategoriesByIds(type, payload);break;
            case MAP_SEEK_AUTOCOMPLETE_RESULTS_FILTERS : this.mapSeekAutocompleteResultFilter(type,payload); break;
            case MAP_SEEK_AUTOCOMPLETE_RESULTS : this.mapSeekAutocompleteResult(type,payload); break;
            case FIND_GEOCODE : this.findGeocode(type, payload);break;
            case SEEK_PARENT_CLASSIFICATIONS : this.onSeekClassifications(type, payload); break;
            case JORA_PARENT_CLASSIFICATIONS : this.onJoraClassifications(type, payload); break;
            case JORA_EMPLOYERS : this.onJoraEmployer(type, payload); break;
            case JORA_JOB_TITLE : this.onJoraJobTitle(type, payload); break;
            case SEEK_JOB_TITLE : this.onSeekJobTitle(type, payload); break;
            case FETCH_APP_MENU : this.fetchAppMenu(type, payload); break;
            case UPDATE_USER_PROFILE : this.updateUserProfile(type, payload); break;
            default: break;
        }
    }

    updateUserProfile(type, payload){
        httph.put(UPDATE_USER_PROFILE_API_HOST, payload, (res => {}), (error => console.error(error)));
    }

    onSeekClassifications(type, payload){
        let parentCats = [];
        let self = this;
        this.onSeekCategories(null, payload, (cats)=>{
            let filtered = cats.selectAll().where((e)=>e.parentId === null || Number(e.parentId) === 0).orderBy('label').val();
            // eslint-disable-next-line
            for(let item of filtered){
                parentCats.push(item);
            }
            self.invokeListeners(type, parentCats);
        });
    }

    onJoraClassifications(type, payload){
        let parentCats = [];
        let self = this;
        this.onJoraCategories(null, payload, (cats)=>{
            let filtered = cats.selectAll().where((e)=>e.parentId === null || Number(e.parentId) === 0).orderBy('label').val();
            // eslint-disable-next-line
            for(let item of filtered){
                parentCats.push(item);
            }
            self.invokeListeners(type, parentCats);
        });
    }


    mapJoraCategoriesByIds(type, payload){
        let jobList = payload.list;
        let country = payload.country;
        // eslint-disable-next-line
        for(let item of jobList){
            let listReady = this.onJoraCategories(null,{func:(e)=>{return (item.categoryIds.indexOf(Number(e.id))!==-1);}, country, jobId:item.id});
            item.categoryReady = listReady;
        }
        this.invokeListeners(type, jobList);
    }

    mapSeekCategoriesByIds(type, payload){
        let jobList = payload.list;
        let country = payload.country;
        // eslint-disable-next-line
        for(let item of jobList){
            let listReady = this.onSeekCategories(null,{func:(e)=>{return (item.categoryIds.indexOf(Number(e.id))!==-1);}, country, jobId:item.id});
            item.categoryReady = listReady;
        }
        this.invokeListeners(type, jobList);
    }

    mapJoraAutocompleteResult(type, payload){
        let category=false,workType=false,advertiser=false;
        const ready = function(){
            return category && workType && advertiser;
        }
        let self = this;
        let newPayload = {
            title:this.castToList('title', payload.autocompleteOptions),
            advertiser:this.castToList('advertiser', payload.autocompleteOptions),
            employer:this.castToList('employer', payload.autocompleteOptions),
            createdAt:this.castToList('createdAt', payload.autocompleteOptions),
            salaryMin:this.castToList('salaryMin', payload.autocompleteOptions, (element)=>{
                if(ViewUtils.isDefined(element)){
                    element.formattedValue = ViewUtils.currencyFormat(element.key);
                }
                return element;
            })
        };
        self.onJoraCategories(null, {country:payload.country.toUpperCase()},(joraCats)=>{
            newPayload.category = self.castToList('category', payload.autocompleteOptions, (element)=>{
                        let ret =  joraCats.selectAll().where((e)=>Number(e.id) === Number(element.key)).first();
                        //parent
                        if(ret!==null){
                            let parent = ret.parentId !== 0 ?
                                joraCats.selectAll().where((e)=>Number(e.id) === Number(ret.parentId)).first()
                                : null;
                            
                            let parentName = null;
                            if(parent!==null){
                                ret.parentName = parent.label;
                                parentName = parent.label;
                            }
    
                            let returnElement = {parentId:ret.parentId, key:ret.id, value:ret.label, size:ret.count, parentName};
                            return returnElement;
                        }
                        
                        element.size = element.value;
                        return element;
                    });
            category = true;
            newPayload.category.sort((a,b)=>b.size-a.size);
            if(ready()){
                self.invokeListeners(type, newPayload);
            }
        });
            self.onJoraWorkType(null, null, (wtList)=>{
                newPayload.workType = self.castToList('workType', payload.autocompleteOptions, (wte)=>{
                        let ret =  self.joraWorkType.selectAll().where((e)=>Number(e.id) === Number(wte.key)).val()[0];
                        if(typeof ret!== 'undefined' && ret!==null){
                            return {key:ret.id, value:ret.name, size:wte.value};
                        }
                        wte.size = wte.value;
                        return wte;
                });
                workType = true;
                if(ready()){
                    self.invokeListeners(type, newPayload);
                }
            });

            self.onJoraAdv(null, {country:payload.country.toUpperCase()}, (advList)=>{
                newPayload.advertiser = this.castToList('advertiser', payload.autocompleteOptions, (element)=>{
                        let ret =  advList.selectAll().where((e)=>Number(e.id) === Number(element.key)).first();
                        if(ret!=null){
                            return {key:ret.id, value:ret.label, size: ret.count};
                        }
                        element.size = element.value;
                        return element;
                });
                advertiser = true;
                if(ready()){
                    self.invokeListeners(type, newPayload);
                }
            });
        
    }

    mapSeekAutocompleteResult(type, payload){
        let category=false,workType=false,advertiser=false;
            //employer=false;
        const ready = function(){
            return category && workType && advertiser; //&& employer;
        }
        let self = this;
        let newPayload = {
            title:this.castToList('title', payload.autocompleteOptions),
            createdAt:this.castToList('createdAt', payload.autocompleteOptions),
            salaryMin:this.castToList('salaryMin', payload.autocompleteOptions, (element)=>{
                if(ViewUtils.isDefined(element)){
                    element.formattedValue = ViewUtils.currencyFormat(element.key);
                }
                return element;
            })};


        this.onSeekCategories(null, payload,(catsList)=>{
            if(typeof catsList.error === 'undefined'){
                newPayload.category = self.castToList('category', payload.autocompleteOptions, (element)=>{
                        let ret =  catsList.selectAll().where((e)=>Number(e.id) === Number(element.key)).first();
                        if(ret!=null){
                            //parent
                            let parent = ret.parentId !== 0 ?
                                catsList.selectAll().where((e)=>Number(e.id) === Number(ret.parentId)).first()
                                : null;
                            let parentName = null;
                            if(parent!==null){
                                ret.parentName = parent.label;
                                parentName = parent.label;
                            }
                            let returnElement = {parentId:ret.parentId, key:ret.id, value:ret.label, size:ret.count, parentName};
                            
                            return returnElement;
                        }
                        element.size = element.value;
                        return element;
                            
                    });
            }
            category = true;
            if(ready()){
                this.invokeListeners(type, newPayload);
            }
        });
        self.onSeekWorkType(null, null, (wtList)=>{
            newPayload.workType = self.castToList('workType', payload.autocompleteOptions, (wte)=>{
                    let ret =  wtList.selectAll().where((e)=>e.id === wte.key).first();
                    if(typeof ret!== 'undefined' && ret!==null){
                        return {key:ret.id, value:ret.name, size:wte.value};
                    }
                    wte.size = wte.value;
                    return wte;
            });
            workType = true;
            if(ready()){
                this.invokeListeners(type, newPayload);
            }
        });

        this.onSeekAdv(null, {country:payload.country.toUpperCase()}, (advList)=>{
            if(typeof advList.error === 'undefined'){
                newPayload.advertiser = this.castToList('advertiser', payload.autocompleteOptions, (element)=>{
                        let ret =  advList.selectAll().where((e)=>Number(e.id) === Number(element.key)).first();
                        if(ret!=null){
                            return {key:ret.id, value:ret.label, size: ret.count};
                        }
                        element.size = element.value;
                        return element;
                });

            }
            advertiser = true;
            if(ready()){
                this.invokeListeners(type, newPayload);
            }
        });

        /*self.onSeekEmployer(null, {country:payload.country.toUpperCase()}, (emplList)=>{
            newPayload.employer = this.castToList('employer', payload.autocompleteOptions, (element)=>{
                    let ret =  emplList.selectAll().where((e)=>Number(e.id) === Number(element.key)).first();
                    if(ret!=null){
                        return {key:ret.id, value:ret.name, size: element.value};
                    }
                    element.size = element.value;
                    return element;
            });
            employer = true;
            if(ready()){
                this.invokeListeners(type, newPayload);
            }
        });*/
        
    }

    mapSeekAutocompleteResultFilter(type, payload){
        this.mapSeekAutocompleteResult(type, {autocompleteOptions:payload.list, country: payload.country});
    }

    mapJoraAutocompleteResultFilter(type, payload){
        this.mapJoraAutocompleteResult(type, {autocompleteOptions:payload.list, country: payload.country});
    }

    castToList(facetName, facetList, elementWillPopulate){
        let returnComponent = [];
        let currentFacet = null;
        try{
            currentFacet = facetList[facetName];
        }catch(e){
            currentFacet = null;
        }
        if(currentFacet!=null){
            
            let populateFunction=null;
            if(ViewUtils.isFunction(elementWillPopulate)){
                populateFunction = elementWillPopulate;
            }else{
                populateFunction = function(element){
                    return element;
                };
            }
           
            currentFacet.forEach((element)=>{
                let currentElement;
                if("advertiser category".indexOf(facetName)){
                    currentElement = {key:element.id, value:element.count || element.id};
                }else{
                    currentElement = {key:element.id, value:element.label || element.id};
                }
                currentElement = populateFunction(currentElement);
                returnComponent.push(currentElement);
            });
        }
        return returnComponent;
    }
    
    onJoraCategories(type, payload, oncomplete){
        let country = payload.country.toLowerCase();

        let joraCats = this[`joraCats${country}`];
        if(typeof joraCats === 'undefined' || joraCats===null){
            this[`joraCats${country}`] = joraCats = new JsonHelper(`${STATIC_SECURED_FILES}jora-classifications.json`);
        }
        
        let result = null;
        let self = this;
        if(!joraCats.isLoaded()){
            
            joraCats.load(function(instance){
                self[`joraCats${country}`] = joraCats = instance;
                result = joraCats.selectAll().where(payload.func).val(); 
                if(ViewUtils.isFunction(oncomplete)){
                    oncomplete(joraCats);
                }
                let payloadReturn = result;
                if(ViewUtils.isDefined(payload.jobId)){
                    payloadReturn = {jobId:payload.jobId,categories:result};
                }
                self.invokeListeners(type, payloadReturn);
                
            });
            return result;
        }else{
            
            result = joraCats.selectAll().where(payload.func).val();
            if(ViewUtils.isFunction(oncomplete)){
                oncomplete(joraCats);
            } 
            let payloadReturn = result;
            if(ViewUtils.isDefined(payload.jobId)){
                payloadReturn = {jobId:payload.jobId,categories:result};
            }
            self.invokeListeners(type, payloadReturn);
        }
        return result;
    }

    onSeekCategories(type, payload, oncomplete){
        const country = payload.country.toUpperCase();
        let seekCats = this[`seekCats${country}`];
        if(typeof seekCats === 'undefined' || seekCats===null){
            //cast the static classifications to a older format to be compatible for while with the current implementation
            this[`seekCats${country}`] = seekCats = new JsonHelper(`${STATIC_SECURED_FILES}seek-classifications.json`);
        }
        
        let result = null;
        if(!seekCats.isLoaded()){
            let self = this;
            seekCats.load(function(instance){
                self[`seekCats${country}`] = seekCats = instance;
                result = seekCats.selectAll().where(payload.func).val(); 
                if(ViewUtils.isFunction(oncomplete)){
                    oncomplete(seekCats);
                }
                
                self.invokeListeners(type, result);
            }, (error, method)=>{
                let pl = {error, method};
                if(ViewUtils.isFunction(oncomplete)){
                    oncomplete(pl);
                }
                self.invokeListeners(type, pl);
            });
            return result;
        }else{
            result = seekCats.selectAll().where(payload.func).val();
            if(ViewUtils.isFunction(oncomplete)){
                oncomplete(seekCats);
            } 
            this.invokeListeners(type, result);
        }
        return result;
    }

    onJoraWorkType(type, payload, oncomplete){
        if(typeof this.joraWorkType === 'undefined' || this.joraWorkType===null){
            this.joraWorkType = new JsonHelper(`${STATIC_SECURED_FILES}jora-worktype.json`, ['country:--']);
            //this.joraWorkType = new CsvHelper('JoraJobType.csv', ['country:--']);
        }
        
        let result = null;
        if(!this.joraWorkType.isLoaded()){
            let self = this;
            this.joraWorkType.load(function(instance){
                self.joraWorkType = instance;
                result = self.joraWorkType.selectAll().where(payload).val(); 
                if(ViewUtils.isFunction(oncomplete)){
                    oncomplete(result);
                }
                self.invokeListeners(type, result);
            });
            return result;
        }else{
            result = this.joraWorkType.selectAll().where(payload).val();
            if(ViewUtils.isFunction(oncomplete)){
                oncomplete(result);
            } 
            this.invokeListeners(type, result);
        }
        return result;
    }

    onSeekEmployer(type, payload, oncomplete){
        let seekEmpl = this[`seekEmpl${payload.country}`];
        if(typeof seekEmpl === 'undefined' || seekEmpl===null){
            this[`seekEmpl${payload.country}`] = seekEmpl = new JsonHelper(`${EMPLOYERS_API_HOST}?country=${payload.country.toUpperCase()}&origin=SEEK&language=en`);
        }
        
        let result = null;
        if(!seekEmpl.isLoaded()){
            let self = this;
            seekEmpl.load(function(instance){
                self[`seekEmpl${payload.country}`] = seekEmpl = instance;
                result = seekEmpl.selectAll().where(payload.func).val(); 
                if(ViewUtils.isFunction(oncomplete)){
                    oncomplete(seekEmpl);
                }
                self.invokeListeners(type, result);
            }, (error, method)=>{
                let pl = {error, method};
                self.invokeListeners(type, pl);
            });
            return result;
        }else{
            result = seekEmpl.selectAll().where(payload.func).val(); 
            if(ViewUtils.isFunction(oncomplete)){
                oncomplete(seekEmpl);
            }
            this.invokeListeners(type, result);
        }
        return result;
    }

    onSeekAdv(type, payload, oncomplete){
        let seekAdvs = this[`seekAdvs${payload.country}`];
        if(typeof seekAdvs === 'undefined' || seekAdvs===null){
            this[`seekAdvs${payload.country}`] = seekAdvs = new JsonHelper(`${ADVERTISER_API_HOST}?country=${payload.country.toUpperCase()}&origin=SEEK&language=en`);
        }
        
        let result = null;
        if(!seekAdvs.isLoaded()){
            let self = this;
            seekAdvs.load(function(instance){
                self[`seekAdvs${payload.country}`] = seekAdvs = instance;
                result = seekAdvs.selectAll().where(payload.func).val(); 
                if(ViewUtils.isFunction(oncomplete)){
                    oncomplete(seekAdvs);
                }
                self.invokeListeners(type, result);
            }, (error, method)=>{
                let pl = {error, method};
                if(ViewUtils.isFunction(oncomplete)){
                    oncomplete(pl);
                }
                self.invokeListeners(type, pl);
            });
            return result;
        }else{
            result = seekAdvs.selectAll().where(payload.func).val(); 
            if(ViewUtils.isFunction(oncomplete)){
                oncomplete(seekAdvs);
            }
            this.invokeListeners(type, result);
        }
        return result;
    }

    findGeocode(type, payload){
        let self = this;
        this.httpGet(`${GEOCODE_API_HOST}?location=${payload.location}&country=${payload.country.toLowerCase()}`,
            (data)=>{
                /**
                 * This code prevents the null case for the geocodeid when it is possible.
                 * 
                 * Siranon comment
                 * If searching by postcode the returned location_id is null,
                 * but the last parent node contains the relevant id (weird quirk or bug?)
                 * 
                 * 
                 * */    
                let returnedGocodeId = null;
                if(typeof data !== 'undefined'){
                    if(typeof data.location_id !== 'undefined'){ 
                        if(data.location_id!== null){
                            returnedGocodeId = data.location_id;
                        }else if(typeof data.location_parent_ids !== 'undefined' && data.location_parent_ids!=null && data.location_parent_ids.length>0){
                            returnedGocodeId = data.location_parent_ids[data.location_parent_ids.length-1];
                        }
                    }
                }
                self.invokeListeners(type, returnedGocodeId);
            });    
    }

    onJoraAdv(type, payload, oncomplete){
        let joraAdvs = this[`joraAdvs${payload.country}`];
        if(typeof joraAdvs === 'undefined' || joraAdvs===null){
            this[`joraAdvs${payload.country}`] = joraAdvs = new JsonHelper(`${ADVERTISER_API_HOST}?country=${payload.country}&origin=JORA&language=en`);
        }
        let result = null;
        if(!joraAdvs.isLoaded()){
            let self = this;
            joraAdvs.load(function(instance){
                self[`joraAdvs${payload.country}`] = joraAdvs = instance;
                result = joraAdvs.selectAll().where(payload.func).val(); 
                if(ViewUtils.isFunction(oncomplete)){
                    oncomplete(joraAdvs);
                }
                self.invokeListeners(type, result);
            });
            return;
        }else{
            result = joraAdvs.selectAll().where(payload.func).val(); 
            if(ViewUtils.isFunction(oncomplete)){
                oncomplete(joraAdvs);
            }
            this.invokeListeners(type, result);
        }
    }

    onJoraEmployer(type, payload, oncomplete){
        let joraEmpl = this[`joraEmpl${payload.country}`];
        if(typeof joraEmpl === 'undefined' || joraEmpl===null){
            this[`joraEmpl${payload.country}`] = joraEmpl = new JsonHelper(`${EMPLOYERS_API_HOST}?country=${payload.country}&origin=JORA&language=en`);
        }
        let result = null;
        if(!joraEmpl.isLoaded()){
            joraEmpl.load((instance)=>{
                this[`joraEmpl${payload.country}`] = joraEmpl = instance;
                result = joraEmpl.selectAll().where(payload.func).val(); 
                if(ViewUtils.isFunction(oncomplete)){
                    oncomplete(joraEmpl);
                }
                this.invokeListeners(type, result);
            });
            return;
        }else{
            result = joraEmpl.selectAll().where(payload.func).val(); 
            if(ViewUtils.isFunction(oncomplete)){
                oncomplete(joraEmpl);
            }
            this.invokeListeners(type, result);
        }
    }

    onJoraJobTitle(type, payload, oncomplete){
        let joraJobTitle = this[`joraJobTitle${payload.country}`];
        if(typeof joraJobTitle === 'undefined' || joraJobTitle===null){
            this[`joraJobTitle${payload.country}`] = joraJobTitle = new JsonHelper(`${JOB_TITLE_API_HOST}?country=${payload.country}&origin=JORA&language=en`);
        }
        let result = null;
        if(!joraJobTitle.isLoaded()){
            joraJobTitle.load((instance)=>{
                this[`joraJobTitle${payload.country}`] = joraJobTitle = instance;
                result = joraJobTitle.selectAll().where(payload.func).val(); 
                if(ViewUtils.isFunction(oncomplete)){
                    oncomplete(joraJobTitle);
                }
                this.invokeListeners(type, result);
            });
            return;
        }else{
            result = joraJobTitle.selectAll().where(payload.func).val(); 
            if(ViewUtils.isFunction(oncomplete)){
                oncomplete(joraJobTitle);
            }
            this.invokeListeners(type, result);
        }
    }

    onSeekJobTitle(type, payload, oncomplete){
        let seekJobTitle = this[`seekJobTitle${payload.country}`];
        if(typeof seekJobTitle === 'undefined' || seekJobTitle===null){
            this[`seekJobTitle${payload.country}`] = seekJobTitle = new JsonHelper(`${JOB_TITLE_API_HOST}?country=${payload.country}&origin=SEEK&language=en`);
        }
        let result = null;
        if(!seekJobTitle.isLoaded()){
            seekJobTitle.load((instance)=>{
                this[`seekJobTitle${payload.country}`] = seekJobTitle = instance;
                result = seekJobTitle.selectAll().where(payload.func).val(); 
                if(ViewUtils.isFunction(oncomplete)){
                    oncomplete(seekJobTitle);
                }
                this.invokeListeners(type, result);
            });
            return;
        }else{
            result = seekJobTitle.selectAll().where(payload.func).val(); 
            if(ViewUtils.isFunction(oncomplete)){
                oncomplete(seekJobTitle);
            }
            this.invokeListeners(type, result);
        }
    }


    onSeekClass(type, payload){
        
    }

    onSeekWorkType(type, payload, oncomplete){
        if(typeof this.seekWorkType === 'undefined' || this.seekWorkType===null){
            this.seekWorkType = new JsonHelper(`${STATIC_SECURED_FILES}seek-worktype.json`)
        }
        
        let result = null;
        if(!this.seekWorkType.isLoaded()){
            let self = this;
            this.seekWorkType.load(function(instance){
                self.seekWorkType = instance;
                result = self.seekWorkType.selectAll().where(payload).val(); 
                if(ViewUtils.isFunction(oncomplete)){
                    oncomplete(self.seekWorkType);
                }
                self.invokeListeners(type, result);
            });
            return result;
        }else{
            result = this.seekWorkType.selectAll().where(payload).val();
            if(ViewUtils.isFunction(oncomplete)){
                oncomplete(this.seekWorkType);
            } 
            this.invokeListeners(type, result);
        }
        return result;
    }

    fetchAppMenu(type, payload){
        httph.get(FETCH_MENU_API_HOST, (res)=>this.invokeListeners(type, {error:false, data:res.data}), (error)=>this.invokeListeners(type,{error}));
    }
}

export default new BasicDataStore(Dispatcher());