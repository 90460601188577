import React from 'react';

const InfoBox = ({text, icon = 'info', className})=>(
    <div className={`display-table full-size div-info-box-wrap ${className}`}>        
        <div className="display-cell icon">
            <i className={`fa fa-${icon}`}/>
        </div>
        <div className="display-cell text">
            {text}
        </div>
    </div>
);

export default InfoBox;