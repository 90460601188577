import PopupSearch from "./PopupSearch";
import BasicDataAction, { SEEK_ADVERTISER, JORA_ADVERTISER, JORA_COUNTRIES } from "../../actions/BasicDataAction";
import Input from '../input/Input';
import React from 'react';
import { If, For } from '../../helper/react-inline-logic';

class AdvertiserPopupSearch extends PopupSearch{
    
    constructor(props){
        super(props);
        this.type = AdvertiserPopupSearch;
        let self = this;
        let onAdvEventListener = function(type, payload){
            if(type===SEEK_ADVERTISER || type===JORA_ADVERTISER){
                self.onSearchComplete(payload);
            }
        };

        BasicDataAction.addStoreEventListener('onAdvEventListener', onAdvEventListener);
        //advertiserId,advertiserName,status,employerTypeDescription,companyTypeDescription
        this.state.data = {id:'',label:''};
        this.state.popupType = '';
    }

    getTableMetadata(){
        return [
            {field:'id', header:'Id', style:{width:'150px'}},
            {field:'label', header:'Name'}
        ];
    }

    onReset(e){
        let data = {id:'',label:'', popupType:this.popupType, country:this.country};
        this.setState({data});
    }

    renderFields(){
        let data = this.state.data;
        return (<div className="div-filters">
            <div className="div-filters-row">
                <Input onChange={(e)=>{data.id = e.value;this.setState({data});}} label="Id" ref="id" value={data.id}></Input>
                <Input onChange={(e)=>{data.label = e.value;this.setState({data});}} label="Name" ref="name" value={data.label}></Input>
            </div>
            <div className="div-filters-row">
                <Input type="select" ref="country" onChange={(e)=>{data.country = e.target.value;this.setState({data});}} 
                       value={data.country} label="Country">
                       <If condition={this.popupType===ADV_POPUP_SEEK}>
                        <option value="AU">AU</option>
                        <option value="NZ">NZ</option>
                       </If>
                       <If condition={this.popupType===ADV_POPUP_JORA}>
                        <For list={JORA_COUNTRIES} onLoop={(item=>(<option value={item.toLowerCase()}>{item.toUpperCase()}</option>))}/>
                       </If>
                </Input>
            </div>
        </div>);
    }

    show(){
        const [popupType, country] = arguments;
        this.popupType = popupType;
        this.country = country;
        this.state.data.popupType = popupType;
        this.state.data.country = country;
        super.show();
        let i = this;
        window.setTimeout(()=>{i.refs.id.focus()},300);
    }


    onSearch(e){
        let filter = this.state.data;
        if(filter.popupType===ADV_POPUP_JORA){
            BasicDataAction.findInJoraAdvertiser({country:filter.country, func:(e)=>
                (!filter.label.hasValue() || e.label.toLowerCase().indexOf(filter.label.toLowerCase())!==-1)
                && (!filter.id.toString().hasValue() || String(e.id).indexOf(filter.id.toString())!==-1)
            });
        }
        if(filter.popupType===ADV_POPUP_SEEK){
            BasicDataAction.findInSeekAdvertiser({country:filter.country, func:(e)=>
                (!filter.label.hasValue() || e.label.toLowerCase().indexOf(filter.label.toLowerCase())!==-1)
                && (!filter.id.toString().hasValue() || String(e.id).indexOf(filter.id.toString())!==-1)
            });
        }
    }

}

export default AdvertiserPopupSearch;
export const ADV_POPUP_JORA = 'adv-popup-jora';
export const ADV_POPUP_SEEK = 'adv-popup-seek';