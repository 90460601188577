class InputEvent{
    constructor(nativeEvent, element, value,data){
        this.nativeEvent = nativeEvent;
        this.element = element;
        this.value = value;
        this.data = data;
        
        if(typeof nativeEvent!== 'undefined'){
            this.target = this.nativeEvent.target;
            if(typeof element ==='undefined'){
                this.element = nativeEvent.target;
            }
            if(typeof value ==='undefined' && typeof nativeEvent.target!== 'undefined'){
                this.value = nativeEvent.target.value;
            }
        }
    }
}

export default InputEvent;