export const If = ({condition=true, children})=>(
    children = !condition?'':children 
);

export const For = ({list = [], onLoop = ()=>{}, onLoopComplete=()=>{}, onLoopBreakIf=()=>false, onLoopContinueIf=()=>false, r=[], index=0, hasLoop})=>{
    // eslint-disable-next-line
    for(let i of list){
        hasLoop=true;
        if(onLoopBreakIf(i, index)) break;
        if(onLoopContinueIf(i, index)) continue;
        r.push(onLoop(i, index));
        index++;
    }
    if(hasLoop){
        onLoopComplete(r,index);
    }
    return r;
};

export const While = ({condition=false, onLoop = ()=>{}, onLoopBreakIf=()=>false, onLoopContinueIf=()=>false, r=[], index=0})=>{
    while(condition){
        if(onLoopBreakIf(index)) break;
        if(onLoopContinueIf(index)){
            index++;
            continue;
        }
        r.push(onLoop(index));
        index++;
    }
    return r;
};

