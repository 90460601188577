import Store from '../helper/Store'
import Dispatcher from '../helper/Dispatcher';
import { LOG_OUT, AUTHORIZE, AUTHORIZE_ASYNC } from '../actions/AuthAction';
import {authorize, logout} from '../helper/OktaHelper'

class AuthStore extends Store{

    constructor(dispatcher){
        super(dispatcher);
    }

    async authorize(type,payload){
        const response = await authorize();
        this.invokeListeners(type, response);
    }

    async logout(type, payload){
        await logout();
        this.invokeListeners(type, {auth:false});
    }

    reduce(type, payload){
        switch(type){
            case LOG_OUT: this.logout(type, payload); break;
            case AUTHORIZE: this.authorize(type, payload); break;
            case AUTHORIZE_ASYNC: this.authorize(type, payload); break;
            default: return null;
        }
    }
}
export default new AuthStore(Dispatcher());