import React from 'react';
import PopupSearch from "./PopupSearch";
import BasicDataAction, { JORA_WORK_TYPE } from "../../actions/BasicDataAction";
import Input from '../input/Input';

class JoraWorkTypePopupSearch extends PopupSearch{
    
    constructor(props){
        super(props);
        this.type = JoraWorkTypePopupSearch;
        let self = this;
        let onJoraWorkTypeEventListener = function(type, payload){
            if(type===JORA_WORK_TYPE){
                self.onSearchComplete(payload);
            }
        };
        BasicDataAction.addStoreEventListener('onJoraWorkTypeEventListener', onJoraWorkTypeEventListener);
        //the state was defined on PopupSearch super class
        //id,name,seoName,i18nKey,language,country
        this.state.data = {id:'',name:'',seoName:'',country:''};
    }

    getTableMetadata(){
        return [
            {field:'id', header:'Id', style:{width:'50px'}},
            {field:'name', header:'Name'},
            {field:'seoName', header:'Seo Name'},
            {field:'country', header:'Country', style:{width:'70px'}}
        ];
    }

    onReset(e){
        let data = {id:'',name:'',seoName:'',country:''};
        this.setState({data});
    }

    renderFields(){
        let data = this.state.data;
        return (<div className="div-filters">
            <div className="div-filters-row">
                <Input onChange={(e)=>{data.id = e.value;this.setState({data});this.onSearch(e);}} label="Id" ref="id" value={data.id}></Input>
                <Input onChange={(e)=>{data.name = e.value;this.setState({data});this.onSearch(e);}} label="Name" ref="name" value={data.name}></Input>
            </div>
            <div className="div-filters-row">
                <Input onChange={(e)=>{data.seoName = e.value;this.setState({data});this.onSearch(e);}} label="Seo Name" ref="seoName" value={data.seoName}></Input>
                <Input onChange={(e)=>{data.country = e.value;this.setState({data});this.onSearch(e);}} label="Country" ref="country" value={data.country}></Input>
            </div>
        </div>);
    }


    onSearch(e){
        let filter = this.state.data;
        BasicDataAction.findInJoraWorkType((e)=> 
            (!filter.country.hasValue() || e.country===filter.country) 
            && (!filter.name.hasValue() || e.name.indexOf(filter.name)!==-1)
            && (!filter.seoName.hasValue() || e.seoName.indexOf(filter.seoName)!==-1)
            && (!filter.id.hasValue() || e.id.indexOf(filter.id)!==-1) 
        );
    }

    onShow(e){
        let i = this;
        window.setTimeout(()=>{i.refs.id.focus()},300);
    }           

}

export default JoraWorkTypePopupSearch;