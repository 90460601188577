import Dispatcher from "./Dispatcher";
import httph from "./HttpHelper";

class Store{
    
    constructor(dispatcher){
        if(typeof dispatcher === 'undefined' || Dispatcher() !== dispatcher){
            throw new DOMException('All stores must receive one dispatcher like constructor parameter.');
        }
    }

    encodeToUrlVal(val){
        return val.replace(/&/g, '%26');
    }

    invokeListeners(type, payload){
        if(type!=null && typeof this.storeEventListeners !== 'undefined'){
            // eslint-disable-next-line
            for(let key of this.storeEventListeners.keys()){
                this.storeEventListeners.get(key)(type, payload);
            }
        }
    }

    addStoreEventListener(fName, fn){
        if(typeof this.storeEventListeners === 'undefined'){
            //avoid dup.
            this.storeEventListeners = new Map();
        }
        this.storeEventListeners.set(fName, fn);
    }

    removeStoreEventListener(fName){
        this.storeEventListeners.delete(fName);
    }

    hasStoreEventListener(fName){
        return this.storeEventListeners && typeof this.storeEventListeners.get(fName) === 'function';
    }

    reduce(type, payload){
        console.log('reduce method not implemented on '+this.constructor.name);
    }


    httpGet(url, onsuccess, onerror){
        httph.get(url,
            (response)=> onsuccess(response.data) || function(){}, 
            (error)=> onerror(error) || function(){});
    }
}
export default Store;