import React,{Component} from 'react';
import SearchFilterPanel from '../../../components/search/SearchFilterPanel';
import Input from '../../../components/input/Input';
import CsSeekAction, { ON_SEEK_SEARCH_AUTOCOMPLETE_POPULATE, ON_SEEK_SEARCH, ON_SEEK_SEARCH_BY_ID } from '../../../actions/CsSeekAction'
import MainViewAction, { CS_SEEK_SEARCH, LOCAL_STORAGE_KEY_RECENT_SEARCHES, ON_SELECT_STORED_FILTER_BY_CLICK, ON_OPEN_SEEK_VIEW } from '../../../actions/MainViewAction';
import ViewUtils from '../../../helper/ViewUtils';
import PanelJobItem from '../../../components/panel-job-item/PanelJobItem';
import ChartBox from '../../../components/chart-box/ChartBox';
import $ from 'jquery'
import Box from '../../../components/box/Box';
import BasicDataAction, { FIND_GEOCODE, MAP_SEEK_AUTOCOMPLETE_RESULTS, MAP_SEEK_AUTOCOMPLETE_RESULTS_FILTERS, MAP_SEEK_CATEGORIES_BY_IDS, SEEK_ADVERTISER, SEEK_JOB_TITLE } from '../../../actions/BasicDataAction'
import JoraWorkTypePopupSearch from '../../../components/popup/JoraWorkTypePopupSearch'; 
import {ToggleButton} from 'primereact/components/togglebutton/ToggleButton';
import AdvertiserPopupSearch, { ADV_POPUP_SEEK } from '../../../components/popup/AdvertiserPopupSearch';
import { SEEK_API_LOCATION_SUGGESTION } from '../../../helper/Config';
import {SelectButton} from 'primereact/components/selectbutton/SelectButton';
import text from '../../../helper/text_en_au'
import CategoryPopupSearch, { CAT_POPUP_SEEK } from '../../../components/popup/CategoryPopupSearch';
import ReactTooltip from 'react-tooltip' 

class CsSeekSearch extends Component{

    constructor(props){
        super(props, ['CsJoraSearchStore']);
        this.state = {
            data: {
                country:'au',
                keyWords:'',
                locationId:'',
                pageSize:"20",
                sort:'score',
                dedup:'filter',
                allDetailsShowed:false
            },
            autocompleteAdvertisers: [],
            autocompleteJobTitle: [],
            autocomplete:{},
            filterAutocomplete:{},
            result:[],
            summaryVisible:false,
            clientSearchMessage:'',
            selectedCountry:'au'
        };
        let data = this.getDataObjectByUrl();
        this.state.data = this.getDataInstance(data);
        this.configureListeners();
        
    }
    
    isSearchById(){
        return this.state.data && this.state.data.jobId && this.state.data.jobId.length>0;
    }

    onStoreUpdated(type, payload, instance){
        if(typeof payload !== 'undefined' && payload!==null && payload.hasErrors===true){
            ViewUtils.addErrorMessage(payload.errorMessage);
            ViewUtils.hideWait();
            return;
        }
            
        switch(type){
            case ON_SEEK_SEARCH:{
                ViewUtils.hideWait();
                if(payload.result.jobs.length===0 ){   
                    ViewUtils.addInfoMessage(text.MSG_NO_JOBS_FOUND);
                }else{
                    instance.setState({result:payload.result});
                    if(instance.isSearchById()){
                        ViewUtils.addInfoMessage(text.MSG_JOB_FOUND);
                    }else{
                        payload.result = null;
                        instance.setState({data:payload,clientSearchMessage:'', selectedCountry:this.state.data.country});
                        BasicDataAction.mapSeekAutoCompleteResultsFilters({list:instance.state.result.facets, country:instance.state.data.country});
                        ViewUtils.addInfoMessage(text.MSG_JOBS_FOUND(instance.state.result.page.resultCount, instance.state.result.page.pageSize));
                        BasicDataAction.mapSeekCategoriesByIds({list:instance.state.result.jobs, country:instance.state.data.country});
                    }
                }
                break;
            }
            case ON_SEEK_SEARCH_BY_ID:{
                ViewUtils.hideWait();
                if(payload.result.jobs.length===0 ){   
                    ViewUtils.addInfoMessage(text.MSG_NO_JOBS_FOUND);
                }else{
                    instance.setState({result:payload.result});
                    ViewUtils.addInfoMessage(text.MSG_JOB_FOUND);
                }
                break;
            }
            case ON_SEEK_SEARCH_AUTOCOMPLETE_POPULATE:{
                BasicDataAction.mapSeekAutoCompleteResults(payload);
                break;
            }
            case ON_SELECT_STORED_FILTER_BY_CLICK:{
                let data = instance.getDataObjectByUrl();
                data = instance.getDataInstance(data); 
                instance.setState({data});
                break;
            }
            case ON_OPEN_SEEK_VIEW:{
                let d = instance.getDataInstance(payload);
                instance.setState({data:d});
                break;
            }
            case MAP_SEEK_AUTOCOMPLETE_RESULTS:{
                if(typeof payload.error === 'undefined'){
                    instance.renderAutocompleteClientOptions(payload);
                }
                $('.lazy-load').removeClass('waiting');
                break;
            }
            case MAP_SEEK_AUTOCOMPLETE_RESULTS_FILTERS:{
                instance.populateFilterAutocompleteOptions(payload, instance);
                instance.populateSearchSummary(payload, instance); 
                break;
            }
            case FIND_GEOCODE:{
                let data = instance.state.data;
                data.locationId = payload;
                instance.setState({data});
                break;
            }
            case MAP_SEEK_CATEGORIES_BY_IDS:{
                let result = instance.state.result;
                result.jobs = payload;
                instance.setState({result});
                break;
            }
            case SEEK_ADVERTISER:{
                let autocompleteAdvertisers = instance.castToAutocompleteOption(payload, SEEK_ADVERTISER);
                instance.setState({autocompleteAdvertisers});
                break;
            }
            case SEEK_JOB_TITLE:{
                let autocompleteJobTitle = instance.castToAutocompleteOption(payload, SEEK_JOB_TITLE);
                instance.setState({autocompleteJobTitle});
                break;
            }
            default:{}
        }
    }
    
    getDataObjectByUrl(){
        let data = null;
        data = ViewUtils.getObjectByPath(window.location.pathname,data, 
            ['pageSize:20','country:au', 'locationId:',
             'keyWords:','jobtitle','id', 'employer', 'salary', 'createdAt:',
             'updatedAt:', 'where', 'advertiser', 'category', 'dedup:filter','sort:score']);
        return data;
    }

    configureListeners(){
        let instance = this;
        let seekOnStoreUpdate = function(type, payload){
            instance.onStoreUpdated(type, payload, instance);
        }
        CsSeekAction.addStoreEventListener('seekOnStoreUpdate', seekOnStoreUpdate);
        MainViewAction.addStoreEventListener('seekOnStoreUpdate', seekOnStoreUpdate);
        BasicDataAction.addStoreEventListener('seekOnStoreUpdate', seekOnStoreUpdate);
    }
    
    autocompleteLoad(country){
        BasicDataAction.findInSeekAdvertiser({country});
        BasicDataAction.findInSeekJobTitle({country});
    }
    
    componentDidMount(){
        if(ViewUtils.isDefined(this.firstInput)){
            this.firstInput.focus();
        }
        $('.lazy-load').addClass('waiting');
        CsSeekAction.populateAutoCompleteOptions(this.state.data);
        this.autocompleteLoad(this.state.data.country);
    }


    renderAutocompleteClientOptions(payload){
        let jobtitle = this.getFacetsOptions('title',payload, true);
        let advertiser = this.getFacetsOptions('advertiser',payload);
        let category = this.getFacetsOptionsCategory(payload);
        let employer = this.getFacetsOptions('employer',payload);
        let workType = this.getFacetsOptionsFilter('workType',payload);
        
        let autocomplete = {
            jobtitle,advertiser,category,employer, workType
        };
        this.setState({autocomplete});
    }

    castToAutocompleteOption(list, type){
        let returnComponent = [];
        if(list!=null){
            switch(type){
                case SEEK_JOB_TITLE:{
                    // eslint-disable-next-line
                    for(let element of list) returnComponent.push(<div data-search-key={`${element.id}`} value={element.id} key={element.id} id={element.id}><span>{element.id}</span></div>);        
                    break;
                }
                default:{
                    // eslint-disable-next-line
                    for(let element of list) returnComponent.push(<div data-search-key={`${element.id},${element.label}`} value={element.label} key={element.id} id={element.id}><span>{element.id}</span><span>{element.label}</span></div>);        
                }
            }    
        }
        return returnComponent;
    }

    onPaginatorChange(inputEvent){
        let data = this.state.data;
        data.pageSize = inputEvent.data.itemsPerPage; 
        data.page= inputEvent.data.currentPage;
        ViewUtils.showWait();
        CsSeekAction.doSearch(data);
    }

    getDataInstance(payload, instance){
        let returnValue = null;
        if(payload===null || payload==='/seek-search'){
            this.onReset();
            returnValue = {
                country:'au',
                keyWords:'',
                locationId:'',
                pageSize:20,
                dedup:'filter'
            };
        }else{
            returnValue = payload;
        }
        return returnValue;
       
    }

    getFacetsOptions(facetName, facetList, printKeyAndValue){
        
        let returnComponent = [];
        let currentFacet = null;
        try{
            currentFacet = facetList[facetName];
        }catch(e){
            currentFacet = null;
        }
        if(currentFacet!=null){
            currentFacet.forEach(element=>{
                let val = null;
                if(printKeyAndValue===true){
                    val = <div data-search-key={`${element.key},${element.value}`} value={element.value} key={element.key} id={element.key}>{`${element.key} (${element.value})`}</div>;
                }else{
                    val = <div data-search-key={`${element.key},${element.value}`} value={element.value} key={element.key} id={element.key}><span>{element.key}</span><span>{element.value}</span></div>;
                }
                returnComponent.push(val);
            });
        }
        return returnComponent;
    }


    getFacetsOptionsFilterCategory(facetList){
        let facetName = 'category';
        let returnComponent = [];
        let currentFacet = null;
        try{
            currentFacet = facetList[facetName];
        }catch(e){
            currentFacet = null;
        }
        if(currentFacet!=null){
            currentFacet.forEach(element=>{
                let val =  `${element.value} ${element.parentName===null?' (Classification)':' in '+element.parentName}`;
                //let val =  `${element.value}`;
                returnComponent.push(<option data-search-key={`${element.key},${element.value}`} value={element.key} key={element.key}>{val}</option>);
            });
        }
        return returnComponent;
    }


    getFacetsOptionsCategory(facetList){
        let facetName = 'category';
        let returnComponent = [];
        let currentFacet = null;
        try{
            currentFacet = facetList[facetName];
        }catch(e){
            currentFacet = null;
        }
        if(currentFacet!=null){
            currentFacet.forEach(element=>{
                if(element.parentId && element.parentId!== null){
                    
                    let val = <div data-search-key={`${element.key},${element.value}`} value={element.value} key={element.key} id={element.key}>
                        <span>{element.key}</span><span>{element.value}<b> in </b>{element.parentName}</span>
                        
                        </div>;
                    
                    returnComponent.push(val);

                }else{
                    let val = <div data-search-key={`${element.key},${element.value}`} value={element.value} key={element.key} id={element.key}>
                        <span>{element.key}</span><span>{element.value} (Classification)</span>
                        
                        </div>;
                    
                    returnComponent.push(val);
                }
            });
        }
        return returnComponent;
    }



    getFacetsOptionsFilter(facetName, facetList, printKeyAndValue){
        
        let returnComponent = [];
        let currentFacet = null;
        try{
            currentFacet = facetList[facetName];
        }catch(e){
            currentFacet = null;
        }
        if(currentFacet!=null){
            currentFacet.forEach(element=>{
                let val = null;
                if(printKeyAndValue===true){
                    val = `${element.key} (${element.value})`;
                }else{
                    val = element.value;
                }
                returnComponent.push(<option data-search-key={`${element.key},${element.value}`} value={element.key} key={element.key}>{val}</option>);
            });
        }
        return returnComponent;
    }

    onSearch(){
        ViewUtils.showWait();
        let data = this.state.data;
        data.page = 1;
        if(this.isSearchById()){
            CsSeekAction.doSearchById(data);
        }else{
            CsSeekAction.doSearch(data);
        }
        CsSeekAction.persistCurrentFilters(CS_SEEK_SEARCH, data, LOCAL_STORAGE_KEY_RECENT_SEARCHES);
        
    }

    onTextSearch(e){
        let currentList = null;
        if(ViewUtils.isEmpty(this.state.result.textSearch)){
            currentList = $(`div.div-panel-job-item-root`);
            currentList.show();
        }else{
            currentList = $(`div.div-panel-job-item-root:contains(${this.state.result.textSearch})`);
            currentList.show();        
            $(`div.div-panel-job-item-root:not(:contains(${this.state.result.textSearch}))`).hide();
        }
        let jobMessage = `${currentList.length===0?'No job found!':''+currentList.length +' job'+(currentList.length>1?'s':'')+' found'}`;
        $(e.target).parent().find('span.job-number-result').remove();
        $(e.target).parent().prepend(`<span class=job-number-result>${jobMessage}</span>`);
        let refInput = e.target;
        window.setTimeout(function(){
            $(refInput).parent().find('span.job-number-result').fadeOut(500);

        },2000);
    }

    onClientSearch(e){
        let r = this.state.result;
        const p = {
            text:r.textSearch,
            category:r.category,
            employer:r.employer,
            jobTitle:r.jobTitle,
            workType:r.workType,
            advertiser:r.advertiser,
            salary:r.salary,
            createdAt:ViewUtils.getTimeByStrKey(r.createdAt)
        };
        this.applyClientFilter(p);
    }
    
    applyClientFilter(param){
        let currentList = $('div.div-panel-job-item-root');
        let jobsShowed = 0;
        $(currentList).each(function(){
            let jobPanel = $(this);
            let shouldBeVisible = true;
            if(shouldBeVisible && typeof param.text !== 'undefined' && param.text.hasValue()){
                shouldBeVisible = $(jobPanel).find(`*:contains(${param.text})`).length>0;
            }
            let json = null;
            if(shouldBeVisible){
                json = JSON.parse($(jobPanel).attr('client-search-values'));
            }
            if(shouldBeVisible && typeof param.category !== 'undefined' && param.category.hasValue()){
                shouldBeVisible = ViewUtils.isDefined(json.categoryIds) && json.categoryIds.hasValue() 
                && json.categoryIds.join(',').indexOf(param.category)!==-1;
            }

            if(shouldBeVisible && typeof param.employer !== 'undefined' && param.employer.hasValue()){
                shouldBeVisible = ViewUtils.isDefined(json.employer) && ViewUtils.isDefined(json.employer.name)
                    && json.employer.name.hasValue() && json.employer.name === param.employer;
            }

            if(shouldBeVisible && typeof param.jobTitle !== 'undefined' && param.jobTitle.hasValue()){
                shouldBeVisible = ViewUtils.isDefined(json.jobTitle) && json.jobTitle.hasValue() 
                    && json.jobTitle.indexOf(param.jobTitle);
            }

            if(shouldBeVisible && typeof param.workType !== 'undefined' && param.workType.hasValue()){
                shouldBeVisible = false;
                if(ViewUtils.isDefined(json.workType) && json.workType.hasValue()){
                    // eslint-disable-next-line
                    for(let i in json.workType){
                        let wt = json.workType[i];
                        if(ViewUtils.isDefined(wt) && String(wt.id) === String(param.workType)){
                            shouldBeVisible = true;
                            break;
                        }
                    }
                }
            }

            if(shouldBeVisible && ViewUtils.isDefined(param.createdAt)){
                shouldBeVisible = ViewUtils.isDefined(json.createdAt) && json.createdAt <= param.createdAt;
            }

            if(shouldBeVisible){
                $(jobPanel).show();
                jobsShowed++;
            }else{
                $(jobPanel).hide();
            }
        });

        
        let clientSearchMessage = `${jobsShowed===0?`No job found on the ${this.state.data.page}th page!`:''+jobsShowed +' job'+(jobsShowed>1?'s':'')+` found on the ${this.state.data.page}th page!`}`;
        this.setState({clientSearchMessage:' -> '+clientSearchMessage});
        

    }

    populateSearchSummary(payload, self){
        let searchSummary = {
            advertiser : self.state.result.facets.advertiser,
            category : self.state.result.facets.category,
            location:self.state.result.facets.location,
            createdAt : payload['createdAt'],
            employer : payload['employer'],
            salaryMin : payload['salaryMin'],
            title : payload['title'],
            workType : payload['workType']
        };

        let summary = self.getFacetsViewBox(searchSummary);

        self.setState({summary});
    }

    populateFilterAutocompleteOptions(payload, self){
        let filterAutocomplete = {
            advertiserOptions : self.getFacetsOptionsFilter('advertiser',payload),
            categoryOptions : self.getFacetsOptionsFilterCategory(payload),
            createdAtOptions : self.getFacetsOptionsFilter('createdAt',payload, true),
            employerOptions : self.getFacetsOptionsFilter('employer',payload),
            salaryMinOptions : self.getFacetsOptionsFilter('salaryMin',payload, true),
            titleOptions : self.getFacetsOptionsFilter('title',payload, true),
            workTypeOptions : self.getFacetsOptionsFilter('workType',payload)
        };
        self.setState({filterAutocomplete});

    }

    getFacetsViewBox(searchSummary){
        if(ViewUtils.isDefined(this.state.result) && ViewUtils.isDefined(this.state.result.page)){
            const data = [
                {name:'Advertiser', value:searchSummary.advertiser, maxItem:7},
                {name:'Classification', value:searchSummary.category, maxItem:7},
                {name:'Date Created', value:searchSummary.createdAt, maxItem:50},
                {name:'Employer', value:searchSummary.employer, maxItem:7},
                {name:'Salary', value:searchSummary.salaryMin, maxItem:50},
                {name:'Title', value:searchSummary.title, maxItem:7},
                {name:'Work Type', value:searchSummary.workType, maxItem:7},
                {name:'Location', value:searchSummary.location, maxItem:10}
            ];

            return <ChartBox className="box-summary" type="SEEK" ref={(e)=> this.summaryBox = e} title={`${text.LBL_SUMMARY}`} data={data} visible={this.state.summaryVisible}>

                </ChartBox>;
        }
        return '';
    }

    getSearchResultsElement(){ 
        if(!ViewUtils.isEmpty(this.state.result)){
            let jobsDiv = [];
            let i = 0;
            this.state.result.jobs.forEach(element => {
                jobsDiv.push(<PanelJobItem country={this.state.selectedCountry} showJobId={true} job={element} key={element.id} ref={`job-item-${i++}`}/>);
            });
            let result = this.state.result;

            let paginator = this.state.result.page.resultCount>20?(<div className="div-filters-row">
            <Input label={`Showing ${this.state.result.page.pageSize} jobs per page`} 
                type="paginator" width="25%" defaultValue="20" paginatorItemsPerPage={20} 
                paginatorItemsSize={this.state.result.page.resultCount} onChange={(e)=>this.onPaginatorChange(e)}/>
            </div>):'';

        return (<div>
                    <div className={`div-search-result ${this.isSearchById()?'hidden':''}`}>
                        <div className="div-filters">
                            <div className="div-filters-row">
                                <div className="display-cell">
                                <Box title={`Additional Filters ${this.state.clientSearchMessage}`} className="lazy-load">
                                    <div className="div-search-result">
                                        <div className="div-filters">
                                            <div className="div-filters-row">
                                                <Input ref="textSearch" onChange={(e)=>{result.textSearch = e.target.value;this.onClientSearch(e);}} placeHolder="Find within results (case sensitive)" label="Find" width="66%"></Input>
                                                <Input ref="filterCategory" type="select" onChange={(e)=>{result.category = e.target.value; this.onClientSearch(e);}} defaultValue="all" label="Classification" width="33%">
                                                        <option value="">All</option>
                                                        {this.state.filterAutocomplete.categoryOptions}
                                                </Input>
                                                
                                            </div>
                                        </div>
                                        <div className="div-filters">
                                            <div className="div-filters-row">
                                                <Input ref="filterEmployer" type="select" onChange={(e)=>{result.employer = e.target.value;this.onClientSearch(e);}} defaultValue="all" label="Employer" width="33%">
                                                        <option value="">All</option>
                                                        {this.state.filterAutocomplete.employerOptions}
                                                </Input>
                                                <Input type="select" ref="filterJobTitle" onChange={(e)=>{result.jobTitle = e.target.value;this.onClientSearch(e);}} defaultValue="all" label="Job Title" width="33%">
                                                        <option value="">All</option>
                                                        {this.state.filterAutocomplete.titleOptions}
                                                </Input>
                                                <Input type="select" ref="filterWorkType" onChange={(e)=>{result.workType = e.target.value;this.onClientSearch(e);}} defaultValue="all" label="Work Type" width="33%">
                                                        <option value="">All</option>
                                                        <option value="242">Full Time</option>
                                                        <option value="243">Part Time</option>
                                                        <option value="244">Contract/Temp</option>
                                                        <option value="245">Casual/Vacation</option>
                                                </Input>
                                                
                                            </div>
                                        </div>
                                        <div className="div-filters">
                                            <div className="div-filters-row">
                                                <Input type="select"ref="filterAdvertiser" onChange={(e)=>{result.advertiser = e.target.value;this.onClientSearch(e);}} defaultValue="all" label="Advertiser" width="33%">
                                                        <option value="all">All</option>
                                                        {this.state.filterAutocomplete.advertiserOptions}
                                                </Input>
                                                <Input type="select" ref="filterCreatedAt" onChange={(e)=>{result.createdAt = e.target.value;this.onClientSearch(e);}} defaultValue="all" label="Date Created" width="33%">
                                                        <option value="all">All</option>
                                                        {this.state.filterAutocomplete.createdAtOptions}
                                                </Input>
                                                <Input type="select" ref="filterSalary" onChange={(e)=>{result.salary = e.target.value;this.onClientSearch(e);}} defaultValue="all" label="Salary" width="33%">
                                                        <option value="all">All</option>
                                                        {this.state.filterAutocomplete.salaryMinOptions}
                                                </Input>
                                                
                                            </div>
                                        </div>
                                    </div>
                                     </Box>
                                </div>
                                <div className="display-cell">
                                    <Box title={`${text.LBL_DISPLAY_OPTIONS} - ${text.LBL_TOTAL_JOBS_FOUND(this.state.result.page.resultCount)}`}>

                                        <div className="div-search-result">
                                            {paginator}
                                            <div className="div-filters-row">
                                                <ToggleButton style={{width:'250px', height:'38px', lineHeight:'26px', marginTop:'2px'}} onLabel="Hide all jobs details" offLabel="Show all jobs details" 
                                                    onIcon="fa fa-window-minimize" offIcon="fa fa-window-maximize" checked={this.state.data.allDetailsShowed} 
                                                    onChange={(e)=>this.onToggleAllJobs()}/>
                                                <ToggleButton style={{width:'250px', height:'38px', lineHeight:'26px', marginTop:'2px'}} onLabel="Sorted by relevance" 
                                                    offLabel="Sorted by date" onIcon="fa fa-trophy" offIcon="fa fa-calendar"
                                                    checked={this.state.data.sort==='score'} onChange={(e)=>this.onToggleOrder()}/>
                                                <ToggleButton style={{width:'250px', height:'38px', lineHeight:'26px', marginTop:'2px'}} onLabel="Hide search summary" 
                                                    offLabel="Show search summary" onIcon="fa fa-pie-chart" offIcon="fa fa-pie-chart" className="lazy-load"
                                                    checked={this.state.summaryVisible} 
                                                    onChange={(e)=>{this.summaryBox.setVisible(!this.state.summaryVisible); this.setState({summaryVisible:!this.state.summaryVisible});}}/>
                                            </div>
                                        </div>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    
                    </div>        
                    {this.state.summary}
                <div className="div-search-result">
                    {jobsDiv}
                </div>
                </div>

        );
        }else{
            return [];
        }
    }

    onToggleOrder(){
        let data = this.state.data;
        data.sort = data.sort==='score' || data.sort===''?'createdAt':'score';
        this.onSearch();
    }


    onToggleAllJobs(){
        let i=0;
        let currentJob = null;
        let data = this.state.data;
        data.allDetailsShowed = !data.allDetailsShowed;
        this.setState({data});
        while(typeof (currentJob = this.refs[`job-item-${i++}`]) !=='undefined'){
            if(data.allDetailsShowed){
                currentJob.showDetails();
            }else{
                currentJob.hideDetails();
            }
        }
    }

    onReset(e){ 
       ViewUtils.clean(this);
       ViewUtils.clean(this.refs);
       if(ViewUtils.isDefined(this.firstInput) && ViewUtils.isFunction(this.firstInput.focus)){
           this.firstInput.focus();
       }
       let data = this.state.data;
       data.locationId = '';
       data.where = '';

       this.setState({result:[], data});
    }

    render(){
        const AUTOCOMPLETE_TIP = 'Click to search for ';
        const AUTOCOMPLETE_TIP_NO_POPUP = 'Enter any value and select an option.';
        let searchResultElement = this.getSearchResultsElement();
        let data = this.state.data;
        return (<div className="div-search-container">
            <ReactTooltip html={true}/>
            <CategoryPopupSearch header="Search for Classifications" modal={true} ref="popupCat" onSelectItem={(e)=> {data.category = e.id; this.setState({data})}}/>
            <JoraWorkTypePopupSearch header="Search for Work Type" modal={true} ref="popupWorkType" onSelectItem={(e)=> {data.workType = e.id; this.setState({data})}}/>
            <AdvertiserPopupSearch header="Search for Advertisers" modal={true} ref="popupAdvertiser" onSelectItem={(e)=> {data.advertiser = e.id; this.setState({data})}}/>
            
                
            <Box title="Site Search" className="seek-filters-box lazy-load">
                <SearchFilterPanel title="Seek Search" className="jora" onSearch={()=>this.onSearch()} onReset={(e)=>this.onReset(e)}>
                <div className="div-filters">
                    
                    <div className="div-filters-row">
                        <Input onChange={(e)=>{data.jobId = e.value;this.setState({data})}} label="Job Id" ref={(i)=> this.firstInput = i} value={data.jobId}></Input>
                        <Input onChange={(e)=>{data.keyWords = e.value;this.setState({data})}} label="Keywords" value={data.keyWords} ref="keywords"></Input>
                        <Input type="select" ref="country" onChange={(e)=>{data.country = e.target.value; this.autocompleteLoad(e.target.value); this.inputWhere.setUrl(`${SEEK_API_LOCATION_SUGGESTION}?source=chalice&limit=20&country=${e.target.value.toUpperCase()}&q=`); this.setState({data})}} 
                            value={this.state.data.country} label="Country">
                            <option value="au">AU</option>
                            <option value="nz">NZ</option>
                        </Input>
                        
                        <Input onChange={(e)=>{data.createdAt = e.target.value; this.setState({data})}} label="Date Created" ref="createdAt" type="datesince" value={data.createdAt}></Input>
                    </div>

                    <div className="div-filters-row">
                        <Input ref={(i)=>this.inputWhere = i} onChange={(e, el)=>{data.where = el?el.value:'';data.locationId = el ? el.id: '';this.setState({data});}} label="Where" placeHolder="city, state or postcode" icon={true} 
                            iconText="info" iconTip={AUTOCOMPLETE_TIP_NO_POPUP}
                            defaultValue={data.where} type="auto-complete" site="seek">
                        </Input>
                        
                        <Input onChange={(e)=>{data.category = e;this.setState({data})}} label="Classification" ref="category" type="auto-complete-client-side" value={data.category}
                            icon={true} iconText="search" iconTip={`${AUTOCOMPLETE_TIP}${'Classification'}`} onIconClick={(e)=>this.refs.popupCat.show(CAT_POPUP_SEEK, data.country.toUpperCase())}>
                            {this.state.autocomplete.category}
                        </Input>
                        <Input type="select-between" onChange={(e)=>{data.salary = e.value;this.setState({data})}} value={data.salary} label="Salary" ref="salary">
                            <option value="all">all</option>
                            <option value="30000">$30,000</option>
                            <option value="50000">$50,000</option>
                            <option value="70000">$70,000</option>
                            <option value="110000">$110,000</option>
                            <option value="150000">$150,000</option>
                        </Input>
                        <Input onChange={(e)=>{data.updatedAt = e.target.value;this.setState({data})}} ref="updatedAt" label="Date Modified" type="datesince" value={data.updatedAt}></Input>
                        
                    </div>          
                    <div className="div-filters-row">
                        <Input onChange={(e)=>{data.workType = e.target.value; this.setState({data})}} label="Work Type" ref="workType" type="select" 
                            value={data.workType}>
                            <option value="">All</option>
                            <option value="242">Full Time</option>
                            <option value="243">Part Time</option>
                            <option value="244">Contract/Temp</option>
                            <option value="245">Casual/Vacation</option>
                        </Input>
                        <Input onChange={(e)=>{data.jobtitle=e; this.setState({data})}} type="auto-complete-client-side" ref="jobTitle"
                            value={this.state.data.jobtitle} label="Job Title" icon={true} iconText="info" iconTip={AUTOCOMPLETE_TIP_NO_POPUP}>
                            {this.state.autocompleteJobTitle} 
                        </Input>
                        <Input onChange={(e)=>{data.advertiser = e; this.setState({data})}} label="Advertiser" ref="advertiser" 
                            type="auto-complete-client-side" value={data.advertiser} icon={true} iconText="search" 
                            iconTip={`${AUTOCOMPLETE_TIP}${'Advertiser'}`} onIconClick={(e)=>this.refs.popupAdvertiser.show(ADV_POPUP_SEEK, data.country.toUpperCase())}> 
                            {this.state.autocompleteAdvertisers}
                        </Input>

                        <Input className="dedup-filter" label="Deduplication" ref="deduplication" type="template" icon={true} iconText="info" 
                            iconTip="Select how the presented records should be processed">
                            <div>
                                <SelectButton value={data.dedup} options={[{label:'Filter On', value:'filter'},{label:'Filter Off', value:'off'}]}
                                    ref="deduplication" onChange={(e)=>{data.dedup = e.value; this.setState({data})}}/>
                            </div>
                        </Input> 

                    </div>          
                </div> 
            </SearchFilterPanel>
            </Box>
            
            {searchResultElement}
        </div>);
    }
}

export default CsSeekSearch;
