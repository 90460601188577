import React from 'react'
import Box from './box/Box';
import './EmailNotVerified.css'
import AuthAction from '../actions/AuthAction';
import { If, Else } from '../helper/react-inline-logic';
import { ProgressBar } from 'primereact/components/progressbar/ProgressBar';

const EmailNotVerified = ({data = {loading:true}}) => (
    <div className="message-email-not-verified">
        <Box title="AIPS Partner Portal - Email verification">
            <If condition={data.loading===true}>
                <ProgressBar mode="indeterminate" style={{height:'10px'}}/>
            </If>
            <Else condition={data.loading===true}>
                Hi {data.givenName},<br/><br/>
                An email has been sent to <strong>{data.email}</strong>.<br/>
                Please, confirm the received email and then try to login again.
                <br/>
                <br/>    
                <button className="p-button" onClick={()=>AuthAction.logOut()}>Go to Login page</button>
            </Else>
        </Box>
        

    </div>);
export default EmailNotVerified;